import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';
import {
  IFetchedOwnerStatus,
  useAgentsFromServer,
  useBanksFromServer,
  useMerchantsFromServer,
  useOwnersFromServer,
} from 'hooks/useDataFromServer';
import { PaymentDirectionEnum, PaymentStatusEnum } from 'modules/payments/enums';

export const paymentsFilters: IFilterSchema[] = [
  {
    title: 'ID заявки',
    name: 'orderId',
    type: 'input-number',
  },
  {
    title: 'ID платежа',
    name: 'id',
    type: 'input-number',
  },
  {
    title: 'Клиент',
    name: 'merchantId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useMerchantsFromServer,
    cacheKey: 'customer-select',
  },
  {
    title: 'ID владельца',
    name: 'ownersIds',
    type: 'lazy-select',
    useDataHook: useOwnersFromServer,
    cacheKey: 'owners-select',
    mode: 'multiple',
    width: 200,
    allowClear: true,
    dataTransformer: (item: IFetchedOwnerStatus) => ({ label: `[${item.id}] ${item.name}`, value: item.id }),
  },
  {
    title: 'Агент',
    name: 'agentId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useAgentsFromServer,
    cacheKey: 'agents-filter',
    width: 200,
  },
  {
    title: 'Статус',
    name: 'status',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'В обработке',
        value: PaymentStatusEnum.IN_PROGRESS,
      },
      {
        label: 'Выполнен',
        value: PaymentStatusEnum.COMPLETED,
      },
      {
        label: 'Не выполнен',
        value: PaymentStatusEnum.CANCELED,
      },
    ],
  },
  {
    title: 'Тип платежа',
    name: 'direction',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Входящие',
        value: PaymentDirectionEnum.IN,
      },
      {
        label: 'Исходящие',
        value: PaymentDirectionEnum.OUT,
      },
    ],
  },
  {
    title: 'Даты',
    name: 'range',
    type: 'date-picker-showtime',
    allowClear: true,
    rangeFields: ['from', 'to'],
  },
  {
    title: 'Тип даты',
    name: 'dateType',
    type: 'select',
    allowClear: true,
    width: 200,
    options: [
      {
        label: 'По дате создания',
        value: 'createdAt',
      },
      {
        label: 'По дате выполнения',
        value: 'paidAt',
      },
    ],
  },
  {
    title: 'Банк',
    name: 'bankIds',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useBanksFromServer,
    cacheKey: 'banks-filter',
    mode: 'multiple',
    width: 200,
  },
  {
    title: 'Сумма ОТ',
    name: 'amountFrom',
    type: 'input-number',
  },
  {
    title: 'Сумма ДО',
    name: 'amountTo',
    type: 'input-number',
  },
];
