import { IUsersState, UsersReducerActions, UsersActionsTypes } from 'modules/users/store/users/types';

const initialState: IUsersState = {
  users: [],
  isLoading: false,
  pagination: {
    total: 0,
    page: 0,
    pages: 0,
    perPage: 20,
  },
};

export function usersReducer(state = initialState, action: UsersReducerActions): IUsersState {
  switch (action.type) {
    case UsersActionsTypes.FETCH_USERS: {
      const { users, pagination } = action.payload;
      return { ...state, users, pagination };
    }
    case UsersActionsTypes.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case UsersActionsTypes.SET_PAGINATION: {
      const { page, perPage } = action.payload;

      return { ...state, pagination: { ...state.pagination, page, perPage } };
    }
    default:
      return state;
  }
}
