import React, { useState } from 'react';

import { App, Button, Space, Tooltip } from 'antd';

import { ActiveOrdersPageAssignOwnerModal } from './components/AssignOwnerModal';
import { ActiveOrdersPageCommissionSetDrawer } from './components/CommissionSetDrawer';
import { ActiveOrdersPageHeader } from './components/Header';
import { ActiveOrdersPageUnknownOrderModal } from './components/UnknownOrderModal';

import { filtersSchema } from './filters';

import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';

import { PauseOutlined, PlayCircleOutlined } from '@ant-design/icons';
import './style.css';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useChannel } from 'hooks/useChannel.hook';
import { useLazyTable } from 'hooks/useLazyTable.hook';
import { useTheme } from 'contexts';
import { ActiveOrdersActionTypes } from 'modules/orders/store/activeOrders/types';
import { ActiveOrder } from 'modules/orders/store/activeOrders/interfaces/activeOrder.interface';
import { BindingOperator } from 'modules/orders/store/activeOrders/interfaces/bindingOperator.interface';
import { useTableColumns } from 'modules/orders/pages/active/columns';
import { OrderTypes } from 'modules/orders/enums/orderTypes.enum';
import { Order } from 'modules/orders/types/order.interface';
import { orderPaymentStatusTitles } from 'modules/orders/constants/orderPaymentStatusTitles';
import { OrderAssigningStatus } from 'modules/orders/enums/orderAssigningStatus.enum';
import { ConfirmOutcomingDrawer } from 'modules/orders/pages/active/components/ConfirmOutcomingDrawer';
import { ChangeStatusOfActiveOrderStatusesEnum } from 'modules/orders/enums/changeStatusOfActiveOrderStatuses.enum';
import { useBindOperator } from 'modules/orders/pages/active/components/RowActions/components/BindOperatorToOrder/hooks/useBindOperator';
import { FetchActiveOrdersParams } from 'modules/orders/store/activeOrders/interfaces/fetchActiveOrdersParams.interface';
import {
  addNewActiveOrder,
  changeBindStatus,
  changeBuffering,
  changePagination,
  changeStatusOfActiveOrder,
  disableAutoAssignment,
  editActiveOrder,
  fetchMoreProcessingOrders,
  getActiveOrders,
  markAsUnknownOrder,
} from 'modules/orders/store/activeOrders/actions';
import { Filters } from 'components/Filters';
import { LazyTable } from 'components/LazyTable';
import { OrderOwnerDescription } from 'modules/orders/components/OrderOwnerDescription';

export const ActiveOrdersPage: React.FC = () => {
  const [selectedUnknownOrder, setSelectedUnknownOrder] = useState<ActiveOrder>();
  const [selectedForAssignOwnerOrder, setSelectedForAssignOwnerOrder] = useState<ActiveOrder>();
  const [selectedForCommissionSetOrder, setSelectedForCommissionSetOrder] = useState<Order>();
  const [selectedForConfirmOrder, setSelectedForConfirmOrder] = useState<ActiveOrder>();

  const { isBuffering, isLoading } = useAppSelector((state) => state.activeOrders);

  const { memoFilters, setMemoFilters } = useMemoFilters<FetchActiveOrdersParams>();

  const { message } = App.useApp();

  const dispatch = useAppDispatch();

  const [bindOperatorCheck] = useBindOperator();

  const { choose } = useTheme();

  const [props, { isTableView, pagination, setIsTableView }] = useLazyTable({
    filters: memoFilters,
    store: 'activeOrders',
    dataField: 'orders',
    fetchAction: getActiveOrders,
    changePageAction: changePagination,
    resetAction: () => ({
      type: ActiveOrdersActionTypes.CLEAR_ACTIVE_ORDERS,
      payload: null,
    }),
    loadMoreAction: fetchMoreProcessingOrders,
  });

  useChannel('private-transactions', {
    '.created': (event: ActiveOrder) => {
      if (isTableView && pagination.page !== 1) return;

      dispatch<any>(addNewActiveOrder(event, memoFilters, isTableView));
    },
    '.edited': (event: ActiveOrder) => dispatch<any>(editActiveOrder(event)),
    '.bound': (event: BindingOperator) => dispatch<any>(changeBindStatus(event.orderId, event.operatorId)),
  });

  const backgroundClass = choose('border-blue-300', 'bg-white border-blue-300');
  const backgroundClassAttention = choose('bg-red-900 border-red-400', 'bg-red-50 border-red-300');

  const columns = useTableColumns({
    onChangeStatusPress: handleChangeStatusPress,
    onUnknownOrderPress: setSelectedUnknownOrder,
    onAssignOwnerForOrderPress: setSelectedForAssignOwnerOrder,
    onCommissionSetForOrderPress: setSelectedForCommissionSetOrder,
    onDisableAutoAssignmentPress: handleDisableAutoAssignmentPress,
    onOutcomingOrderConfirmPress: handleOutcommingConfirmPress,
  });

  function handleChangeStatusPress(actionName: ChangeStatusOfActiveOrderStatusesEnum, id: number) {
    dispatch<any>(changeStatusOfActiveOrder({ orderId: id, status: actionName }));
  }

  async function handleUnknownModalConfirm(id: number, amount: number, customerName?: string): Promise<void> {
    if (!amount) {
      message.error('Не заполнена сумма поступления!');

      return;
    }

    await dispatch<any>(markAsUnknownOrder(id, { amount, customerName }));

    setSelectedUnknownOrder(undefined);
  }

  async function handleDisableAutoAssignmentPress(order: ActiveOrder) {
    try {
      await dispatch(disableAutoAssignment(order.id));

      message.success('Автоматическое распределение для заявки успешно отключено!');
    } catch {
      message.error('Произошла ошибка при выключении автоматического распределения');
    }
  }

  async function handleOutcommingConfirmPress(order: ActiveOrder) {
    await bindOperatorCheck(order.id, () => setSelectedForConfirmOrder(order));
  }

  return (
    <>
      <Filters
        headerTitle="Текущие заявки"
        filters={filtersSchema}
        value={memoFilters}
        onSubmit={setMemoFilters}
        extraHeaderViewButtons={() => (
          <ActiveOrdersPageHeader
            isLoading={isLoading}
            isTableView={isTableView}
            onViewToggleChanged={() => setIsTableView(!isTableView)}
          />
        )}
        pauseButton={() => (
          <div>
            <Tooltip title={`${!isBuffering ? 'Остановить' : 'Возобновить'} поток заявок`}>
              <Button
                icon={!isBuffering ? <PauseOutlined /> : <PlayCircleOutlined />}
                onClick={() => {
                  dispatch<any>(changeBuffering(!isBuffering));

                  message.info(`Поток заявок ${isBuffering ? 'возобновлен' : 'остановлен'}`);
                }}
              />
            </Tooltip>
          </div>
        )}
      />
      <LazyTable
        columns={columns}
        rowKey={(record: ActiveOrder) => record.id}
        rowClassName={(order) =>
          order.assigningStatus === OrderAssigningStatus.TURNING_OFF_AUTO_ASSIGNING_ALLOWED
            ? backgroundClassAttention
            : backgroundClass
        }
        expandedRowRender={(order: Order) => (
          <Space direction="horizontal" wrap className="p-2">
            {order.owners.map((owner) => {
              let status = undefined;
              if (OrderTypes.OUTCOMING === order.type && order.sumTransferred) {
                const sum = order.sumTransferred.find((sum) => sum.ownerId === owner.ownerId);
                status = sum?.status || sum?.status === 0 ? orderPaymentStatusTitles[sum.status] : undefined;
              }

              return (
                <OrderOwnerDescription
                  key={owner.ownerId}
                  orderType={order.type}
                  orderId={order.id}
                  owner={owner}
                  paymentStatus={status}
                />
              );
            })}
          </Space>
        )}
        rowExpandable={({ owners }) => owners && owners.length > 0}
        {...props}
      />
      <ActiveOrdersPageAssignOwnerModal
        order={selectedForAssignOwnerOrder}
        onCancel={() => setSelectedForAssignOwnerOrder(undefined)}
      />
      <ActiveOrdersPageUnknownOrderModal
        loading={isLoading}
        onConfirm={handleUnknownModalConfirm}
        order={selectedUnknownOrder}
        onCancel={() => setSelectedUnknownOrder(undefined)}
      />
      <ActiveOrdersPageCommissionSetDrawer
        order={selectedForCommissionSetOrder}
        onCancel={() => setSelectedForCommissionSetOrder(undefined)}
      />
      <ConfirmOutcomingDrawer order={selectedForConfirmOrder} onClose={() => setSelectedForConfirmOrder(undefined)} />
    </>
  );
};
