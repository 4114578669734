import React, { useEffect, useRef } from 'react';

interface Props {
  title: string;
  columnKey: string;
  onResize: (key: string, width: number) => void;
  width: number | string | undefined;
}

export const LazyTableHeaderColumn: React.FC<Props> = ({ title, columnKey, onResize, width }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    getBounds();

    window.addEventListener('resize', getBounds);

    return () => {
      window.removeEventListener('resize', getBounds);
    };
  }, []);

  function getBounds() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      const bounds = ref.current?.getBoundingClientRect();

      if (bounds) {
        onResize(columnKey, bounds.width);
      }
    }, 600);
  }

  return (
    <div ref={(r) => (ref.current = r)} className="grow items-center justify-center" style={{ width }}>
      {title}
    </div>
  );
};
