import instance from 'utils/axios';
import { BankAccount } from 'modules/bankAccounts/types/bankAccount.interface';

export async function fetchBankAccountRequest(id: number): Promise<BankAccount> {
  const {
    data: { data },
  } = await instance.get('/bank-accounts/item', { params: { id } });

  return data;
}
