import React, { ReactElement } from 'react';
import axios from 'axios';
import { notification } from 'antd';

import { PRESERVED_USER_TOKEN_KEY } from 'modules/auth/store/auth/reducers';

/**
 * Используется для сетевых запросов
 *
 * @author Алексей Рассохин <telegram: @alras63>
 **/
const baseRequestConfig = {
  baseURL: import.meta.env.VITE_API_URL + '/api/admin',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  xsrfHeaderName: 'X-XSRF-TOKEN',
  withCredentials: true,
};

const instance = axios.create(baseRequestConfig);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let description: string | ReactElement = String(error);

    const messageFromResponse = error?.response?.data?.message;
    const errorsFromResponse = error?.response?.data?.errors;

    if (messageFromResponse) {
      description = (
        <>
          <b>{messageFromResponse}</b>
          {Object.values(errorsFromResponse ?? {}).map((errorItem) => (
            <>
              <br />
              {errorItem}
            </>
          ))}
        </>
      );
    }

    notification.error({
      message: 'Ошибка!',
      description,
      duration: 6,
    });

    throw error;
  }
);

instance.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${localStorage.getItem(PRESERVED_USER_TOKEN_KEY)}`,
  },
}));

export default instance;
