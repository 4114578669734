import { useBanksFromServer } from 'hooks/useDataFromServer';
import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'ID владельца',
    name: 'ownerId',
    type: 'input-number',
  },
  {
    title: 'Банк',
    name: 'bankId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useBanksFromServer,
    cacheKey: 'provider-filter',
  },
];
