import instance from 'utils/axios';
import { BankAccount } from '../types/bankAccount.interface';

export interface UpdateBankAccountRequestParams {
  id: number;
  type: string;
  accountNumber: string;
  bankId: number;
  bic: string;
  sbpEnabled: boolean;
  phone: string;
  limitMinIn: number;
}

export async function updateBankAccountRequest(params: UpdateBankAccountRequestParams): Promise<BankAccount> {
  const {
    data: { data },
  } = await instance.post('/bank-accounts/update', params);

  return data;
}
