import useSWR from 'swr';
import { fetchMerchantBalancesByCurrenciesRequest } from '../api/fetchMerchantBalancesByCurrencies.request';

export function useFetchMerchantBalancesByCurrenciesRequest(id: number) {
  const { data, isLoading, mutate } = useSWR([id, 'fetch-merchant-balances-by-currencies-request'], ([id]) =>
    fetchMerchantBalancesByCurrenciesRequest(id)
  );

  return {
    merchantBalancesByCurrencies: data,
    isFetchingMerchantBalancesByCurrencies: isLoading,
    fetchMerchantBalancesByCurrencies: mutate,
  };
}
