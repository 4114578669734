import React, { useMemo, useState } from 'react';

import { Button, Image, Space, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Divider } from 'antd/es';

import cn from 'classnames';

import { IProvider } from 'types/IProvider';

import { InputNumber } from 'components/Input';
import { CreateMerchantDrawerCommissionsStepModal } from './components/Modal';
import { CreateMerchantDrawerCommissionsStepFilters } from './components/Filters';

import { CurrencyNameEnum } from 'enums/CurrencyEnum';
import { useTheme } from 'contexts';

import { Tooltip } from 'components/Tooltip';

import { MerchantCommission, MerchantCommissionStepModal } from 'modules/merchants/types';
import { MerchantTariffTypesEnum } from 'modules/merchants/enums';

interface Props {
  isShown: boolean;
  onPrevButtonClick: () => void;
  onSubmit: (commissions: MerchantCommission[]) => void;
  providers: IProvider[];
  isEditCommissionsModalVisible: boolean;
  setIsEditCommissionsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateMerchantDrawerCommissionsStep: React.FC<Props> = ({
  providers,
  isShown,
  onPrevButtonClick,
  onSubmit,
  isEditCommissionsModalVisible,
  setIsEditCommissionsModalVisible,
}) => {
  const [form] = useForm<MerchantCommissionStepModal[]>();

  const [selectedProviders, setSelectedProviders] = useState<number[]>([]);
  const [commissions, setCommissions] = useState<MerchantCommissionStepModal[]>([]);

  const { isDarkMode } = useTheme();

  const formData = useMemo(() => {
    if (selectedProviders.length > 0 && !isEditCommissionsModalVisible) {
      const filteredProviders = [] as IProvider[];

      selectedProviders.forEach((id) => {
        filteredProviders.push(providers.filter((el) => el.id === id)[0]);
      });

      return filteredProviders;
    }

    return providers;
  }, [selectedProviders, providers, isEditCommissionsModalVisible]);

  function updateTable(updatedTableData: MerchantCommissionStepModal[]) {
    form.setFieldsValue(updatedTableData);

    setCommissions(updatedTableData);
  }

  function handleSubmitPress() {
    const formValues = form.getFieldsValue();

    const values = { ...commissions, ...formValues };

    const results: MerchantCommission[] = [];

    Object.values(values).forEach(({ bankId, incomingValue, outcomingValue }: any) => {
      if (incomingValue.p2p || outcomingValue.p2p) {
        results.push({
          bankId,
          incomingValue: (incomingValue.p2p ?? 0) / 100,
          outcomingValue: (outcomingValue.p2p ?? 0) / 100,
          tariffType: MerchantTariffTypesEnum.P2P,
        });
      }

      if (incomingValue.c2c || outcomingValue.c2c) {
        results.push({
          bankId,
          incomingValue: (incomingValue.c2c ?? 0) / 100,
          outcomingValue: (outcomingValue.c2c ?? 0) / 100,
          tariffType: MerchantTariffTypesEnum.C2C,
        });
      }
    });

    onSubmit(results);
  }

  return (
    <Space className={cn('w-full', isShown ? '' : 'hidden')} direction="vertical" size="middle">
      <CreateMerchantDrawerCommissionsStepFilters
        selectedFilters={selectedProviders}
        onSelectedFilterChange={setSelectedProviders}
      />
      <div
        style={{
          padding: '0 4px',
          height: '60px',
          backgroundColor: isDarkMode ? '#292e31' : '#FAFAFA',
        }}
        className="flex items-center gap-x-4 font-semibold"
      >
        <div className="w-20">Провайдер</div>
        <div className="w-14">Код валюты</div>
        <div className="w-60">Входящие</div>
        <div className="w-60">Исходящие</div>
      </div>
      <Form form={form}>
        {formData.map((bank) => (
          <div key={bank.id}>
            <div className="flex justify-between items-center py-2 gap-x-2">
              <Tooltip title={bank.name}>
                <Image
                  style={{ objectFit: 'contain' }}
                  src={bank.image}
                  alt={bank.name}
                  width={80}
                  height={57}
                  preview={false}
                />
              </Tooltip>
              <div className="w-10">{bank.currencyCode}</div>

              <Form.Item name={[bank.id, 'bankId']} hidden initialValue={bank.id}>
                <Input type="hidden" />
              </Form.Item>
              <Space direction="vertical">
                <InputNumber
                  size="middle"
                  className="w-60"
                  name={[bank.id, 'incomingValue', 'p2p']}
                  addonAfter="%"
                  addonBefore="P2P"
                />
                <InputNumber
                  size="middle"
                  className="w-60"
                  addonAfter="%"
                  name={[bank.id, 'incomingValue', 'c2c']}
                  addonBefore="C2C"
                  disabled={bank.currencyCode !== CurrencyNameEnum.RUB}
                />
              </Space>
              <Space direction="vertical">
                <InputNumber
                  size="middle"
                  className="w-60"
                  addonAfter="%"
                  addonBefore="P2P"
                  name={[bank.id, 'outcomingValue', 'p2p']}
                />
                <InputNumber
                  size="middle"
                  className="w-60"
                  addonAfter="%"
                  addonBefore="C2C"
                  name={[bank.id, 'outcomingValue', 'c2c']}
                  disabled={bank.currencyCode !== CurrencyNameEnum.RUB}
                />
              </Space>
            </div>
            <Divider style={{ margin: 0 }} />
          </div>
        ))}
      </Form>
      <div className="flex gap-3">
        <Button onClick={onPrevButtonClick} className="w-1/3">
          Назад
        </Button>
        <Button onClick={handleSubmitPress} className="w-2/3" type="primary">
          Создать клиента
        </Button>
      </div>
      <CreateMerchantDrawerCommissionsStepModal
        isVisible={isEditCommissionsModalVisible}
        onCancel={() => {
          setIsEditCommissionsModalVisible(false);
          setSelectedProviders([]);
        }}
        updateTable={updateTable}
        commissionsForm={form}
      />
    </Space>
  );
};
