import instance from 'utils/axios';

export async function getLinkedToOperatorStatus(orderId: number) {
  try {
    const {
      data: { data },
    } = await instance.get(`/orders/${orderId}/bound-operator-to-order-status`);

    return data as number | undefined;
  } catch (err) {
    return null;
  }
}
