import React, { useEffect, useRef } from 'react';
import { SelectProps, Select as AntdSelect, RefSelectProps } from 'antd';

interface Props extends SelectProps {
  blurOnScroll?: boolean;
}

export const Select: React.FC<Props> = ({ blurOnScroll = true, ...props }) => {
  const selectRef = useRef<RefSelectProps | null>(null);
  const bodyElement = useRef<HTMLElement>(document.getElementsByTagName('body').item(0));

  useEffect(() => {
    if (!blurOnScroll) return;

    bodyElement.current?.addEventListener('scroll', onScroll);

    return () => {
      bodyElement.current?.removeEventListener('scroll', onScroll);
    };
  }, []);

  function onScroll() {
    selectRef.current?.blur();
  }

  return (
    <AntdSelect
      ref={selectRef}
      showSearch
      filterOption={(input, option) => ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())}
      {...props}
    />
  );
};
