import useSWRMutation from 'swr/mutation';
import { blockBankAccountRequest } from 'modules/bankAccounts/api/blockBankAccount.request';

export function useBlockBankAccountRequest() {
  const { trigger, isMutating } = useSWRMutation('block-bank-account-request', (_, { arg }: { arg: number }) =>
    blockBankAccountRequest(arg)
  );

  return { blockBankAccount: trigger, isBlockingBankAccount: isMutating };
}
