import useSWRMutation from 'swr/mutation';
import { updateBankRequest, UpdateBankRequestParams } from 'modules/banks/api/updateBank.request';

export function useUpdateBankRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'update-bank-request',
    (_, { arg }: { arg: UpdateBankRequestParams }) => updateBankRequest(arg),
    { throwOnError: true }
  );

  return { updateBank: trigger, isUpdatingBank: isMutating };
}
