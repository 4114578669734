import useSWRMutation from 'swr/mutation';
import { fetchPaymentsStatisticRequest, FetchPaymentsStatisticRequestParams } from 'modules/payments/api';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';

export function useFetchPaymentsStatisticRequest() {
  const { trigger, isMutating, data } = useSWRMutation(
    'fetch-payment-statistic',
    (
      _,
      {
        arg,
      }: {
        arg: FetchPaymentsStatisticRequestParams;
      }
    ) => fetchPaymentsStatisticRequest(sanitizeRequestParams(arg)),
    {
      throwOnError: true,
    }
  );

  return { fetchPaymentsStatistic: trigger, isFetchingPaymentsStatistic: isMutating, paymentsStatistic: data };
}
