import React from 'react';
import { TooltipProps, Tooltip as BaseTooltip } from 'antd';

/**
 * Общий для проекта компонент тултипа.
 *
 * Является компонентом Tooltip из библиотеки antd
 * с некоторыми дефолтными настройками, чтобы избежать
 * дублирование по проекту.
 *
 * @author Собянин Виктор <telegram: @victorsobyanin>
 */
export const Tooltip: React.FC<TooltipProps> = (props) => <BaseTooltip mouseLeaveDelay={0} {...props} />;
