import React, { useState } from 'react';
import { App, Modal, Space, Typography } from 'antd';
import { Device } from 'modules/devices/types/device.interface';
import { Select } from 'components/Select';
import useSWR from 'swr';
import instance from 'utils/axios';

interface Props {
  device: Device | undefined;
  onSubmit: () => void;
  onCancel: () => void;
}

export const DeviceListPageAssignOwnerModal: React.FC<Props> = ({ device, onCancel, onSubmit }) => {
  const [selectedOwnerId, setSelectedOwnerId] = useState<number>();

  const { data, isLoading } = useSWR<{ id: number; name: string }[]>({ id: device?.id }, async () => {
    if (!device?.agent) {
      return [];
    }

    const {
      data: { data },
    } = await instance.get('/owners/list-short', { params: { agentId: device.agent.id } });

    return data;
  });

  const { message } = App.useApp();

  const label = device?.owner ? 'Перепри' : 'При';

  async function handleSubmit() {
    try {
      await instance.post('/device/assign', { deviceId: device!.id, ownerId: selectedOwnerId });

      onSubmit();
      message.success(`Устройство успешно ${label.toLowerCase()}вязано`);
    } catch {
      message.error(`Произошла ошибка при ${label.toLowerCase()}взяке владельца`);
    }
  }

  return (
    <Modal
      open={!!device}
      okText={label + 'вязать'}
      cancelText="Отмена"
      okButtonProps={{ disabled: !selectedOwnerId, loading: isLoading }}
      onCancel={onCancel}
      onOk={handleSubmit}
    >
      <Space className="w-full" direction="vertical">
        <Typography.Title level={5}>{label}вязать устройство к владельцу</Typography.Title>
        <Select
          className="w-full"
          loading={isLoading}
          placeholder="Выберите владельца"
          value={selectedOwnerId}
          options={data?.map((owner) => ({ label: owner.name, value: owner.id }))}
          onChange={setSelectedOwnerId}
        />
      </Space>
    </Modal>
  );
};
