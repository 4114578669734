import { Dispatch } from 'redux';

import { MerchantsBalancesActionsTypes } from './types';
import { FetchMerchantsBalancesParams } from './interfaces/fetchMerchantsBalancesParams.interface';

import api from 'utils/axios';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';

export function fetchMerchantsBalances(params: FetchMerchantsBalancesParams & RequestParamsWithPagination) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setLoading());

      const {
        data: { data: merchantsBalances, meta: pagination },
      } = await api.get('/merchants/balances', {
        params: sanitizeRequestParams(params),
      });

      dispatch({
        type: MerchantsBalancesActionsTypes.FETCH_MERCHANTS_BALANCES,
        payload: { merchantsBalances, pagination },
      });
    } catch {
      dispatch<any>(setLoading(false));
    }
  };
}

export function setLoading(value: boolean = true) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: MerchantsBalancesActionsTypes.SET_LOADING,
      payload: value,
    });
  };
}

export function changePagination(page: number, perPage: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: MerchantsBalancesActionsTypes.CHANGE_PAGE,
      payload: { page, perPage },
    });
  };
}
