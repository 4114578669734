import { useEffect } from 'react';

export function useOnResize(callback: () => void) {
  useEffect(() => {
    callback();

    window.addEventListener('resize', callback);

    return () => {
      window.removeEventListener('resize', callback);
    };
  }, []);
}
