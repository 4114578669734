import instance from 'utils/axios';
import { MerchantToken } from '../types/merchantToken.interface';

export async function fetchMerchantTokensRequest(merchantId: number): Promise<MerchantToken[]> {
  const {
    data: { data },
  } = await instance.get('/merchants/tokens', { params: { merchantId } });

  return data;
}
