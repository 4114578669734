import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'ID банка',
    name: 'id',
    type: 'input',
    rules: [
      {
        pattern: /^[0-9]+$/,
        message: 'Можно вводить только цифры',
      },
    ],
  },
  {
    title: 'Название банка',
    name: 'name',
    type: 'input',
    rules: [
      {
        pattern: /^[a-zA-Zа-яА-Я0-9]+$/,
        message: 'Можно вводить только русские и английские буквы, а также цифры',
      },
    ],
    allowClear: true,
    width: 300,
  },
];
