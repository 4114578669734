import instance from 'utils/axios';
import { Bank } from 'modules/banks/types/bank.interface';

export interface CreateBankFromBinRequestParams {
  bins: number[];
  source: string;
}

export async function createBankFromBinRequest(params: CreateBankFromBinRequestParams) {
  const {
    data: { data },
  } = await instance.post('/banks/create-bin', { ...params });

  return data as Bank;
}
