import React from 'react';
import { App, Button, Form, Input, Space } from 'antd';
import { Divider } from 'antd/es';
import cn from 'classnames';

import { MerchantChat } from 'modules/merchants/types';
import { InputNumber } from 'components/Input';
import { SwitchField } from 'components/SwitchField';
import { MerchantChatsForm } from 'modules/merchants/components/MerchantChatsForm';

interface Props {
  isShown: boolean;
  onNextButtonClick: () => void;
  chats: MerchantChat[];
  onChangeChats: (e: React.SetStateAction<MerchantChat[]>) => void;
}

const CLIENT_UNPAID_ORDERS_DEFAULT_LIMIT = 10;

export const CreateMerchantDrawerCustomerStep: React.FC<Props> = ({
  isShown,
  onNextButtonClick,
  onChangeChats,
  chats,
}) => {
  const { message } = App.useApp();

  function handleSubmit(): void {
    const isChatsFilled =
      chats.length > 0 && chats.every((chat) => Object.values(chat).every((value) => value !== undefined));

    if (isChatsFilled) {
      onNextButtonClick();

      return;
    }

    message.error('Поле Чаты клиента в разрезе валют обязательно для заполнения');
  }

  return (
    <div className={cn('w-full', isShown || 'hidden')}>
      <Form.Item
        name="name"
        label="Наименование"
        required
        wrapperCol={{ span: 12 }}
        labelCol={{ span: 12 }}
        labelAlign="left"
      >
        <Input size="middle" className="w-full" />
      </Form.Item>
      <Form.Item
        name="aliasOrder"
        label="Алиас"
        required
        wrapperCol={{ span: 12 }}
        labelCol={{ span: 12 }}
        labelAlign="left"
      >
        <Input className="w-full" />
      </Form.Item>
      <Form.Item
        name="callbackUrl"
        label="Колбэк URL"
        wrapperCol={{ span: 12 }}
        labelCol={{ span: 12 }}
        labelAlign="left"
      >
        <Input className="w-full" />
      </Form.Item>
      <Form.Item
        name="ownersCommissionsLimit"
        label="Макс. знач. комиссии владельца"
        initialValue={0}
        required
        wrapperCol={{ span: 12 }}
        labelCol={{ span: 12 }}
        labelAlign="left"
      >
        <InputNumber className="w-full" />
      </Form.Item>
      <Form.Item
        initialValue={CLIENT_UNPAID_ORDERS_DEFAULT_LIMIT}
        name="unpaidOrdersLimitByOwner"
        label="Лимит неоплаченных заявок на 1 владельца"
        required
        wrapperCol={{ span: 12 }}
        labelCol={{ span: 12 }}
        labelAlign="left"
      >
        <InputNumber className="w-full" />
      </Form.Item>
      <Form.Item
        initialValue={0}
        name="overdraft"
        label="Овердрафт"
        wrapperCol={{ span: 12 }}
        labelCol={{ span: 12 }}
        labelAlign="left"
      >
        <InputNumber isAmountInput className="w-full" />
      </Form.Item>
      <Form.Item
        initialValue={0}
        name="c2cMinOrderAmount"
        label="Минимальная сумма C2C"
        wrapperCol={{ span: 12 }}
        labelCol={{ span: 12 }}
        labelAlign="left"
      >
        <InputNumber isAmountInput className="w-full" />
      </Form.Item>
      <Space direction="vertical" size="middle">
        <SwitchField name="outcomingOrdersPriority" defaultValue={false}>
          Включить приоритет исходящих заявок
        </SwitchField>
        <SwitchField name="combineBalance" defaultValue={false}>
          Объединение балансов
        </SwitchField>
        <SwitchField name="isAutoAssigmentEnabled" defaultValue={true}>
          Автоматическое распределение заявок клиента включено
        </SwitchField>
        <SwitchField name="c2cIsActive" defaultValue={false}>
          Прием C2C
        </SwitchField>
        <SwitchField name="isHighRisk" defaultValue={false}>
          High risk
        </SwitchField>
      </Space>
      <Divider />
      <MerchantChatsForm customerChats={chats} onCustomerChatsChange={onChangeChats} />
      <Divider />
      <Button onClick={handleSubmit} className="w-full" type="primary">
        Далее
      </Button>
    </div>
  );
};
