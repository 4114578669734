import React, { FC } from 'react';
import { App, Button, message, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { permissions } from 'policies/permissions';
import { useDeleteBankAccountRequest } from 'modules/bankAccounts/hooks/useDeleteBankAccountRequest.hook';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';

interface Props {
  id: number;
  onSuccess: () => void;
}

export const DeleteBankAccountButton: FC<Props> = ({ id, onSuccess }) => {
  const { deleteBankAccount, isDeletingBankAccount } = useDeleteBankAccountRequest();

  const { modal } = App.useApp();

  const { userCan } = useUserPermissions();

  function handleClick() {
    const { destroy } = modal.error({
      title: 'Удаление счета',
      content: (
        <>
          Вы уверены, что хотите
          <br />
          удалить счет?
        </>
      ),
      closable: true,
      width: '280px',
      footer: () => (
        <>
          <Button onClick={destroy}>Отмена</Button>
          <Button
            type="primary"
            danger
            onClick={() => {
              destroy();
              handleDelete();
            }}
          >
            Удалить
          </Button>
        </>
      ),
    });
  }

  async function handleDelete() {
    try {
      await deleteBankAccount(id);

      message.success('Счет успешно удален!');
      onSuccess();
    } catch {
      message.error('Не удалось удалить счет');
    }
  }

  return (
    <Tooltip title="Удалить счёт">
      <Button
        onClick={handleClick}
        shape="circle"
        type="primary"
        danger
        icon={<DeleteOutlined />}
        disabled={!userCan(permissions.bankAccount.delete)}
        loading={isDeletingBankAccount}
      />
    </Tooltip>
  );
};
