import React, { useEffect, useMemo, useRef, useState } from 'react';

import Link from 'antd/lib/typography/Link';

import { IOrderDispute } from 'modules/disputes/types/IOrderDispute';

import telegramIcon from 'assets/svg/telegram.svg';
import { OrderDisputesPageTableExecutionDurationColumn } from 'modules/disputes/pages/list/components/Table/components/ExecutionDurationColumn';
import { OrderDisputesPageTableReceiptsColumn } from 'modules/disputes/pages/list/components/Table/components/ReceiptsColumn';

interface Props {
  dispute: IOrderDispute;
}

export const OrderDisputesPageSmsModalHeader: React.FC<Props> = ({ dispute }) => {
  const intervalRef = useRef<NodeJS.Timer>();

  const [timer, setTimer] = useState<number>(0);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimer((state) => state + 1);
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, []);

  const executionDuration = useMemo(() => <OrderDisputesPageTableExecutionDurationColumn dispute={dispute} />, [timer]);

  return (
    <div className="flex items-center mx-auto sm:flex-row flex-col">
      <div className="grow text-left mt-0">
        <div className="flex flex-row items-center gap-x-8 flex-wrap gap-y-2 justify-between">
          <div className="flex flex-col w-max">
            <h1 className="text-xl title-font font-bold mb-2">Номер диспута: {dispute.id}</h1>
            <p className="leading-relaxed text-base">
              ID заявки клиента:{' '}
              <span className="opacity-90 block sm:inline sm:text-base text-sm">
                {dispute.customerName + '_' + dispute.customerTransactionId}
              </span>
            </p>
          </div>
          <div className="w-56 shadow-md mr-10">
            <OrderDisputesPageTableReceiptsColumn dispute={dispute} />
          </div>
        </div>
        <div className="flex font-semibold pt-3">
          <div className="w-full flex justify-start flex-wrap gap-y-4 gap-x-8">
            <div className="w-max flex flex-col items-center">
              <h2 className="opacity-70">Время обработки</h2>
              {executionDuration}
            </div>
            <div className="w-max flex flex-col items-center">
              <h2 className="opacity-70">ID Заявки</h2>
              <a className="text-blue-400" href={'/transactions_static?id=' + dispute.transactionId}>
                {dispute.transactionId}
              </a>
            </div>
            <div className="w-max flex flex-col items-center">
              <h2 className="opacity-70">Чат владельца</h2>
              <Link className="flex gap-1 items-center w-max" target="_blank" href={dispute.ownerChatTgMessageLink}>
                {dispute.ownerId}
                <img src={telegramIcon} alt="telegram" />
              </Link>
            </div>
            <div className="w-max flex flex-col items-center">
              <h2 className="opacity-70">Переведенная сумма</h2>
              <p>
                {dispute.firstPaymentAmount
                  ? (dispute.firstPaymentAmount + (dispute.secondPaymentAmount ?? 0)).toLocaleString('ru-RU')
                  : ''}
              </p>
            </div>
            <div className="w-max flex flex-col items-center">
              <h2 className="opacity-70">Карта получателя</h2>
              <p className="break-all">{dispute.cardNumber}</p>
            </div>
            <div className="w-max flex flex-col items-center">
              <h2 className="opacity-70">Комментарий</h2>
              <p className="break-all">{dispute.comment}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
