import { Dispatch } from 'redux';

import {
  ICreateOwnerParams,
  IEditOwnerBalanceParams,
  IFetchOwnersParams,
  IOwnerFieldToUpdate,
  OwnersActionTypes,
} from 'modules/owners/store/owners/types';

import api from 'utils/axios';
import { IOwner } from 'modules/owners/types/IOwner';
import { GetState } from 'app/store';
import { IMetaPagination } from 'types/IMetaPagination';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';

async function fetchOwnersRequest(params: IFetchOwnersParams): Promise<[IOwner[], IMetaPagination]> {
  const {
    data: { data, meta },
  } = await api.get('/owners', { params: sanitizeRequestParams(params) });

  return [data, meta];
}

async function fetchOwnersTotalBalances(owners: IOwner[]): Promise<{ ownerId: number; amount: number }[]> {
  const ownerIds = owners.map(({ ownerId }) => ownerId);

  try {
    const {
      data: { data: balances },
    } = await api.get('/bank-accounts/owner-balance', {
      params: { ownerIds },
      validateStatus: (status) => status < 400 || status === 403,
    });

    return balances ?? [];
  } catch {
    return [];
  }
}

function mergeOwnersBalances(owners: IOwner[], balances: { ownerId: number; amount: number }[]) {
  return owners.map((owner) => {
    const balance = balances.find((b) => b.ownerId === owner.ownerId);

    return {
      ...owner,
      balanceActual: balance?.amount,
    };
  });
}

export function fetchOwners(params: IFetchOwnersParams) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setLoading());

      const [owners, meta] = await fetchOwnersRequest(params);
      const balances = await fetchOwnersTotalBalances(owners);

      dispatch({
        type: OwnersActionTypes.FETCH_OWNERS,
        payload: {
          owners: mergeOwnersBalances(owners, balances),
          pagination: { ...meta, page: meta.page || 1 },
        },
      });
    } finally {
      dispatch<any>(setLoading(false));
    }
  };
}

export function fetchMoreOwners(params: IFetchOwnersParams) {
  return async (dispatch: Dispatch, getState: GetState) => {
    try {
      const {
        isLoading,
        owners,
        pagination: { page },
      } = getState().owners;

      if (isLoading) {
        return;
      }

      dispatch<any>(setLoading());

      const [newOwners, meta] = await fetchOwnersRequest({ ...params, page: page + 1 });
      const balances = await fetchOwnersTotalBalances(newOwners);

      dispatch({
        type: OwnersActionTypes.FETCH_OWNERS,
        payload: {
          owners: [...owners, ...mergeOwnersBalances(newOwners, balances)],
          pagination: { ...meta, page: meta.page || 1 },
        },
      });
    } finally {
      dispatch<any>(setLoading(false));
    }
  };
}

export function createOwner(params: ICreateOwnerParams) {
  return async (dispatch: Dispatch): Promise<boolean> => {
    try {
      dispatch<any>(setLoading());

      const {
        data: { data },
      } = await api.post('/owners/create', { ...params });

      dispatch<any>({ type: OwnersActionTypes.CREATE_OWNER, payload: data });

      return true;
    } catch {
      dispatch<any>(setLoading(false));

      return false;
    }
  };
}

export function updateOwnerField(
  ownerId: number,
  field: keyof IOwnerFieldToUpdate,
  value: IOwnerFieldToUpdate[keyof IOwnerFieldToUpdate]
) {
  return async (dispatch: Dispatch): Promise<boolean> => {
    try {
      dispatch<any>(setLoading());

      const {
        data: { data },
      } = await api.post('/owners/updateField', { ownerId, [field]: value });
      dispatch<any>({
        type: OwnersActionTypes.UPDATE_OWNER_FIELD,
        payload: data,
      });

      return true;
    } catch {
      return false;
    } finally {
      dispatch<any>(setLoading(false));
    }
  };
}

export function updateOwner(params: IOwner) {
  return async (dispatch: Dispatch) => {
    try {
      const owner = { ...params, status: params.status.id };

      const {
        data: { data },
      } = await api.post('/owners/update', { ...owner });

      const balances = await fetchOwnersTotalBalances([data]);
      const owners = mergeOwnersBalances([data], balances);

      dispatch<any>({ type: OwnersActionTypes.EDIT_OWNER, payload: owners[0] });
    } finally {
      dispatch<any>(setLoading(false));
    }
  };
}

export function editOwnerActualBalance(params: IEditOwnerBalanceParams) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setLoading());

      const {
        data: { data },
      } = await api.post('/owners/edit-balance', params);

      dispatch<any>({
        type: OwnersActionTypes.EDIT_OWNER_BALANCE,
        payload: data,
      });
    } catch (e) {
      dispatch<any>(setLoading(false));

      throw e;
    }
  };
}

export function deleteOwner(ownerId: number, params?: IFetchOwnersParams, tableViewToggle = true) {
  return async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch<any>(setLoading());

      await api.post('/owners/delete', { ownerId });
      dispatch<any>({ type: OwnersActionTypes.DELETE_OWNER, payload: ownerId });

      if (!tableViewToggle && getState().owners.pagination.page < getState().owners.pagination.pages) {
        const {
          data: { data, meta, other },
        } = await api.get('/owners', { params: sanitizeRequestParams(params!) });

        dispatch({
          type: OwnersActionTypes.FETCH_OWNERS,
          payload: {
            owners: [...getState().owners.owners, data.at(data.length - 1)],
            pagination: { ...meta, page: getState().owners.pagination.page },
            other,
          },
        });
      }
    } catch (e) {
      dispatch<any>(setLoading(false));

      throw e;
    }
  };
}

export function setLoading(value: boolean = true) {
  return (dispatch: Dispatch) => {
    dispatch({ type: OwnersActionTypes.SET_LOADING, payload: value });
  };
}

export function changePage({ page, perPage }: { page: number; perPage: number }) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: OwnersActionTypes.CHANGE_PAGE,
      payload: { page, perPage },
    });
  };
}
