/** Русификация пагинации */
export const paginationLocale = { items_per_page: ' на странице' };
/** Русификация фильтров в таблице */
export const tableFiltersLocale = {
  filterSearchPlaceholder: 'Поиск',
  filterReset: 'Сброс',
};
export const tableLocale = {
  emptyText: 'Нет данных',
};
