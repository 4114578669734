import { asyncThunkCreator, buildCreateSlice, PayloadAction } from '@reduxjs/toolkit';
import instance from 'utils/axios';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';
import { BankAccountsState } from 'modules/bankAccounts/store/types/bankAccountsState.interface';
import { FetchBankAccountsParams } from 'modules/bankAccounts/store/types/fetchBankAccountsParams.interface';

const createSlice = buildCreateSlice({ creators: { asyncThunk: asyncThunkCreator } });

const bankAccountsSlice = createSlice({
  name: 'bankAccounts',
  initialState: {
    accounts: [],
    isLoading: false,
    pagination: {
      page: 1,
      pages: 0,
      perPage: 20,
      total: 0,
    },
  } as BankAccountsState,
  reducers: (create) => ({
    fetchBankAccounts: create.asyncThunk(
      async (params: FetchBankAccountsParams & RequestParamsWithPagination) => {
        const { data } = await instance.get('bank-accounts', { params: sanitizeRequestParams(params) });

        return data;
      },
      {
        fulfilled: (state, action) => {
          state.isLoading = false;
          state.accounts = action.payload.data;
          state.pagination = action.payload.meta;
        },
        rejected: (state) => {
          state.isLoading = false;
        },
        pending: (state) => {
          state.isLoading = true;
        },
      }
    ),
    updateCardCount: create.reducer(
      (state, { payload }: PayloadAction<{ type: 'increment' | 'decrement'; bankAccountId: number }>) => {
        const account = state.accounts.find((account) => account.id === payload.bankAccountId);
        if (account) {
          account.cardCount = payload.type === 'increment' ? account.cardCount + 1 : account.cardCount - 1;
        }
      }
    ),
  }),
});

export const bankAccountsReducer = bankAccountsSlice.reducer;

export const { fetchBankAccounts, updateCardCount } = bankAccountsSlice.actions;
