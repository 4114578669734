import React, { useEffect, useState } from 'react';
import { message, Switch, Tooltip } from 'antd';
import { BankAccount } from 'modules/bankAccounts/types/bankAccount.interface';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { permissions } from 'policies/permissions';
import { useEnableSbpBankAccountRequest } from 'modules/bankAccounts/hooks/useEnableSbpBankAccountRequest.hook';
import { useDisableSbpBankAccountRequest } from 'modules/bankAccounts/hooks/useDisableSbpBankAccountRequest.hook';

interface Props {
  account: BankAccount;
}

export const BankAccountSbpEnabledSwitch: React.FC<Props> = ({ account }) => {
  const [checked, setChecked] = useState<boolean>(account.sbpEnabled);

  const { isEnablingSbpBankAccount, enableSbpBankAccount } = useEnableSbpBankAccountRequest();
  const { isDisablingSbpBankAccount, disableSbpBankAccount } = useDisableSbpBankAccountRequest();

  const { userCanAll } = useUserPermissions();

  useEffect(() => {
    setChecked(account.sbpEnabled);
  }, [account]);

  async function handleSbpEnabledAccountChange(newChecked: boolean) {
    try {
      if (newChecked) {
        await enableSbpBankAccount(account.id);
      } else {
        await disableSbpBankAccount(account.id);
      }

      message.success({
        content: 'Работа с СБП успешно изменена!',
        duration: 1.5,
      });
      setChecked(newChecked);
    } catch {
      message.error('Ошибка изменения работы с СБП!');
    }
  }

  return (
    <Tooltip
      placement="top"
      title={!account.phone && 'Нельзя изменить статус СБП, так как у аккаунта нет номера телефона'}
    >
      <Switch
        checked={checked}
        onChange={handleSbpEnabledAccountChange}
        disabled={
          !userCanAll(permissions.bankAccount.sbp.enable, permissions.bankAccount.sbp.disable) || !account.phone
        }
        loading={isEnablingSbpBankAccount || isDisablingSbpBankAccount}
      />
    </Tooltip>
  );
};
