import api from 'utils/axios';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';
import { PaymentDirectionEnum, PaymentStatusEnum } from 'modules/payments/enums';

export interface RequestPaymentsReportRequestParams {
  id?: number;
  bankId?: number;
  agentId?: number;
  type?: PaymentDirectionEnum;
  merchantId?: number;
  ownersIds?: number[];
  status?: PaymentStatusEnum;
  orderId?: number;
  from?: string;
  to?: string;
  dateType?: 'created_at' | 'paid_at';
}

export async function requestPaymentsReportRequest(params: RequestPaymentsReportRequestParams): Promise<void> {
  await api.get('/payment/report', {
    params: sanitizeRequestParams(params),
  });
}
