import React from 'react';
import { BankAccountCardsListAddCard } from './components/AddCard';
import { useFetchCardsRequest } from 'modules/bankAccounts/hooks/useFetchCardsRequest.hook';
import { updateCardCount } from 'modules/bankAccounts/store';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { CardEnabledSwitch, DeleteCardButton } from 'modules/bankAccounts/components';
import { CreditCardOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useTheme } from 'contexts';

interface Props {
  bankAccountId: number;
  className?: string;
  addRowProps?: {
    className?: string;
    addButtonPosition?: 'left' | 'right';
  };
}

export const BankAccountCardsList: React.FC<Props> = ({ bankAccountId, className, addRowProps = {} }) => {
  const { isFetchingCards, cards, fetchCards } = useFetchCardsRequest({ bankAccountId });

  const { choose } = useTheme();

  const dispatch = useAppDispatch();

  return (
    <Spin spinning={isFetchingCards}>
      <div className={className}>
        <BankAccountCardsListAddCard
          bankAccountId={bankAccountId}
          onSubmit={fetchCards}
          className={addRowProps.className}
          addButtonPosition={addRowProps.addButtonPosition}
        />
        {cards?.map?.((card) => (
          <div
            className="flex flex-row border-t p-2 gap-x-2 px-4 items-center justify-between"
            key={card.id}
            style={{
              borderColor: choose('rgb(93, 102, 107)', '#e5e7eb'),
              borderBottomRightRadius: 8,
              borderBottomLeftRadius: 8,
            }}
          >
            <div className="flex flex-row gap-3 items-center">
              <CreditCardOutlined />
              {card.cardNumber}
            </div>
            <div className="flex flex-row gap-3 items-center">
              <CardEnabledSwitch checked={card.status} cardId={card.id} onChange={() => fetchCards()} />
              <DeleteCardButton
                id={card.id}
                onSuccess={() => {
                  fetchCards();
                  dispatch(updateCardCount({ bankAccountId, type: 'decrement' }));
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </Spin>
  );
};
