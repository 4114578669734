import React from 'react';

import { useBanksFromServer } from 'hooks/useDataFromServer';
import { LazySelect } from 'components/LazySelect';

interface Props {
  selectedFilters?: number[];
  onSelectedFilterChange: (value: number[]) => void;
}

export const CreateMerchantDrawerCommissionsStepFilters: React.FC<Props> = ({
  selectedFilters: selectedFilter,
  onSelectedFilterChange,
}) => {
  return (
    <LazySelect
      style={{ minWidth: '128px' }}
      allowClear
      mode="multiple"
      placeholder="Провайдер"
      value={selectedFilter}
      onChange={onSelectedFilterChange}
      useDataHook={useBanksFromServer}
      cacheKey="provider-filter"
    />
  );
};
