import { useAppSelector } from 'hooks/useAppSelector.hook';

export const useUserData = () => {
  const { user } = useAppSelector((state) => state.auth);

  const userName = user?.name || null;
  const userRole = user?.roles?.map((r) => r.name).join(', ') || null;

  return { userName, userRole };
};
