import { Form, Input, Radio, Select, DatePicker, Switch } from 'antd';
import { LazyRadio } from 'components/LazyRadio';
import { LazySelect } from 'components/LazySelect';
import { FormFieldSchema } from 'components/UnifiedForm/types/fieldsSchema.interface';
import React, { useMemo } from 'react';
import { InputNumber } from 'components/Input';
import { DEFAULT_DATETIME_STRING_FORMAT } from 'constants/dates';

interface Props<T> {
  schema: FormFieldSchema<T>;
  defaultValue?: string | string[];
}

export const UnifiedFormItem = <T,>({ schema, defaultValue }: Props<T>) => {
  const element = useMemo(() => {
    switch (schema.type) {
      case 'input':
        return (
          <Input
            addonBefore={schema.addonBefore}
            placeholder={schema.placeholder}
            allowClear={schema.allowClear}
            style={{ minWidth: schema.width || 100, maxWidth: schema.width }}
          />
        );
      case 'input-number':
        return <InputNumber isAmountInput={schema.isAmountInput} placeholder={schema.placeholder} />;
      case 'date-picker':
        return (
          <DatePicker.RangePicker
            placeholder={[`${schema.placeholder} от`, `${schema.placeholder} до`]}
            allowClear={schema.allowClear}
          />
        );
      case 'date-picker-showtime':
        return (
          <DatePicker.RangePicker
            showTime
            placeholder={[`${schema.placeholder} От`, `${schema.placeholder} До`]}
            allowClear={schema.allowClear}
            format={DEFAULT_DATETIME_STRING_FORMAT}
          />
        );
      case 'select':
        return (
          <Select
            mode={schema.mode}
            options={schema.options}
            style={{ minWidth: schema.width || 100, maxWidth: schema.width }}
            placeholder={schema.placeholder}
            allowClear={schema.allowClear}
            maxTagCount={1}
          />
        );
      case 'lazy-select':
        return (
          <LazySelect
            useDataHook={schema.useDataHook}
            style={{ minWidth: schema.width || 100, maxWidth: schema.width }}
            placeholder={schema.placeholder}
            allowClear={schema.allowClear}
            cacheKey={schema.cacheKey}
            mode={schema.mode}
            dataTransformer={schema.dataTransformer}
            maxTagCount={1}
            fetchIfNoCache
            defaultValue={defaultValue}
          />
        );
      case 'radio':
        return (
          <Radio.Group className="flex whitespace-nowrap" options={schema.options} optionType={schema.optionType} />
        );
      case 'lazy-radio':
        return (
          <LazyRadio
            title={schema.title}
            cacheKey={schema.cacheKey}
            useDataHook={schema.useDataHook}
            optionType={schema.optionType}
            dataTransformer={schema.dataTransformer}
          />
        );
      case 'switch':
        return <Switch defaultChecked={schema.defaultValue} />;
      case 'custom':
        return schema.element;
      default:
        return null;
    }
  }, [schema]);

  const formItem = (
    <Form.Item
      className={schema.className}
      key={schema.name as string}
      name={schema.name as string}
      label={schema.label}
      hidden={schema.hidden}
      rules={schema.rules}
    >
      {element}
    </Form.Item>
  );

  return <div>{schema.wrapper ? schema.wrapper(formItem) : formItem}</div>;
};
