import { DatePicker, Form, Select } from 'antd';
import React, { useMemo } from 'react';
import { IFilterSchema } from '../../types/filterSchema.interface';
import { DEFAULT_DATETIME_STRING_FORMAT } from 'constants/dates';
import { Input, InputNumber } from 'components/Input';
import { LazySelect } from 'components/LazySelect';

interface Props {
  schema: IFilterSchema;
  defaultValue?: string | string[];
}

export const FiltersItem: React.FC<Props> = ({ schema, defaultValue }) => {
  const { title, type, rules, name } = schema;

  const component = useMemo(() => {
    switch (type) {
      case 'input':
        return (
          <Input
            placeholder={title}
            allowClear={schema.allowClear}
            style={{ minWidth: schema.width || 100, maxWidth: schema.width }}
          />
        );
      case 'input-number':
        return <InputNumber isAmountInput={schema.isAmountInput} placeholder={title} />;
      case 'date-picker':
        return <DatePicker.RangePicker placeholder={[`${title} от`, `${title} до`]} allowClear={schema.allowClear} />;
      case 'date-picker-showtime':
        return (
          <DatePicker.RangePicker
            showTime
            placeholder={[`${title} От`, `${title} До`]}
            allowClear={schema.allowClear}
            format={DEFAULT_DATETIME_STRING_FORMAT}
          />
        );
      case 'select':
        return (
          <Select
            mode={schema.mode}
            options={schema.options}
            style={{ minWidth: schema.width || 100, maxWidth: schema.width }}
            placeholder={title}
            allowClear={schema.allowClear}
            maxTagCount={1}
          />
        );
      case 'lazy-select':
        return (
          <LazySelect
            useDataHook={schema.useDataHook}
            style={{ minWidth: schema.width || 100, maxWidth: schema.width }}
            placeholder={title}
            allowClear={schema.allowClear}
            cacheKey={schema.cacheKey}
            mode={schema.mode}
            dataTransformer={schema.dataTransformer}
            maxTagCount={1}
            fetchIfNoCache
            defaultValue={defaultValue}
          />
        );
      default:
        return null;
    }
  }, [schema]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginRight: '10px',
        fontSize: '12px',
        lineHeight: 2.1,
        marginBottom: '5px',
      }}
    >
      <span>{title}</span>
      <Form.Item name={name} rules={rules} noStyle>
        {component}
      </Form.Item>
    </div>
  );
};
