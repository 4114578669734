import { FetchMerchantsBalancesPayload } from './interfaces/fetchMerchantsBalancesPayload.interface';
import { ReducerAction } from 'types/reducerAction.interface';

export interface IChangePagePayload {
  page: number;
  perPage: number;
}

export enum MerchantsBalancesActionsTypes {
  FETCH_MERCHANTS_BALANCES = 'FETCH_MERCHANTS_BALANCES',
  SET_LOADING = 'SET_LOADING',
  CHANGE_PAGE = 'CHANGE_PAGE',
}

export type FetchMerchantsBalancesReducerAction = ReducerAction<
  FetchMerchantsBalancesPayload,
  MerchantsBalancesActionsTypes.FETCH_MERCHANTS_BALANCES
>;

export type SetLoadingReducerAction = ReducerAction<boolean, MerchantsBalancesActionsTypes.SET_LOADING>;

export type ChangePageMerchantsBalancesReducerAction = ReducerAction<
  IChangePagePayload,
  MerchantsBalancesActionsTypes.CHANGE_PAGE
>;

export type MerchantsBalancesReducerActions =
  | FetchMerchantsBalancesReducerAction
  | SetLoadingReducerAction
  | ChangePageMerchantsBalancesReducerAction;
