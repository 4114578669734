import { Switch } from 'antd';
import { useTheme } from 'contexts';
import React from 'react';

export const ThemeSwitcher: React.FC = () => {
  const { isDarkMode, setIsDarkMode } = useTheme();

  return (
    <Switch
      checked={isDarkMode}
      onChange={() => setIsDarkMode(!isDarkMode)}
      checkedChildren="Dark"
      unCheckedChildren="Light"
    />
  );
};
