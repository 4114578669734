import useSWR from 'swr';
import { fetchMerchantTokensRequest } from '../api/fetchMerchantTokens.request';

export function useFetchMerchantTokensRequest(id: number) {
  const { data, isLoading, mutate } = useSWR([id, 'fetch-merchant-tokens-request'], ([id]) =>
    fetchMerchantTokensRequest(id)
  );

  return {
    merchantTokens: data,
    isFetchingMerchantTokens: isLoading,
    fetchMerchantTokens: mutate,
  };
}
