import { IMetaPagination } from 'types/IMetaPagination';
import { IOrderDispute } from 'modules/disputes/types/IOrderDispute';
import { ReducerAction } from 'types/reducerAction.interface';

export interface IOrderDisputeState {
  orderDisputes: IOrderDispute[];
  isLoading: boolean;
  pagination: IMetaPagination;
  buffer: IOrderDispute[];
  isBuffering: boolean;
}

export interface IFetchOrderDisputesParams {
  id?: number;
  transactionId?: number;
  customerTransactionId?: string;
  customerId?: number;
  status?: number;
  ownerId?: number;
  perPage: number;
  page: number;
}

export interface IFetchOrderDisputesPayload {
  orderDisputes: IOrderDispute[];
  pagination: IMetaPagination;
}

export interface IChangePaginationOrderDisputesPayload {
  page: number;
  perPage: number;
}

export type IFetchOrderDisputesReducerAction = ReducerAction<
  IFetchOrderDisputesPayload,
  OrderDisputesActionTypes.FETCH_ORDER_DISPUTES
>;

export type ISetIsLoadingOrderDisputesReducerActions = ReducerAction<boolean, OrderDisputesActionTypes.SET_IS_LOADING>;

export type IChangePaginationOrderDisputesReducerActions = ReducerAction<
  IChangePaginationOrderDisputesPayload,
  OrderDisputesActionTypes.CHANGE_PAGINATION
>;

export type IUpdateOrderDisputeStatusReducerActions = ReducerAction<
  IOrderDispute,
  OrderDisputesActionTypes.UPDATE_STATUS
>;

export type IAddOrderDisputeReducerAction = ReducerAction<IOrderDispute, OrderDisputesActionTypes.ADD>;

export type ISetBufferingOrderDisputeReducerAction = ReducerAction<boolean, OrderDisputesActionTypes.SET_BUFFERING>;

export type OrderDisputesReducerActions =
  | IFetchOrderDisputesReducerAction
  | ISetIsLoadingOrderDisputesReducerActions
  | IChangePaginationOrderDisputesReducerActions
  | IUpdateOrderDisputeStatusReducerActions
  | IAddOrderDisputeReducerAction
  | ISetBufferingOrderDisputeReducerAction;

export enum OrderDisputesActionTypes {
  FETCH_ORDER_DISPUTES = 'FETCH_ORDER_DISPUTES',
  SET_IS_LOADING = 'SET_IS_LOADING',
  CHANGE_PAGINATION = 'CHANGE_PAGINATION',
  UPDATE_STATUS = 'UPDATE_STATUS',
  ADD = 'ADD',
  SET_BUFFERING = 'SET_BUFFERING',
}
