import React from 'react';
import { Table } from 'antd';

import { changePagination } from 'modules/owners/store/ownersSms/actions';

import { tableColumns } from 'modules/owners/pages/sms/components/Table/columns';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { Pagination } from 'components/Pagination';

export const OwnersSMSPageTable: React.FC = () => {
  const { sms, isLoading, pagination } = useAppSelector((state) => state.ownersSms);

  const dispatch = useAppDispatch();

  return (
    <>
      <Table
        className="big-table"
        size="small"
        columns={tableColumns}
        dataSource={sms}
        loading={isLoading}
        pagination={false}
        rowKey={(sms) => sms.id}
        scroll={{ x: true }}
      />
      <Pagination
        customStyle="ml-2 my-2"
        pagination={pagination}
        isLoading={isLoading}
        onPaginationChange={(page, perPage) => dispatch(changePagination(page, perPage))}
      />
    </>
  );
};
