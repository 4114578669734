import api from 'utils/axios';
import { BackendResponse } from 'types/BackendResponse';
import { Agent } from 'modules/agents/types/agent.interface';

export interface UpdateAgentRequestParams {
  id: number;
  name: string;
  parentId?: number | null;
}

export async function updateAgentRequest(params: UpdateAgentRequestParams): Promise<void> {
  await api.post<BackendResponse<Agent>>('/agents/update', params);
}
