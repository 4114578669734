import { useEffect, useRef } from 'react';
import { Channel } from 'laravel-echo';
import { echo } from 'utils/echo.util';

export function useChannel(
  channelName: string,
  eventListeners: {
    [eventName: string]: (data: any) => void;
  },
  depedencies: any[] = [],
  disabled: boolean = false
) {
  const channelRef = useRef<Channel>();
  const currentListeners = useRef<string[]>([]);

  useEffect(() => {
    if (disabled) return;

    channelRef.current = echo.channel(channelName);

    for (const eventName in eventListeners) {
      channelRef.current?.listen(eventName, eventListeners[eventName]);
    }

    currentListeners.current = Object.keys(eventListeners);

    return () => {
      currentListeners.current.forEach((eventName) => {
        channelRef.current?.stopListening(eventName);
      });

      currentListeners.current = [];
    };
  }, [channelName, eventListeners, disabled, ...depedencies]);
}
