import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';
import { useBanksFromServer, useMerchantsFromServer } from 'hooks/useDataFromServer';
import { OrderTypes } from 'modules/orders/enums/orderTypes.enum';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'ID заявки',
    name: 'id',
    type: 'input-number',
  },
  {
    title: 'ID заявки клиента',
    name: 'remoteOrderId',
    type: 'input',
    allowClear: true,
    width: 200,
  },
  {
    title: 'Провайдеры',
    name: 'bankIds',
    type: 'lazy-select',
    mode: 'multiple',
    allowClear: true,
    useDataHook: useBanksFromServer,
    cacheKey: 'providers-filter',
    width: 200,
  },
  {
    title: 'Исключить провайдеров',
    name: 'excludeBankIds',
    type: 'lazy-select',
    mode: 'multiple',
    allowClear: true,
    useDataHook: useBanksFromServer,
    cacheKey: 'providers-filter',
    width: 200,
  },
  {
    title: 'Клиенты',
    name: 'merchantIds',
    type: 'lazy-select',
    mode: 'multiple',
    allowClear: true,
    useDataHook: useMerchantsFromServer,
    cacheKey: 'customers-filter',
    width: 200,
  },
  {
    title: 'Тип заявки',
    name: 'type',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Любой',
        value: '',
      },
      {
        label: 'Входящие',
        value: OrderTypes.INCOMING,
      },
      {
        label: 'Исходящие',
        value: OrderTypes.OUTCOMING,
      },
    ],
  },
  {
    title: 'Доступна ли привязка',
    name: 'assignable',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Любое значение',
        value: '',
      },
      {
        label: 'Доступна',
        value: 1,
      },
      {
        label: 'Недоступна',
        value: 0,
      },
    ],
  },
  {
    title: 'Время заявки',
    name: 'onlyExpired',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Все заявки',
        value: '',
      },
      {
        label: 'Просроченные',
        value: 1,
      },
    ],
  },
  {
    title: 'Тип клиента',
    name: 'isHighRiskMerchant',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Любой',
        value: '',
      },
      {
        label: 'High risk',
        value: 1,
      },
      {
        label: 'Обменник',
        value: 0,
      },
    ],
  },
  {
    title: 'Привязан владелец',
    name: 'hasPayments',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Привязан',
        value: true,
      },
      {
        label: 'Не привязан',
        value: false,
      },
    ],
  },
  {
    title: 'Сортировка по',
    name: 'sortBy',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Дате создания',
        value: 'created_at',
      },
      { label: 'Сумма', value: 'sum' },
    ],
  },
  {
    title: 'Направление сортировки',
    name: 'sortDirection',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'По возрастанию',
        value: 'asc',
      },
      { label: 'По убыванию', value: 'desc' },
    ],
  },
];
