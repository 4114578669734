import dayjs from 'dayjs';

export function utcToMoscowDateString(date: string): string {
  let moscowDate: string = new Date(date).toLocaleString('ru-RU', { timeZone: 'Europe/Moscow' });

  const fullDate = moscowDate.split(' ');
  const time = fullDate[1];
  const [day, month, year] = fullDate[0].split('.');
  moscowDate = `${year}-${month}-${day} ${time}`;

  return dayjs(moscowDate, ['YYYY-MM-DD HH:mm:ss']).format('DD.MM.YYYY HH:mm:ss');
}
