import { AutoSizer, CellMeasurerCache, InfiniteLoader, List, ListRowRenderer, WindowScroller } from 'react-virtualized';
import React from 'react';
import { useTheme } from 'contexts';
import { Spin } from 'antd';

interface Props<RecordType = any> {
  data: RecordType[];
  onLoadMore: () => Promise<void>;
  total: number;
  rowRenderer: ListRowRenderer;
  isLoading: boolean;
  listRef: React.MutableRefObject<List | null>;
  cellMeasurerCache: CellMeasurerCache;
}

export const LazyTableListRenderer: React.FC<Props> = ({
  data,
  onLoadMore,
  total,
  rowRenderer,
  isLoading,
  listRef,
  cellMeasurerCache,
}) => {
  const {
    currentTheme: {
      token: { colorBgContainer },
    },
  } = useTheme();

  return (
    <div style={{ backgroundColor: colorBgContainer }} className="rounded-b-md">
      <AutoSizer disableHeight={true}>
        {({ width }) => (
          <WindowScroller>
            {({ height, isScrolling, onChildScroll, scrollTop }) => (
              <InfiniteLoader isRowLoaded={({ index }) => !!data[index]} loadMoreRows={onLoadMore} rowCount={total}>
                {({ onRowsRendered, registerChild }) => (
                  <List
                    autoHeight
                    onRowsRendered={onRowsRendered}
                    overscanRowCount={20}
                    ref={(ref) => {
                      registerChild(ref);

                      listRef.current = ref;
                    }}
                    height={height}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                    rowCount={data.length}
                    rowHeight={cellMeasurerCache.rowHeight}
                    rowRenderer={rowRenderer}
                    scrollTop={scrollTop}
                    width={width}
                  />
                )}
              </InfiniteLoader>
            )}
          </WindowScroller>
        )}
      </AutoSizer>
      {isLoading && (
        <div className="flex justify-center items-center h-16">
          <Spin />
        </div>
      )}
      {data.length === total && !isLoading && (
        <p className="flex justify-center items-center h-16">
          <b>Все данные подгружены.</b>
        </p>
      )}
    </div>
  );
};
