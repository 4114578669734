import useSWR from 'swr';
import { FetchUsersParams, fetchUsersRequest } from 'modules/users/api/fetchUsers.request';

export function useFetchUsersRequest(params: FetchUsersParams) {
  const { data, isLoading, mutate } = useSWR([params, 'fetch-users'], async ([params]) => {
    return await fetchUsersRequest(params);
  });

  return { isFetchingUsers: isLoading, users: data, fetchUsers: mutate };
}
