import React, { useEffect, useState } from 'react';
import { message, Switch } from 'antd';
import { BankAccount } from 'modules/bankAccounts/types/bankAccount.interface';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { permissions } from 'policies/permissions';
import { useEnableIncomingBankAccountRequest } from 'modules/bankAccounts/hooks/useEnableIncomingBankAccountRequest.hook';
import { useDisableIncomingBankAccountRequest } from 'modules/bankAccounts/hooks/useDisableIncomingBankAccountRequest.hook';

interface Props {
  account: BankAccount;
}

export const BankAccountIncomingEnabledSwitch: React.FC<Props> = ({ account }) => {
  const [checked, setChecked] = useState<boolean>(account.inEnabled);

  const { enableIncomingBankAccount, isEnablingIncomingBankAccount } = useEnableIncomingBankAccountRequest();
  const { disableIncomingBankAccount, isDisablingIncomingBankAccount } = useDisableIncomingBankAccountRequest();

  const { userCanAll } = useUserPermissions();

  useEffect(() => {
    setChecked(account.inEnabled);
  }, [account]);

  async function handleChange(newChecked: boolean) {
    try {
      if (newChecked) {
        await enableIncomingBankAccount(account.id);
      } else {
        await disableIncomingBankAccount(account.id);
      }

      message.success({
        content: 'Входящие платежи успешно изменены!',
        duration: 1.5,
      });
      setChecked(newChecked);
    } catch {
      message.error('Ошибка изменения входящих платежей!');
    }
  }

  return (
    <Switch
      checked={checked}
      onChange={handleChange}
      disabled={!userCanAll(permissions.bankAccount.in.enable, permissions.bankAccount.in.disable)}
      loading={isEnablingIncomingBankAccount || isDisablingIncomingBankAccount}
    />
  );
};
