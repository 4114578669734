export enum OrderStatusEnum {
  IN_PROGRESS = 1,
  ENTERED = 2,
  NOT_ENTERED = 3,
  EXECUTED = 4,
  NOT_EXECUTED = 5,
  UNKNOWN_ENTERED = 6,
  AWAITING = 7,
  WAITING_FOR_REFUND = 8,
  REFUNDED = 9,
  CANCELED_BY_CLIENT = 11,
  WAITING_FOR_AUTO_ASSIGN_OWNER = 10,
  WAITING_CHOOSE_BANK = 12,
  EXECUTED_PARTLY = 13,
}
