import api from 'utils/axios';

export interface CreateAgentRequestParams {
  name: string;
  parentId: number;
  users: CreateAgentRequestParamsUser[];
}

export interface CreateAgentRequestParamsUser {
  name: string;
  email: string;
  password: string;
}

export async function createAgentRequest(params: CreateAgentRequestParams): Promise<void> {
  await api.post('/agents/create', params);
}
