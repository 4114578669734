import useSWRMutation from 'swr/mutation';
import { disableSbpBankAccountRequest } from 'modules/bankAccounts/api/disableSbpBankAccount.request';

export function useDisableSbpBankAccountRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'disable-sbp-bank-account',
    (
      _,
      {
        arg,
      }: {
        arg: number;
      }
    ) => disableSbpBankAccountRequest(arg)
  );

  return { disableSbpBankAccount: trigger, isDisablingSbpBankAccount: isMutating };
}
