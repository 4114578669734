import React from 'react';
import { Card } from 'antd';
import { Tooltip } from 'components/Tooltip';
import { InputNumber } from 'components/Input';
import { SelectedOwner } from 'modules/orders/components/AssignOwnersToOrderForm/interfaces/selectedOwner.interface';

interface Props {
  owner: SelectedOwner;
  onInputChange: (value: number | null) => void;
}

export const AssignOwnersToTransactionsFormOwnerCard: React.FC<Props> = ({ owner, onInputChange }) => {
  return (
    <>
      <Card size="small" className="mb-3" title={`Владелец №${owner.ownerId}`}>
        <ul className="mb-2">
          <Tooltip title="Часть от суммы транзакции, которая привязывается к владельцу">
            <InputNumber
              key={owner.ownerId}
              value={owner.amount}
              onChange={(value) => onInputChange(value as number)}
              onKeyUp={(e) => (e.target as HTMLInputElement).value === '' && onInputChange(null)}
              isAmountInput
              addonBefore="Сумма для владельца"
              decimalSeparator=","
              className="w-full"
            />
          </Tooltip>
        </ul>
      </Card>
    </>
  );
};
