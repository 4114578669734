import React, { FC } from 'react';
import { App, Button } from 'antd';
import { PaymentStatistic } from 'components/PaymentStatistic';
import { useFetchPaymentsStatisticRequest } from 'modules/payments/hooks';
import { FetchPaymentsStatisticRequestParams } from 'modules/payments/api/fetchPaymentsStatistic.request';
import { localDateTimeToUtc } from 'utils/localDateTimeToUtc.util';

interface Props {
  params: FetchPaymentsStatisticRequestParams | (() => FetchPaymentsStatisticRequestParams);
  onSuccess?: () => void;
  onRequest?: () => void;
}

export const PaymentsStatisticBlock: FC<Props> = ({ params, onSuccess, onRequest }) => {
  const { fetchPaymentsStatistic, isFetchingPaymentsStatistic, paymentsStatistic } = useFetchPaymentsStatisticRequest();

  const { message } = App.useApp();

  async function fetchData() {
    const statisticParams = typeof params === 'function' ? params() : params;

    try {
      onRequest?.();

      await fetchPaymentsStatistic({
        ...statisticParams,
        from: statisticParams.from ? localDateTimeToUtc(statisticParams.from) : undefined,
        to: statisticParams.to ? localDateTimeToUtc(statisticParams.to) : undefined,
      });

      message.success('Статистика платежей запрошена!');
      onSuccess?.();
    } catch {
      message.error('Не удалось запросить статистику');
    }
  }

  return (
    <div className="flex flex-row items-center gap-1">
      <Button
        type="primary"
        onClick={fetchData}
        loading={isFetchingPaymentsStatistic}
        disabled={isFetchingPaymentsStatistic}
      >
        Запросить статистику
      </Button>
      <PaymentStatistic statistic={paymentsStatistic} isLoading={isFetchingPaymentsStatistic} />
    </div>
  );
};
