import React, { useMemo } from 'react';

import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { ColumnType } from 'antd/lib/table';
import { permissions } from 'policies/permissions';
import { Merchant } from 'modules/merchants/types';
import { MerchantsTableRowActions } from 'modules/merchants/components';

interface Config {
  onMerchantEdit: (merchantId: number) => void;
  onMerchantCommissionEdit: (merchantId: number) => void;
  onMerchantDelete: (merchant: Merchant) => void;
  onMerchantBalanceClick: (merchantId: number) => void;
  onMerchantTokensEdit: (merchantId: number) => void;
}

export function useMerchantsColumns({
  onMerchantEdit,
  onMerchantCommissionEdit,
  onMerchantDelete,
  onMerchantBalanceClick,
  onMerchantTokensEdit,
}: Config): ColumnType<Merchant>[] {
  return useMemo<ColumnType<Merchant>[]>(
    () => [
      {
        title: 'Наименование клиента',
        dataIndex: 'name',
      },
      {
        title: 'Алиас',
        dataIndex: 'aliasOrder',
      },
      {
        title: 'Действия',
        dataIndex: 'actions',
        render: (_: any, merchant: Merchant) => (
          <>
            <ProtectedComponent requiredPermissions={[permissions.merchant.update]}>
              <MerchantsTableRowActions
                onMerchantEdit={() => onMerchantEdit(merchant.id)}
                onMerchantCommissionEdit={() => onMerchantCommissionEdit(merchant.id)}
                onMerchantDelete={() => onMerchantDelete(merchant)}
                onMerchantBalanceClick={() => onMerchantBalanceClick(merchant.id)}
                onMerchantTokensEdit={() => onMerchantTokensEdit(merchant.id)}
              />
            </ProtectedComponent>
          </>
        ),
      },
    ],
    []
  );
}
