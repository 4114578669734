import { Dispatch } from 'redux';

import {
  MerchantsActionTypes,
  FetchMerchantsReducerAction,
  CreateMerchantReducerAction,
  DeleteMerchantReducerAction,
  UpdateMerchantReducerAction,
} from './types';

import instance from 'utils/axios';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';
import { UpdateMerchantParams } from './interfaces/updateMerchantParams.interface';
import { FetchMerchantsParams } from './interfaces/fetchMerchantsParams.interface';
import { UpdateMerchantCommissionsParams } from './interfaces/updateMerchantCommissionsParams.type';
import { CreateMerchantParams } from 'modules/merchants/types/createMerchantParams.interface';
import { Merchant } from 'modules/merchants/types/merchant.interface';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';

export function fetchMerchants(params: FetchMerchantsParams & RequestParamsWithPagination) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setLoading());

      const {
        data: { data, meta },
      } = await instance.get('/merchants', {
        params,
      });

      dispatch<FetchMerchantsReducerAction>({
        type: MerchantsActionTypes.FETCH_MERCHANTS,
        payload: { merchants: data, pagination: meta },
      });
    } finally {
      dispatch<any>(setLoading(false));
    }
  };
}

export function createMerchant(params: CreateMerchantParams) {
  return async (dispatch: Dispatch) => {
    try {
      const {
        data: { data },
      } = await instance.post('/merchants/create', sanitizeRequestParams(params));

      dispatch<CreateMerchantReducerAction>({
        type: MerchantsActionTypes.CREATE_MERCHANT,
        payload: data as Merchant,
      });

      return true;
    } catch {
      dispatch<any>(setLoading(false));

      return false;
    }
  };
}

export function updateMerchantCommissions(params: {
  commissions: UpdateMerchantCommissionsParams;
  merchantId: number;
}) {
  return async (dispatch: Dispatch) => {
    try {
      await instance.post('/merchants/commissions/update', sanitizeRequestParams(params));

      return true;
    } catch {
      dispatch<any>(setLoading(false));

      return false;
    }
  };
}

export function updateMerchant(params: UpdateMerchantParams) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setLoading(true));

      const {
        data: { data },
      } = await instance.post('/merchants/update', sanitizeRequestParams(params));

      dispatch<UpdateMerchantReducerAction>({
        type: MerchantsActionTypes.UPDATE_MERCHANT,
        payload: data as Merchant,
      });

      return true;
    } catch {
      return false;
    } finally {
      dispatch<any>(setLoading(false));
    }
  };
}

export function deleteMerchant(merchantId: number) {
  return async (dispatch: Dispatch) => {
    try {
      await instance.post('/merchants/delete', {
        id: merchantId,
      });

      dispatch<DeleteMerchantReducerAction>({
        type: MerchantsActionTypes.DELETE_MERCHANT,
        payload: merchantId as number,
      });
    } catch (error) {
      dispatch<any>(setLoading(false));

      throw error;
    }
  };
}

export function setLoading(value: boolean = true) {
  return (dispatch: Dispatch) => {
    dispatch({ type: MerchantsActionTypes.SET_LOADING, payload: value });
  };
}

export function changePagination(page: number, pageSize: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: MerchantsActionTypes.CHANGE_PAGE,
      payload: { page, perPage: pageSize },
    });
  };
}
