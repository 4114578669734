import { DEFAULT_DATETIME_STRING_FORMAT } from 'constants/dates';
import { OrderDisputesStatusesEnum } from 'modules/disputes/types/OrderDisputeStatusesEnum';
import { IOrderDispute } from 'modules/disputes/types/IOrderDispute';
import dayjs from 'dayjs';
import React from 'react';

interface Props {
  dispute: IOrderDispute;
}

export const OrderDisputesPageTableExecutionDurationColumn: React.FC<Props> = ({ dispute }) => {
  const now = dayjs.utc();

  let difference: number;

  const isClosedDispute = dispute.status === OrderDisputesStatusesEnum.CLOSED && dispute.closedAt;

  if (isClosedDispute) {
    difference = dayjs.utc(dispute.closedAt, DEFAULT_DATETIME_STRING_FORMAT).diff(dayjs.utc(dispute.createdAt));
  } else {
    difference = now.diff(dayjs.utc(dispute.createdAt, DEFAULT_DATETIME_STRING_FORMAT), 'milliseconds');
  }

  const numberOfDays = Math.trunc(difference / (1000 * 60 * 60 * 24));

  return (
    <p className={`${isClosedDispute && 'text-green-600'}`}>
      {(numberOfDays ? numberOfDays + 'д. ' : '') + dayjs.utc(difference).format('HH:mm:ss')}
    </p>
  );
};
