import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useEffect, useRef, useState } from 'react';
import { LazyTableProps } from 'components/LazyTable/LazyTable';
import { IMetaPagination } from 'types/IMetaPagination';
import { RootState } from 'app/store';

type ExcludedStores = 'auth' | 'configuration' | 'goips' | 'bankAccount';

type StoreKeys = Exclude<keyof RootState, ExcludedStores>;

type KeysOfUnion<T> = T extends T ? keyof T : never;

interface UseLazyTableConfig {
  store: StoreKeys;
  dataField: KeysOfUnion<RootState[StoreKeys]>;
  fetchAction: (filters: any) => any;
  loadMoreAction: (filters: any) => any;
  changePageAction: (payload: { page: number; perPage: number }) => any;
  resetAction: () => any;
  filters: any;
}

interface UseLazyTableReturn {
  fetch: () => void;
  setIsTableView: (value: boolean) => void;
  isTableView: boolean;
  isLoading: boolean;
  pagination: IMetaPagination;
}

export function useLazyTable(config: UseLazyTableConfig): [Omit<LazyTableProps, 'columns'>, UseLazyTableReturn] {
  const { store: storeName, dataField, fetchAction, loadMoreAction, resetAction, filters, changePageAction } = config;

  const store = useAppSelector((state) => state[storeName])!;

  const dispatch = useAppDispatch();

  const [isTableView, setIsTableView] = useState<boolean>(false);

  const pagination = store.pagination;
  const isLoading = store.isLoading;
  const data = store[dataField as keyof typeof store] as unknown as any[];

  const lastFetchedPage = useRef<number>(pagination.page);

  useEffect(() => {
    reset();
    fetch(1);
  }, [filters, isTableView]);

  useEffect(() => {
    lastFetchedPage.current = pagination.page;
  }, [pagination.page]);

  async function fetch(page = pagination.page, perPage = pagination.perPage) {
    await dispatch(fetchAction({ ...filters, page, perPage }));
  }

  async function fetchMore() {
    const nextPage = pagination.page + 1;

    if (nextPage === lastFetchedPage.current) return;

    lastFetchedPage.current = nextPage;

    await dispatch(loadMoreAction({ ...filters }));
  }

  function reset() {
    dispatch(resetAction());
  }

  function changeView(isTableView: boolean) {
    setIsTableView(isTableView);
    reset();
  }

  function onPaginationChange(page: number, perPage: number = 20) {
    dispatch(changePageAction({ page, perPage }));
    fetch(page, perPage);
  }

  return [
    { pagination, data, isTableView, onLoadMore: fetchMore, isLoading, onPaginationChange },
    { fetch, setIsTableView: changeView, isLoading, isTableView, pagination },
  ];
}
