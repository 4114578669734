import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

/**
 * Страница 404
 *
 * @author Собянин Виктор <telegram: @victorsobyanin>
 */
export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen min-w-full justify-center items-center">
      <h1 className="font-bold text-3xl mb-5">Страница не найдена</h1>
      <Button type="primary" onClick={() => navigate('/')}>
        Перейти на главную
      </Button>
    </div>
  );
};
