import React, { useCallback, useEffect, useState } from 'react';
import { App, Switch } from 'antd';

import { Bank } from 'modules/banks/types/bank.interface';
import { useToggleBankRequest } from 'modules/banks/hooks/useToggleBankRequest.hook';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { permissions } from 'policies/permissions';

interface Props {
  bank: Bank;
  onChanged?: (checked: boolean) => void;
}

export const BankEnabledSwitch: React.FC<Props> = ({ bank, onChanged }) => {
  const [isChecked, setIsChecked] = useState<boolean>(bank.enabled);

  const { toggleBank, isTogglingBank } = useToggleBankRequest();
  const { userCanAll } = useUserPermissions();

  const { message } = App.useApp();

  useEffect(() => {
    setIsChecked(bank.enabled);
  }, [bank]);

  const handleChange = useCallback(
    async (checked: boolean) => {
      try {
        await toggleBank({
          id: bank.id,
          enabled: checked,
        });

        setIsChecked(checked);
        onChanged?.(checked);
        message.success(`Банк успешно ${checked ? 'включен' : 'выключен'}`);
      } catch {
        message.error(`Не удалось ${checked ? 'включить' : 'выключить'} банк`);
      }
    },
    [bank]
  );

  if (!userCanAll(permissions.bank.enable, permissions.bank.disable)) {
    return null;
  }

  return <Switch loading={isTogglingBank} checked={isChecked} onChange={handleChange} />;
};
