import { asyncThunkCreator, buildCreateSlice } from '@reduxjs/toolkit';
import instance from 'utils/axios';
import { BankTransactionsState } from './types/bankTransactionsState.interface';
import { FetchBankTransactionsParams } from './types/fetchBankTransactionsParams.interface';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';

const createSlice = buildCreateSlice({ creators: { asyncThunk: asyncThunkCreator } });

const bankTransactionsSlice = createSlice({
  name: 'bankTransactions',
  initialState: {
    bankTransactions: [],
    isLoading: false,
    pagination: {
      page: 1,
      pages: 0,
      perPage: 20,
      total: 0,
    },
  } as BankTransactionsState,
  reducers: (create) => ({
    fetchBankTransactions: create.asyncThunk(
      async (params: FetchBankTransactionsParams & RequestParamsWithPagination) => {
        const { data } = await instance.get('transaction/list', { params: sanitizeRequestParams(params) });

        return data;
      },
      {
        fulfilled: (state, action) => {
          state.isLoading = false;
          state.bankTransactions = action.payload.data;
          state.pagination = action.payload.meta;
        },
        rejected: (state) => {
          state.isLoading = false;
        },
        pending: (state) => {
          state.isLoading = true;
        },
      }
    ),
  }),
});

export const bankTransactionsReducer = bankTransactionsSlice.reducer;

export const { fetchBankTransactions } = bankTransactionsSlice.actions;
