import React from 'react';

import { OwnersTransactionPage } from 'modules/owners/pages/transactions';
import { ConfigurationPage } from 'modules/configuration/pages/main';
import { PaymentsListPage } from 'modules/payments/pages/list/PaymentsListPage';
import { OwnersSMSPage } from 'modules/owners/pages/sms';
import { ReportsPage } from 'modules/reports/pages/list/ReportsPage';
import { GoipsPage } from 'modules/goips/pages/list';

import { INavigationRoute } from 'app/navigation/types';
import { UsersPage } from 'modules/users/pages/list';
import { AgentsPage } from 'modules/agents/pages/list';
import { permissions } from 'policies/permissions';

import {
  ApartmentOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CrownOutlined,
  DollarOutlined,
  FileTextOutlined,
  GatewayOutlined,
  MehOutlined,
  NotificationOutlined,
  PhoneOutlined,
  RobotOutlined,
  SettingOutlined,
  TeamOutlined,
  TransactionOutlined,
} from '@ant-design/icons';
import { PartnersMainPage } from 'modules/partners/pages/main';
import { MerchantsPage } from 'modules/merchants/pages/list';
import { MerchantsBalancesPage } from 'modules/merchants/pages/balances';
import { OrdersPage } from 'modules/orders/pages/list';
import { ActiveOrdersPage } from 'modules/orders/pages/active';
import { BanksPage } from 'modules/banks/pages/list';
import { OwnersPage } from 'modules/owners/pages/list';
import { OrderDisputesPage } from 'modules/disputes/pages/list';
import { BankAccountsListPage } from 'modules/bankAccounts/pages/list';
import { DevicesListPage } from 'modules/devices/pages/list';
import { BankTransactionsPage } from 'modules/bankTransactions/pages/list';
import { RejectedTransactionsPage } from 'modules/rejectedTransactions/pages/list';

export const privateRoutes: INavigationRoute[] = [
  {
    path: '/owners',
    title: 'Владельцы',
    component: <OwnersPage />,
    icon: <CrownOutlined />,
    permissions: [permissions.module.owner],
    subroutes: [
      {
        path: '/bank-accounts',
        title: 'Счета',
        component: <BankAccountsListPage />,
        permissions: [permissions.module.account],
      },
      {
        path: '/transactions',
        title: 'Транзакции',
        component: <OwnersTransactionPage />,
        permissions: [permissions.module.ownerBalanceHistory],
      },
      {
        path: '/sms',
        title: 'СМС',
        component: <OwnersSMSPage />,
        permissions: [permissions.module.sms],
      },
      {
        path: '/payments',
        title: 'Платежи',
        component: <PaymentsListPage />,
        icon: <DollarOutlined />,
        permissions: [permissions.module.payment],
      },
    ],
  },
  {
    path: '/customers',
    title: 'Клиенты',
    component: <MerchantsPage />,
    icon: <TeamOutlined />,
    permissions: [permissions.module.merchant],
    subroutes: [
      {
        path: '/balances',
        title: 'Балансы клиентов',
        component: <MerchantsBalancesPage />,
        permissions: [permissions.module.merchantBalance],
      },
    ],
  },
  {
    path: '/order_disputes',
    title: 'Диспуты',
    component: <OrderDisputesPage />,
    icon: <NotificationOutlined />,
    permissions: [permissions.module.dispute],
  },
  {
    path: '/goips',
    title: 'Шлюзы',
    component: <GoipsPage />,
    icon: <GatewayOutlined />,
    permissions: [permissions.module.goip],
  },
  {
    path: '/reports',
    title: 'Отчёты',
    component: <ReportsPage />,
    icon: <FileTextOutlined />,
    permissions: [permissions.module.report],
  },
  {
    path: '/transactions_static',
    title: 'Транзакции',
    component: <OrdersPage />,
    icon: <TransactionOutlined />,
    permissions: [permissions.module.order],
  },
  {
    path: '/bank-transactions',
    title: 'Банковские транзакции',
    component: <BankTransactionsPage />,
    icon: <TransactionOutlined />,
    permissions: [permissions.module.bankTransactions],
  },
  {
    path: '/live_transactions',
    title: 'Текущие заявки',
    component: <ActiveOrdersPage />,
    icon: <ClockCircleOutlined />,
    permissions: [permissions.module.processingOrder],
  },
  {
    path: '/rejected-transactions',
    title: 'Отклонённые заявки',
    component: <RejectedTransactionsPage />,
    icon: <CloseOutlined />,
    permissions: [permissions.module.rejectedOrder],
  },
  {
    path: '/configuration',
    title: 'Конфигурация',
    component: <ConfigurationPage />,
    icon: <SettingOutlined />,
    permissions: [permissions.module.config],
  },
  {
    path: '/users',
    title: 'Пользователи',
    component: <UsersPage />,
    icon: <MehOutlined />,
    permissions: [permissions.module.user],
  },
  {
    path: '/banks',
    title: 'Банки',
    component: <BanksPage />,
    icon: <DollarOutlined />,
    permissions: [permissions.module.bank],
  },
  {
    path: '/agents',
    title: 'Агенты',
    component: <AgentsPage />,
    icon: <RobotOutlined />,
    permissions: [permissions.module.agent],
  },
  {
    path: '/partners',
    title: 'Партнеры',
    component: <PartnersMainPage />,
    icon: <ApartmentOutlined />,
    permissions: [permissions.module.partner],
  },
  {
    path: '/devices',
    title: 'Устройства',
    component: <DevicesListPage />,
    icon: <PhoneOutlined />,
    permissions: [permissions.module.device],
  },
];
