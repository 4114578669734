import { enableSbpBankAccountRequest } from 'modules/bankAccounts/api/enableSbpBankAccount.request';
import useSWRMutation from 'swr/mutation';

export function useEnableSbpBankAccountRequest() {
  const { trigger, isMutating } = useSWRMutation('enable-sbp-bank-account', (_, { arg }: { arg: number }) =>
    enableSbpBankAccountRequest(arg)
  );

  return {
    enableSbpBankAccount: trigger,
    isEnablingSbpBankAccount: isMutating,
  };
}
