import React, { FC } from 'react';
import { App, Button, Popconfirm } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useDeleteReportRequest } from 'modules/reports/hooks';

interface Props {
  id: number;
  onSuccess: () => void;
}

export const DeleteReportButton: FC<Props> = ({ id, onSuccess }) => {
  const { deleteReport, isDeletingReport } = useDeleteReportRequest();

  const { message } = App.useApp();

  async function handleDelete() {
    try {
      await deleteReport(id);

      message.success('Отчет успешно удален');
      onSuccess();
    } catch {
      message.error('Не удалось удалить отчет');
    }
  }

  return (
    <Popconfirm cancelText="Отмена" title="Вы уверенны, что хотите удалить отчет?" onConfirm={handleDelete}>
      <Button
        type="primary"
        danger
        shape="circle"
        icon={<CloseOutlined />}
        loading={isDeletingReport}
        disabled={isDeletingReport}
      />
    </Popconfirm>
  );
};
