import instance from './axios';

export async function downloadAndSaveFrom<T>(requestUrl: string, requestParams?: T) {
  let blob: Blob;
  let filename: string;

  if (requestUrl.includes('://')) {
    const res = await fetch(requestUrl, { mode: 'cors' });

    blob = await res.blob();
    filename =
      requestUrl.split('/').pop()?.split('?').shift() + '.' + res.headers.get('content-type')?.split('/').pop();
  } else {
    const res = await instance.post(requestUrl, requestParams ?? {}, {
      responseType: 'blob',
    });

    blob = new Blob([res.data]);
    filename = res.headers['content-disposition']?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)?.[1];
  }

  const anchor = document.createElement('a');

  anchor.setAttribute('download', filename);
  anchor.href = URL.createObjectURL(blob);

  document.body.appendChild(anchor);

  anchor.click();
}
