import React, { useState } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { App, Button, Drawer, Popconfirm, Space, Spin, Table } from 'antd';

import { MerchantTokensDrawerCreatedTokenModal } from './components/CreatedTokenModal';

import { MerchantToken } from 'modules/merchants/types';

import { useFetchMerchantTokensRequest, useRevokeMerchantTokenRequest } from 'modules/merchants/hooks';
import { useMerchantTokensDrawerColumns } from './hooks/useMerchantTokensDrawerColumns.hook';

interface Props {
  merchantId: number;
  onClose: () => void;
}

export const MerchantTokensDrawer: React.FC<Props> = ({ merchantId, onClose }) => {
  const { merchantTokens, isFetchingMerchantTokens, fetchMerchantTokens } = useFetchMerchantTokensRequest(merchantId);
  const { revokeToken, isRevoking } = useRevokeMerchantTokenRequest();

  const [isTokensModalVisible, setIsTokensModalVisible] = useState(false);

  const columns = useMerchantTokensDrawerColumns({
    onRevoke: async (token: MerchantToken) => {
      try {
        await revokeToken({ merchantId, tokenId: token.id });

        await fetchMerchantTokens();

        message.success('Токен успешно отозван!');
      } catch {
        message.error('Не удалось отозвать токен');
      }
    },
  });

  const { message } = App.useApp();

  return (
    <Drawer
      open={!!merchantId}
      width="50%"
      title={
        <Space size="small" className="flex justify-between items-center">
          <h3>Токены клиента</h3>
          <Popconfirm
            title="Вы действительно хотите создать токен?"
            okType="primary"
            okText="Создать"
            cancelText="Отменить"
            onConfirm={() => setIsTokensModalVisible(true)}
          >
            <Button className="ml-0" type="primary" shape="round" icon={<PlusCircleOutlined />}>
              Создать токен
            </Button>
          </Popconfirm>
        </Space>
      }
      onClose={onClose}
    >
      <Spin spinning={isFetchingMerchantTokens || isRevoking}>
        <Table columns={columns} dataSource={merchantTokens} pagination={false} />
      </Spin>
      <MerchantTokensDrawerCreatedTokenModal
        merchantId={merchantId}
        isShown={isTokensModalVisible}
        onCancel={() => {
          setIsTokensModalVisible(false);

          fetchMerchantTokens();
        }}
      />
    </Drawer>
  );
};
