import { Agent } from 'modules/agents/types/agent.interface';
import instance from 'utils/axios';

export async function fetchAgentRequest(id: number): Promise<Agent> {
  const {
    data: { data },
  } = await instance.get('/agents/item', { params: { id } });

  return data;
}
