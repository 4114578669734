import instance from 'utils/axios';
import { BankAccount } from 'modules/bankAccounts/types/bankAccount.interface';

export async function enableOutcomingBankAccountRequest(id: number): Promise<BankAccount> {
  const {
    data: { data },
  } = await instance.post('/bank-accounts/enable-outcoming', { id });

  return data;
}
