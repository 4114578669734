import React from 'react';
import { App, Table } from 'antd';

import api from 'utils/axios';

import { GoipPort } from 'modules/goips/store/goips/types';
import { getGoips } from 'modules/goips/store/goips/action';

import { useGoipsChannelTableColumns } from 'modules/goips/pages/list/components/Table/components/channelTable/columns';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { OwnerStatusIdEnum } from 'modules/owners/store/owners/types';

interface Props {
  data: GoipPort[];
}

export const GoipsPageChannelTable: React.FC<Props> = ({ data }) => {
  const columns = useGoipsChannelTableColumns({ switchOwners });

  const { message } = App.useApp();

  const dispatch = useAppDispatch();

  async function switchOwners(ownerId: number, isActive = false): Promise<void> {
    await api.post('/owners/updateField', {
      ownerId,
      status: isActive ? OwnerStatusIdEnum.ACTIVE : OwnerStatusIdEnum.BLOCKED_GOIP_GATEWAY_IS_UNAVAILABLE,
    });

    const actionMsg = isActive ? 'отключен' : 'включен';
    message.success('Владелец: ' + ownerId + ' был ' + actionMsg);

    try {
      await dispatch(getGoips());
    } catch {
      message.error('Не удалось получить список шлюзов');
    }
  }

  return (
    <Table
      rowKey="id"
      size="small"
      dataSource={data.sort((a, b) => a.channelId - b.channelId)}
      columns={columns}
      scroll={{ y: 300 }}
      pagination={false}
      className="mr-12"
    />
  );
};
