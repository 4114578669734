import { App, Button, Form, Input, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { login } from 'modules/auth/store/auth/actions';
import { ILoginParams } from 'modules/auth/store/auth/types';

import LogoDarkIconSvg from 'assets/svg/Logo-dark.svg';
import LogoLightIconSvg from 'assets/svg/Logo-light.svg';
import { useTheme } from 'contexts';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';

const { Title } = Typography;

export const LoginPage = () => {
  const [form] = useForm<ILoginParams>();

  const { message } = App.useApp();

  const { isAuth } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const {
    choose,
    currentTheme: {
      token: { colorBgBase },
    },
  } = useTheme();

  if (isAuth) return <Navigate to="/" />;

  async function handleSubmit() {
    const messageKey = 'login-load-message';
    const params = form.getFieldsValue();

    try {
      message.open({
        type: 'loading',
        duration: 100,
        key: messageKey,
        content: 'Авторизация в системе...',
      });

      await dispatch(login(params));

      message.open({
        type: 'success',
        content: 'Вы успешно авторизовались!',
        key: messageKey,
      });
    } catch {
      message.open({
        type: 'error',
        key: messageKey,
        content: 'Не удалось авторизироваться. Проверьте логин и пароль, и попробуйте снова.',
      });
    }
  }

  return (
    <div className="flex justify-center items-center min-h-screen min-w-full pb-20">
      <div>
        <img src={choose(LogoLightIconSvg, LogoDarkIconSvg)} className="w-42 m-auto pt-6 pb-10" alt="Paykong" />
        <Form form={form} style={{ width: 457, background: colorBgBase }} className="pl-12 pr-12 pt-14 pb-12 rounded">
          <Title level={3}>Вход в систему</Title>
          <div className="flex flex-col pb-8 pt-7">
            <label htmlFor="email" className="mb-2">
              Email
            </label>
            <Form.Item name="email" className="mb-5">
              <Input
                id="email"
                type="email"
                placeholder="Email"
                style={{ background: colorBgBase }}
                className="pt-2 pb-2 focus:bg-white"
              />
            </Form.Item>
            <label htmlFor="password" className="mb-2">
              Пароль
            </label>
            <Form.Item name="password" labelAlign="left" className="mb-5">
              <Input
                id="password"
                type="password"
                placeholder="Пароль"
                style={{ background: colorBgBase }}
                className="pt-2 pb-2 focus:bg-white"
              />
            </Form.Item>
          </div>
          <Button className="w-full " type="primary" onClick={handleSubmit} style={{ height: '40px' }}>
            Войти
          </Button>
        </Form>
      </div>
    </div>
  );
};
