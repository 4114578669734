import { useBanksFromServer } from 'hooks/useDataFromServer';
import { OrderTypes } from 'modules/orders/enums/orderTypes.enum';
import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'ID владельца',
    name: 'ownerId',
    type: 'input-number',
  },
  {
    title: 'Сумма от',
    name: 'amountFrom',
    type: 'input-number',
    isAmountInput: true,
  },
  {
    title: 'Сумма до',
    name: 'amountTo',
    type: 'input-number',
    isAmountInput: true,
  },
  {
    title: 'Провайдер',
    name: 'bankId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useBanksFromServer,
    cacheKey: 'provider-filter',
  },
  {
    title: 'Привязана заявка',
    name: 'hasLinkedTransaction',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Не привязана',
        value: 0,
      },
      {
        label: 'Привязана',
        value: 1,
      },
    ],
  },
  {
    title: 'Тип заявки',
    name: 'type',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Входящие',
        value: OrderTypes.INCOMING,
      },
      {
        label: 'Исходящие',
        value: OrderTypes.OUTCOMING,
      },
    ],
  },
  {
    title: 'Даты',
    name: 'range',
    type: 'date-picker',
    rangeFields: ['createdAtFrom', 'createdAtTo'],
    allowClear: true,
    dateFormat: 'YYYY-MM-DD',
  },
];
