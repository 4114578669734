import React, { useMemo, useState } from 'react';
import { Cascader, Spin } from 'antd';

import { UseDataFromServerHookStatesEnum, useAvailableGoipsFromServer } from 'hooks/useDataFromServer';
import { DefaultOptionType } from 'antd/es/cascader';
import { useTheme } from 'contexts';
import { parseGoipFullName } from 'modules/owners/util/parseGoipFullName.util';

export function goipSearchfilter(inputValue: string, path: DefaultOptionType[]) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return path.some((option) => option.label?.toLowerCase?.().indexOf(inputValue.toLowerCase()) > -1);
}

interface Props {
  isShown?: boolean;
  hasLabel?: boolean;
  goip?: string;
  onChange?: (goip: string) => void;
  onConfirm?: (value: boolean) => void;
}

export const GoipCascader: React.FC<Props> = ({ isShown = false, onChange, goip, hasLabel = true }) => {
  const [goipsData, state, fetchData] = useAvailableGoipsFromServer(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { isDarkMode } = useTheme();

  let goipCascaderDefaultValue: undefined | [string, number];

  if (goip) {
    goipCascaderDefaultValue = parseGoipFullName(goip);
  }

  const isLoading = useMemo(() => state === UseDataFromServerHookStatesEnum.LOADING, [state]);

  const GoipCascaderSelectOptions = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return goipsData
      .sort((a, b) => parseInt(a.name) - parseInt(b.name))
      .map((goip) => ({
        label: goip.name,
        value: goip.name,
        children: goip.channels.map((channel) => ({
          value: channel,
          label: channel,
        })),
      }));
  }, [goipsData, isLoading]);

  async function fetchOnOpenModal(isModalOpen: boolean): Promise<void> {
    if (!isModalOpen) {
      setIsOpen(false);

      return;
    }

    await fetchData();
    setIsOpen(true);
  }

  async function onGoipsChange(newValue: (string | number)[]) {
    if (!onChange) return;

    onChange(newValue?.join(''));
  }

  const CascaderWithLabel = (
    <div className="flex items-center">
      <div
        className={`px-2 py-1 border border-solid rounded-tl-md rounded-bl-md ${
          isDarkMode ? 'border-gray-500 bg-opacity-0 text-white text-opacity-85' : 'border-hmGray bg-grayTrue-50'
        }`}
      >
        Имя шлюза
      </div>
      <Spin spinning={isLoading}>
        <Cascader
          loading={isLoading}
          showCheckedStrategy={Cascader.SHOW_CHILD}
          defaultValue={goipCascaderDefaultValue}
          options={GoipCascaderSelectOptions}
          showSearch={{ filter: goipSearchfilter }}
          onChange={onGoipsChange}
          onDropdownVisibleChange={fetchOnOpenModal}
        />
      </Spin>
    </div>
  );

  if (!isShown) return null;

  return (
    <>
      {hasLabel ? (
        CascaderWithLabel
      ) : (
        <Spin spinning={isLoading}>
          <Cascader
            open={isOpen}
            showCheckedStrategy={Cascader.SHOW_CHILD}
            defaultValue={goipCascaderDefaultValue}
            loading={isLoading}
            options={GoipCascaderSelectOptions}
            showSearch={{ filter: goipSearchfilter }}
            onChange={onGoipsChange}
            onDropdownVisibleChange={fetchOnOpenModal}
          />
        </Spin>
      )}
    </>
  );
};
