import React from 'react';
import { App, Button, Drawer, Form, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { IOwner } from 'modules/owners/types/IOwner';
import { EditOwnerBalanceOperationTypeEnum, IEditOwnerBalanceParams } from 'modules/owners/store/owners/types';
import { editOwnerActualBalance } from 'modules/owners/store/owners/actions';
import { InputNumber } from 'components/Input';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';

interface Props {
  owner?: IOwner;
  onClose: () => void;
}

export const OwnerPageEditOwnerBalanceDrawer: React.FC<Props> = ({ owner, onClose }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();

  const { message } = App.useApp();

  async function handleSubmitPress() {
    const params: IEditOwnerBalanceParams = form.getFieldsValue();

    params.ownerId = ownerId;

    try {
      await dispatch(editOwnerActualBalance(params));

      message.success('Баланс обновлен.');
    } catch {
      message.error('Ошибка обновления баланса владельца.');
    }

    onCloseModal();
  }

  function onCloseModal(): void {
    form.resetFields();
    onClose();
  }

  if (!owner) return null;

  const { ownerId, fio } = owner;

  return (
    <Drawer title={`№${ownerId} ${fio}`} open={!!owner} onClose={() => onCloseModal()}>
      <Form form={form} onFinish={handleSubmitPress}>
        <Form.Item
          initialValue={undefined}
          label="Тип операции"
          name="operationType"
          rules={[
            {
              required: true,
              message: 'Обязательный параметр',
            },
          ]}
        >
          <Select>
            <Select.Option value={EditOwnerBalanceOperationTypeEnum.ADDITION}>Начисление</Select.Option>
            <Select.Option value={EditOwnerBalanceOperationTypeEnum.SUBTRACTION}>Списание</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          initialValue={undefined}
          label="Сумма операции"
          className="w-full"
          name="amount"
          rules={[
            {
              required: true,
              message: 'Сумма обязательна',
            },
            {
              validator: (_, value) => {
                if (value === 0 || value < 0) {
                  return Promise.reject(new Error('Только больше 0'));
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber className="w-full" controls={false} />
        </Form.Item>
        <Button htmlType="submit" className="mt-2 w-full" type="primary">
          Скорректировать баланс
        </Button>
      </Form>
    </Drawer>
  );
};
