import instance from 'utils/axios';
import { MerchantCommission } from '../types/merchantCommission.interface';

export async function fetchMerchantCommissionsRequest(merchantId: number): Promise<MerchantCommission[]> {
  const {
    data: { data },
  } = await instance.get('/merchants/commissions', { params: { merchantId } });

  return data;
}
