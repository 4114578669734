import React from 'react';
import { IMetaPagination } from 'types/IMetaPagination';
import { Table } from 'antd';
import { LazyTableColumnsType } from 'components/LazyTable/types/lazyTableColumns.type';
import { Pagination } from 'components/Pagination';
import { LazyTableListView } from 'components/LazyTable/components/ListView';
import { ScrollToTopButton } from 'components/ScrollToTopButton';

export interface LazyTableProps<RecordType = any> {
  columns: LazyTableColumnsType<RecordType>;
  isTableView: boolean;
  onLoadMore: () => Promise<void>;
  onPaginationChange: (page: number, pageSize?: number) => void;
  isLoading: boolean;
  pagination: IMetaPagination;
  data: RecordType[];
  rowExpandable?: (record: RecordType) => boolean;
  expandedRowRender?: (record: RecordType) => React.ReactNode;
  rowClassName?: (record: RecordType) => string;
  rowKey?: (record: RecordType, index?: number) => string | number;
}

export const LazyTable: React.FC<LazyTableProps> = ({
  columns,
  isLoading,
  isTableView,
  onLoadMore,
  onPaginationChange,
  pagination,
  data,
  rowExpandable,
  expandedRowRender,
  rowClassName,
  rowKey,
}) => {
  if (isTableView) {
    return (
      <>
        <Table
          className="big-table"
          dataSource={data}
          columns={columns}
          loading={isLoading}
          size="small"
          pagination={false}
          rowClassName={rowClassName}
          rowKey={rowKey}
          expandable={
            rowExpandable && expandedRowRender
              ? {
                  rowExpandable,
                  expandedRowRender,
                }
              : undefined
          }
        />
        <Pagination
          customStyle="pt-2"
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={onPaginationChange}
        />
      </>
    );
  }

  return (
    <>
      <LazyTableListView
        onLoadMore={onLoadMore}
        isLoading={isLoading}
        data={data}
        columns={columns}
        total={pagination.total}
        rowExpandable={rowExpandable}
        expandedRowRender={expandedRowRender}
        rowKey={rowKey}
      />
      <ScrollToTopButton />
    </>
  );
};
