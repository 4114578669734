import React from 'react';
import { Space, Drawer, Button, message, Input } from 'antd';

import { OwnerCreateTransactionParams } from 'modules/owners/types/OwnerCreateTransactionParams';

import { useBanksFromServer } from 'hooks/useDataFromServer';
import { InputNumber } from 'components/Input';
import { LazySelect } from 'components/LazySelect';

interface Props {
  isShown: boolean;
  params: OwnerCreateTransactionParams;
  onChange: (newValue: OwnerCreateTransactionParams) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

export const OwnersBalancePageCreateTransactionModal: React.FC<Props> = ({
  isShown,
  params,
  onCancel,
  onSubmit,
  onChange,
}) => {
  const { ownerId, amount, providerId, comment } = params;

  function handleSubmitPress() {
    if (!validate()) return;

    onSubmit();
  }

  function validate() {
    if (Object.values(params).some((i) => !i)) {
      message.error('Заполнены не все поля!');

      return false;
    }

    return true;
  }

  const onValueChange = (key: keyof OwnerCreateTransactionParams, value: null | number | string) => {
    if (value) {
      onChange({ ...params, [key]: value });
    } else {
      delete params[key];
      onChange({ ...params });
    }
  };

  return (
    <Drawer title="Создать транзакцию" open={isShown} onClose={onCancel}>
      <Space direction="vertical" size="large">
        <InputNumber
          placeholder="ID владельца"
          addonBefore="ID владельца"
          value={ownerId}
          className="w-full"
          onChange={(value) => onValueChange('ownerId', value as number)}
        />
        <InputNumber
          placeholder="Сумма операции"
          addonBefore="Сумма операции"
          isAmountInput
          value={amount}
          className="w-full"
          onChange={(value) => onValueChange('amount', value as number)}
        />
        <LazySelect
          allowClear
          placeholder="Провайдер"
          value={providerId}
          onChange={(value: number) => onValueChange('providerId', value)}
          useDataHook={useBanksFromServer}
          cacheKey="provider-filter"
        />
        <Input
          addonBefore={'Комментарий'}
          placeholder={'Комментарий'}
          name="comment"
          type="text"
          value={comment}
          onChange={(e) => onValueChange('comment', e.target.value)}
        />
        <Button onClick={handleSubmitPress} className="w-full" type="primary">
          Создать
        </Button>
      </Space>
    </Drawer>
  );
};
