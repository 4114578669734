import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'Название клиента',
    name: 'name',
    type: 'input',
    allowClear: true,
    width: 300,
  },
  {
    title: 'Алиас клиента',
    name: 'aliasOrder',
    type: 'input',
    allowClear: true,
    width: 300,
  },
];
