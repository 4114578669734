import React from 'react';
import { Image } from 'antd';

import { IOrderDispute } from 'modules/disputes/types/IOrderDispute';

import { setBuffering } from 'modules/disputes/store/orderDisputes/actions';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';

interface Props {
  dispute: IOrderDispute;
}

export const OrderDisputesPageTableReceiptsColumn: React.FC<Props> = ({ dispute }) => {
  const dispatch = useAppDispatch();

  function handleImageClick(): void {
    dispatch(setBuffering(true));
  }

  let receiptsUrls: string[] = [];
  if (dispute.receipts.length) {
    receiptsUrls = dispute.receipts.map((receipt) => receipt.url);
  } else {
    receiptsUrls = [dispute.firstPaymentReceiptUrl, dispute.secondPaymentReceiptUrl].filter(Boolean);
  }

  return (
    <div className="flex flex-row items-center justify-between h-12 overflow-hidden">
      <Image.PreviewGroup>
        {receiptsUrls.map((url: string) => {
          return (
            <Image
              src={url}
              key={url}
              className="h-12 ml-1 mr-2 object-contain w-auto self-start"
              onClick={handleImageClick}
            />
          );
        })}
      </Image.PreviewGroup>
    </div>
  );
};
