import React, { FC, useEffect, useState } from 'react';
import { Filters } from 'components/Filters';
import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';
import { filtersSchema } from './filters';
import { Pagination } from 'components/Pagination';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { FetchBankAccountsParams } from 'modules/bankAccounts/store/types/fetchBankAccountsParams.interface';
import { App, Button, Drawer, Table } from 'antd';
import { fetchBankAccounts } from 'modules/bankAccounts/store';
import { useBankAccountsColumns } from 'modules/bankAccounts/pages/list/hooks/useBankAccountsColumns.hook';
import { BankAccountCardsList, CreateBankAccountForm, UpdateBankAccountForm } from 'modules/bankAccounts/components';
import { permissions } from 'policies/permissions';
import { PlusOutlined } from '@ant-design/icons';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';

export const BankAccountsListPage: FC = () => {
  const { memoFilters, setMemoFilters } = useMemoFilters<FetchBankAccountsParams>();

  const [bankAccountToUpdateId, setBankAccountToUpdateId] = useState<number>();
  const [isCreateDrawerShown, setIsCreateDrawerShown] = useState<boolean>(false);

  const { message } = App.useApp();

  const {
    accounts,
    isLoading,
    pagination: { page, perPage },
    pagination,
  } = useAppSelector((state) => state.bankAccountsModule);

  const dispatch = useAppDispatch();

  const columns = useBankAccountsColumns({
    onEditClick: (bankAccount) => setBankAccountToUpdateId(bankAccount.id),
    onDeleteSuccess: () => fetch(memoFilters),
  });

  useEffect(() => {
    fetch(memoFilters);
  }, []);

  function handleFiltersChange(filters: FetchBankAccountsParams) {
    setMemoFilters(filters);
    fetch(filters);
  }

  function handlePaginationChange(page: number, perPage: number): void {
    fetch(memoFilters, page, perPage);
  }

  async function fetch(filters: FetchBankAccountsParams, requestPage = page, requestPerPage = perPage) {
    try {
      await dispatch(fetchBankAccounts({ ...filters, page: requestPage, perPage: requestPerPage })).unwrap();
    } catch {
      message.error('Не удалось получить список счетов');
    }
  }

  return (
    <>
      <Drawer
        open={!!bankAccountToUpdateId}
        title="Редактирование счёта"
        onClose={() => setBankAccountToUpdateId(undefined)}
      >
        {bankAccountToUpdateId && (
          <UpdateBankAccountForm
            id={bankAccountToUpdateId}
            onSuccess={() => {
              setBankAccountToUpdateId(undefined);
              fetch(memoFilters);
            }}
          />
        )}
      </Drawer>
      <Drawer open={isCreateDrawerShown} title="Создание счета" onClose={() => setIsCreateDrawerShown(false)}>
        {isCreateDrawerShown && (
          <CreateBankAccountForm
            onSuccess={() => {
              setIsCreateDrawerShown(false);
              fetch(memoFilters);
            }}
          />
        )}
      </Drawer>
      <Filters
        headerTitle="Счета и карты"
        filters={filtersSchema}
        value={memoFilters}
        onSubmit={handleFiltersChange}
        extraHeaderButtons={() => (
          <ProtectedComponent requiredPermissions={[permissions.bankAccount.create]} fallbackComponent={<div />}>
            <Button icon={<PlusOutlined />} shape="round" type="primary" onClick={() => setIsCreateDrawerShown(true)}>
              Создать
            </Button>
          </ProtectedComponent>
        )}
      />
      <Table
        columns={columns}
        dataSource={accounts}
        loading={isLoading}
        pagination={false}
        rowKey={(record) => record.id}
        size="middle"
        expandable={{
          expandedRowRender: ({ id }) => {
            return <BankAccountCardsList bankAccountId={id} />;
          },
        }}
      />
      <Pagination pagination={pagination} isLoading={isLoading} onPaginationChange={handlePaginationChange} />
    </>
  );
};
