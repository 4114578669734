import React, { useMemo } from 'react';
import { ColumnType } from 'antd/lib/table';

interface Props<RecordType = any> {
  column: ColumnType<RecordType>;
  row: RecordType;
  index: number;
  width: number | string;
  breakWord?: boolean;
}

export const LazyTableListViewRowColumn: React.FC<Props> = ({ column, row, index, width, breakWord = true }) => {
  const data = useMemo(() => {
    if (!Array.isArray(column.dataIndex)) {
      return row[(column.dataIndex ?? column.key) as string];
    }

    return column.dataIndex.reduce((acc, key) => acc[key], row);
  }, [row, column]);

  return (
    <div
      className="flex items-center justify-center flex-wrap text-center whitespace-normal"
      key={index}
      style={{
        minWidth: typeof width === 'number' ? width + 'px' : width,
        maxWidth: typeof width === 'number' ? width + 'px' : width,
        overflowWrap: breakWord ? 'break-word' : undefined,
        wordBreak: breakWord ? 'break-all' : undefined,
      }}
    >
      {column.render ? column.render(data, row, index) : data}
    </div>
  );
};
