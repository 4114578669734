import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';

export const partnersFilters: IFilterSchema[] = [
  {
    type: 'input',
    title: 'Имя',
    name: 'name',
    width: 300,
  },
  {
    type: 'select',
    title: 'Статус',
    name: 'enabled',
    allowClear: true,
    options: [
      { label: 'Включен', value: true },
      {
        label: 'Выключен',
        value: false,
      },
    ],
  },
];
