import useSWRMutation from 'swr/mutation';
import { updateUserRequest, UpdateUserRequestParams } from 'modules/users/api/updateUser.request';

export function useUpdateUserRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'update-user-request',
    async (
      _,
      {
        arg,
      }: {
        arg: UpdateUserRequestParams;
      }
    ) => {
      return await updateUserRequest(arg);
    },
    {
      throwOnError: true,
    }
  );

  return { updateUser: trigger, isUpdatingUser: isMutating };
}
