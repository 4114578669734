import {
  OwnerTransactionsState,
  OwnersTransactionsReducersActions,
  OwnersTransactionsActionsTypes,
  IFetchOwnersTransactionsReducerActionPayload,
  IChangePaginationOwnersTransactionsReducerActionPayload,
} from 'modules/owners/store/ownersTransactions/types';

const initialState: OwnerTransactionsState = {
  transactions: [],
  isLoading: false,
  pagination: {
    page: 1,
    perPage: 20,
    pages: 0,
    total: 0,
  },
};

export function ownersTransactionsReducer(state = initialState, { type, payload }: OwnersTransactionsReducersActions) {
  switch (type) {
    case OwnersTransactionsActionsTypes.FETCH_OWNERS_TRANSACTIONS: {
      const { transactions, pagination } = payload as IFetchOwnersTransactionsReducerActionPayload;

      return { ...state, transactions, pagination, isLoading: false };
    }
    case OwnersTransactionsActionsTypes.CREATE_OWNER_TRANSACTION:
      return {
        ...state,
        transactions: [...[payload], ...state.transactions],
        isLoading: false,
      };
    case OwnersTransactionsActionsTypes.SET_LOADING:
      return { ...state, isLoading: payload };
    case OwnersTransactionsActionsTypes.CHANGE_PAGINATION: {
      const { page, perPage } = payload as IChangePaginationOwnersTransactionsReducerActionPayload;

      return { ...state, pagination: { ...state.pagination, page, perPage } };
    }
    default:
      return state;
  }
}
