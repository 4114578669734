import useSWR from 'swr';
import { fetchAgentRequest } from 'modules/agents/api/fetchAgent.request';

export function useFetchAgentRequest(id: number) {
  const { isLoading, mutate, data } = useSWR([id, 'fetch-agent-api'], async ([id]) => {
    return await fetchAgentRequest(id);
  });

  return { isFetchingAgent: isLoading, fetchAgent: mutate, agent: data };
}
