import React from 'react';

interface Props {
  cardNumber?: string;
  bankAccountNumber?: string;
  bic?: string;
  phone?: string;
}

export const OrderOwnerDescriptionRequisites: React.FC<Props> = ({ cardNumber, bankAccountNumber, bic, phone }) => {
  return (
    <>
      {cardNumber && <li>Карта владельца: {cardNumber}</li>}
      {bankAccountNumber && (
        <>
          <li>Счет: {bankAccountNumber}</li>
          <li>БИК: {bic ?? 'Нет данных'}</li>
        </>
      )}
      {phone && <li>Номер телефона СБП: {phone}</li>}
      {!cardNumber && !bankAccountNumber && !bic && !phone && (
        <>
          <li>Реквизиты: нет данных</li>
        </>
      )}
    </>
  );
};
