import React from 'react';

import { App } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { useFieldsSchema } from 'modules/bankAccounts/components/CreateBankAccountForm/fields';
import { UnifiedForm } from 'components/UnifiedForm';
import { useCreateBankAccountRequest } from 'modules/bankAccounts/hooks';
import { CreateBankAccountRequestParams } from 'modules/bankAccounts/api/createBankAccount.request';

interface Props {
  ownerId?: number;
  onSuccess: () => void;
}

export const CreateBankAccountForm: React.FC<Props> = ({ onSuccess, ownerId }) => {
  const [form] = useForm<CreateBankAccountRequestParams>();

  const { isCreatingBankAccount, createBankAccount } = useCreateBankAccountRequest();

  const { message } = App.useApp();

  const fieldsSchema = useFieldsSchema({ preselectedOwnerId: ownerId });

  async function handleSubmitPress() {
    try {
      let values = form.getFieldsValue();

      if (ownerId) {
        values = { ...values, ownerId };
      }

      await createBankAccount(values);

      message.success('Счет владельца успешно создан');
      onSuccess();
    } catch (error) {
      message.error('Не удалось создать счет владельца.');
    }
  }

  return (
    <UnifiedForm
      form={form}
      className="flex flex-col h-full"
      fields={fieldsSchema}
      onSubmitClick={handleSubmitPress}
      submitButtonTitle="Добавить счёт"
      isSubmitButtonAtBottom
      isLoading={isCreatingBankAccount}
    />
  );
};
