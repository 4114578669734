import React from 'react';
import { Form, Table, Switch, FormInstance } from 'antd';

import { OwnerToCustomerBindings, IRow } from '../../types';

import { IOwner } from 'modules/owners/types/IOwner';

interface Props {
  /** Инстанс формы */
  form: FormInstance<OwnerToCustomerBindings>;
  /** Идет ли сейчас загрузка */
  isLoading: boolean;
  /** Колбэк при переключении состояния связки */
  onSubmit: (customerId: number, createBind: boolean) => void;
  /** Список агентов */
  agents: IOwner[];
  /** Колбэк при изменении значений формы */
  onChange: () => void;
  /** Колонки таблицы формы */
  rows: IRow[];
}

/**
 * Компонент формы привязки владельца к клиентам и ввода
 * комиссий владельца и агентов.
 *
 * @author Собянин Виктор <telegram: @victorsobyanin>
 */
export const OwnersPageAssignToCustomerDrawerForm: React.FC<Props> = ({
  form,
  isLoading,
  onSubmit,
  onChange,
  rows,
}) => {
  return (
    <Form form={form} component={false} onFieldsChange={() => onChange()}>
      <Table
        dataSource={rows}
        loading={isLoading}
        columns={[
          {
            dataIndex: ['customer', 'name'],
            key: 'name',
            title: 'Клиент',
          },
          {
            key: 'assignAction',
            title: 'Привязка',
            render: (_, { customer: { id }, commissionsExceeded }: IRow) => (
              <Form.Item name={[id, 'isActive']} valuePropName="checked" noStyle>
                <Switch onChange={(checked) => onSubmit(id, checked)} disabled={commissionsExceeded} />
              </Form.Item>
            ),
          },
        ]}
        pagination={false}
      />
    </Form>
  );
};
