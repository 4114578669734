import React, { useMemo, useCallback } from 'react';
import { Switch } from 'antd';

import { updateOwnerField } from 'modules/owners/store/owners/actions';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { permissions } from 'policies/permissions';
import { IOwner } from 'modules/owners/types/IOwner';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';

interface Props {
  owner: IOwner;
  forOutcomming?: boolean;
}

export const OwnersPageTableTransactionsIsAllowedColumn: React.FC<Props> = ({ owner, forOutcomming = false }) => {
  const dispatch = useAppDispatch();

  const { userCan } = useUserPermissions();

  const value = useMemo<boolean>(
    () => (forOutcomming ? owner.outcommingTransactionsIsAllowed : owner.incommingTransactionsIsAllowed),
    [owner, forOutcomming]
  );

  const handleChange = useCallback(
    (isChecked: boolean) => {
      const fieldName = forOutcomming ? 'outcommingTransactionsIsAllowed' : 'incommingTransactionsIsAllowed';

      dispatch(updateOwnerField(owner.ownerId as number, fieldName, isChecked));
    },
    [owner, forOutcomming]
  );

  return (
    <div className="flex justify-center items-center">
      <Switch checked={value} onChange={handleChange} disabled={!userCan(permissions.owner.update)} />
    </div>
  );
};
