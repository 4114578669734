import { Dispatch } from 'redux';

import instance from 'utils/axios';
import {
  GoipsActionTypes,
  IGetGoipsReducerAction,
  ISetIsLoadingGoipsReducerAction,
} from 'modules/goips/store/goips/types';

export function getGoips() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setIsLoading(true));

      const {
        data: { data },
      } = await instance.get('/goip');

      dispatch<IGetGoipsReducerAction>({
        type: GoipsActionTypes.GET_GOIPS,
        payload: { goips: data },
      });
    } finally {
      dispatch<any>(setIsLoading(false));
    }
  };
}

export function setIsLoading(payload: boolean) {
  return (dispatch: Dispatch) => {
    dispatch<ISetIsLoadingGoipsReducerAction>({
      type: GoipsActionTypes.SET_IS_LOADING,
      payload,
    });
  };
}
