import { User } from 'modules/users/types/user.interface';
import api from 'utils/axios';
import { BackendResponse } from 'types/BackendResponse';

export interface FetchUsersParams {
  id?: number | null;
  name?: string | null;
  agentId?: number | null;
  merchantId?: number | null;
}

export async function fetchUsersRequest(params: FetchUsersParams): Promise<User[]> {
  const {
    data: { data },
  } = await api.get<BackendResponse<User[]>>('/users/list', { params });

  return data;
}
