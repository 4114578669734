import React from 'react';
import { Image, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { Bank } from 'modules/banks/types/bank.interface';
import { CreateBankFormTableFontColorWrapper } from 'modules/banks/components/CreateBankForm/components/Table/components/FontColorWrapper/CreateBankFormTableFontColorWrapper';
import { useTheme } from 'contexts';
import { BankEnabledSwitch } from 'modules/banks/components/BankEnabledSwitch/BankEnabledSwitch';

interface Props {
  bank: Bank;
}

export const CreateBankFormTable: React.FC<Props> = ({ bank }) => {
  const {
    currentTheme: {
      token: { colorBgTableHeader },
    },
  } = useTheme();

  const tableBorder: string = 'border border-gray-200';
  const tableRowTitle: string = 'bg-gray-100 pr-2 pb-1 pl-2 pt-1';
  const tableRowValue: string = 'pr-2 pb-1 pl-2 pt-1 flex justify-center flex-col items-center';
  const commonImageProps = { width: 60, preview: false, alt: bank.name };

  return (
    <table className={`${tableBorder} mb-5`}>
      <tbody>
        <tr>
          <td className={`w-1/2 ${tableRowTitle}`} style={{ backgroundColor: colorBgTableHeader }}>
            Id банка:
          </td>
          <td className={tableRowValue}>{bank.id}</td>
        </tr>
        <tr className={tableBorder}>
          <td className={tableRowTitle} style={{ backgroundColor: colorBgTableHeader }}>
            Название банка:
          </td>
          <td className={tableRowValue}>{bank.name}</td>
        </tr>
        <tr className={tableBorder}>
          <td className={tableRowTitle} style={{ backgroundColor: colorBgTableHeader }}>
            Название банка (англ):{' '}
          </td>
          <td className={tableRowValue}>{bank.nameEn}</td>
        </tr>
        <tr className={tableBorder}>
          <td className={tableRowTitle} style={{ backgroundColor: colorBgTableHeader }}>
            Код страны:{' '}
          </td>
          <td className={tableRowValue}>{bank.countryCode}</td>
        </tr>
        <tr className={tableBorder}>
          <td className={tableRowTitle} style={{ backgroundColor: colorBgTableHeader }}>
            Код валюты:{' '}
          </td>
          <td className={tableRowValue}>{bank.currencyCode}</td>
        </tr>
        <tr className={tableBorder}>
          <td className={tableRowTitle} style={{ backgroundColor: colorBgTableHeader }}>
            Внутренний / внешний банк:{' '}
          </td>
          <td className="text-center">
            {bank.internal ? (
              <Tooltip title="Внутренний банк">
                <CheckOutlined className="text-green-700" />
              </Tooltip>
            ) : (
              <Tooltip title="Внешний банк">
                <CloseOutlined className="text-red-700" />
              </Tooltip>
            )}
          </td>
        </tr>
        <tr className={tableBorder}>
          <td className={tableRowTitle} style={{ backgroundColor: colorBgTableHeader }}>
            Включен / выключен:{' '}
          </td>
          <td className={tableRowValue}>
            <BankEnabledSwitch bank={bank} />
          </td>
        </tr>
        <tr className={tableBorder}>
          <td className={tableRowTitle} style={{ backgroundColor: colorBgTableHeader }}>
            Главная картинка:{' '}
          </td>
          <td className={tableRowValue}>
            <Image src={bank.imageUrl} {...commonImageProps} />
          </td>
        </tr>
        <tr className={tableBorder}>
          <td className={tableRowTitle} style={{ backgroundColor: colorBgTableHeader }}>
            Большой логотип:{' '}
          </td>
          <td className={tableRowValue}>
            <Image src={bank.logoBigSizeUrl} {...commonImageProps} />
          </td>
        </tr>
        <tr className={tableBorder}>
          <td className={tableRowTitle} style={{ backgroundColor: colorBgTableHeader }}>
            Малый логотип:{' '}
          </td>
          <td className={tableRowValue}>
            <Image src={bank.logoSmallSizeUrl} {...commonImageProps} />
          </td>
        </tr>
        <tr className={tableBorder}>
          <td className={tableRowTitle} style={{ backgroundColor: colorBgTableHeader }}>
            Инвертированный большой логотип:{' '}
          </td>
          <td className={tableRowValue}>
            <Image src={bank.logoInvertBigSizeUrl} {...commonImageProps} />
          </td>
        </tr>
        <tr className={tableBorder}>
          <td className={tableRowTitle} style={{ backgroundColor: colorBgTableHeader }}>
            Инвертированный малый логотип:
          </td>
          <td className={tableRowValue}>
            <Image src={bank.logoInvertSmallSizeUrl} {...commonImageProps} />
          </td>
        </tr>
        <tr className={tableBorder}>
          <td className={tableRowTitle} style={{ backgroundColor: colorBgTableHeader }}>
            Цвета фона:
          </td>
          <td className={tableRowValue}>
            {bank.backgroundColors?.map((color: string, index: number) =>
              color ? <CreateBankFormTableFontColorWrapper key={index} backgroundColor={color} /> : null
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
