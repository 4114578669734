import React from 'react';
import { Button, Tooltip } from 'antd';
import { SwapOutlined, SwapRightOutlined, UserOutlined } from '@ant-design/icons';
import { useBindOperator } from 'modules/orders/pages/active/components/RowActions/components/BindOperatorToOrder/hooks/useBindOperator';

interface Props {
  linkedOperatorId?: number;
  orderId: number;
}

export const BindOperatorToOrder: React.FC<Props> = ({ linkedOperatorId, orderId }) => {
  const [bindOperatorCheck] = useBindOperator();

  const styles = {
    notLinked:
      'text-white bg-green-500 border-green-500 hover:text-white hover:bg-green-400 hover:border-green-400 active:bg-green-500 active:border-green-500 focus:border-green-500 focus:bg-green-500',
    linkedToAnother:
      'text-white bg-yellow-500 border-yellow-500 hover:text-white hover:bg-yellow-400 hover:border-yellow-400 active:border-yellow-500 active:bg-yellow-500 focus:border-yellow-500 focus:bg-yellow-500',
  };
  const isNotLinked = null === linkedOperatorId;
  const colorStyle = isNotLinked ? styles.notLinked : styles.linkedToAnother;

  return (
    <Tooltip title={isNotLinked ? 'Привязать оператора' : 'Перепривязать оператора'} className="mr-1">
      <Button
        type="default"
        className={colorStyle}
        style={{ color: 'white', border: 'none' }}
        shape="round"
        icon={
          <div className="flex flex-row justify-between">
            {isNotLinked ? (
              <>
                <SwapRightOutlined className="mr-2" />
                <UserOutlined />
              </>
            ) : (
              <>
                <UserOutlined />
                <SwapOutlined className="ml-2 mr-2" />
                <UserOutlined />
              </>
            )}
          </div>
        }
        onClick={async () => await bindOperatorCheck(orderId)}
      />
    </Tooltip>
  );
};
