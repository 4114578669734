import React, { useEffect, useMemo } from 'react';
import { App, Button, Drawer, Form, Spin, Input as AntInput } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { PartnersPageEnabledSwitch } from '../EnabledSwitch';
import { Partner } from 'modules/partners/types/partner.interface';
import { UpdatePartnerParams } from 'modules/partners/store/types/updatePartnerParams.interface';
import { updatePartner } from 'modules/partners/store';
import { Input } from 'components/Input';

interface Props {
  partner?: Partner;
  onClose: () => void;
}

export const PartnersPageUpdateDrawer: React.FC<Props> = ({ partner, onClose }) => {
  const [form] = useForm<UpdatePartnerParams>();

  const { message } = App.useApp();

  const dispatch = useAppDispatch();
  const { isLoading, partners } = useAppSelector((state) => state.partners);

  const partnerFromState = useMemo(() => {
    return partners.find(({ id }) => id === partner?.id)!;
  }, [partner, partners]);

  useEffect(() => {
    if (!partner) {
      return;
    }

    form.setFieldsValue(partner);
  }, [partner]);

  async function handleSubmit() {
    const params = form.getFieldsValue();

    try {
      await dispatch(updatePartner(params)).unwrap();

      message.success('Партнер успешно обновлен');
      onClose();
    } catch {
      message.error('Не удалось обновить партнера');
    }
  }

  function handleClose() {
    form.resetFields();
    onClose();
  }

  return (
    <Drawer open={!!partner} onClose={handleClose} title={`Редактировать партнера №${partner?.id}`}>
      <Spin spinning={isLoading} tip="Идет обновление...">
        <Form form={form}>
          <Form.Item hidden name="id">
            <AntInput type="hidden" />
          </Form.Item>
          <Form.Item name="name" label="Имя">
            <Input label="Имя" />
          </Form.Item>
        </Form>
        <Form.Item label="Включен">
          <PartnersPageEnabledSwitch partner={partnerFromState} />
        </Form.Item>
        <Button type="primary" onClick={handleSubmit}>
          Сохранить
        </Button>
      </Spin>
    </Drawer>
  );
};
