import { IChangePagePayload, MerchantsBalancesReducerActions, MerchantsBalancesActionsTypes } from './types';

import { MerchantsBalancesState } from './interfaces/merchantsBalancesState.interface';
import { FetchMerchantsBalancesPayload } from './interfaces/fetchMerchantsBalancesPayload.interface';

const initialState: MerchantsBalancesState = {
  merchantsBalances: [],
  isLoading: false,
  pagination: {
    page: 1,
    pages: 1,
    perPage: 20,
    total: 0,
  },
};
export function merchantsBalancesReducer(
  state = initialState,
  { type, payload }: MerchantsBalancesReducerActions
): MerchantsBalancesState {
  switch (type) {
    case MerchantsBalancesActionsTypes.FETCH_MERCHANTS_BALANCES: {
      const { pagination, merchantsBalances } = payload as FetchMerchantsBalancesPayload;

      return { ...state, merchantsBalances, isLoading: false, pagination };
    }
    case MerchantsBalancesActionsTypes.SET_LOADING:
      return { ...state, isLoading: payload as boolean };
    case MerchantsBalancesActionsTypes.CHANGE_PAGE: {
      const { page, perPage } = payload as IChangePagePayload;

      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: page,
          perPage,
        },
      };
    }
    default:
      return state;
  }
}
