import React, { useMemo } from 'react';
import { Button, Space, Table } from 'antd';
import { EditOutlined, UndoOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { useUserPermissions } from 'policies/hooks/useUserPermissions';

import { makeColumns } from './columns';
import { checkAllowedUserToEditOrder, finalStatusesAllowedToEdit } from './utils';
import { permissions } from 'policies/permissions';
import { useBindOperator } from 'modules/orders/pages/active/components/RowActions/components/BindOperatorToOrder/hooks/useBindOperator';
import { OrderTypes } from 'modules/orders/enums/orderTypes.enum';
import { Order } from 'modules/orders/types/order.interface';
import { OrderStatusEnum } from 'modules/orders/enums/orderStatus.enum';
import { Tooltip } from 'components/Tooltip';
import { OrderOwnerDescription } from 'modules/orders/components/OrderOwnerDescription';

interface Props {
  isLoading: boolean;
  orders: Order[];
  onEditPress: (order: Order) => void;
  onResendCallback: (order: Order) => void;
}

export const OrdersPageTable: React.FC<Props> = ({ isLoading, orders, onEditPress, onResendCallback }) => {
  const { userCan } = useUserPermissions();
  const [bindOperatorCheck] = useBindOperator();

  const isIncomingOrderWithOneOwner = (order: Order) => order.type === OrderTypes.INCOMING && order.owners.length === 1;

  const columns = useMemo(
    () => [
      ...makeColumns(),
      {
        title: 'Действия',
        render: (_, order: Order) => {
          const canUpdateFinalOrder =
            (order.amount <= 2000 &&
              order.disputeIds &&
              order.disputeIds?.length > 0 &&
              (order.status.id === OrderStatusEnum.NOT_ENTERED || order.status.id === OrderStatusEnum.IN_PROGRESS) &&
              userCan(permissions.order.smallAmount.update)) ||
            userCan(permissions.order.final.update);

          return (
            <Space className="big-table-actions">
              {checkAllowedUserToEditOrder(order.status.id, canUpdateFinalOrder) &&
                (order.type === OrderTypes.OUTCOMING || isIncomingOrderWithOneOwner(order)) &&
                canUpdateFinalOrder && (
                  <Button
                    className={cn(
                      'block m-auto',
                      finalStatusesAllowedToEdit.includes(order.status.id) &&
                        canUpdateFinalOrder &&
                        'bg-red-600 border-red-600 hover:bg-red-700 hover:border-red-700'
                    )}
                    style={{ border: 'none' }}
                    type="primary"
                    onClick={async () => {
                      if (
                        (finalStatusesAllowedToEdit.includes(order.status.id) && canUpdateFinalOrder) ||
                        [OrderStatusEnum.NOT_ENTERED, OrderStatusEnum.NOT_EXECUTED].includes(order.status.id)
                      ) {
                        onEditPress(order);
                      } else {
                        await bindOperatorCheck(order.id, onEditPress, [order], true);
                      }
                    }}
                  >
                    <EditOutlined width={20} />
                  </Button>
                )}
              <Tooltip title="Переотправить callback изменения статуса клиенту">
                <Button type="primary" onClick={() => onResendCallback(order)}>
                  <UndoOutlined />
                </Button>
              </Tooltip>
            </Space>
          );
        },
      },
    ],
    [orders]
  );

  return (
    <div className="flex justify-center mt-0 max-w-full md:container overflow-x-auto">
      <Table
        className="big-table"
        bordered
        size="small"
        rowKey="id"
        pagination={false}
        locale={{
          emptyText: 'Нет данных. Примените фильтры.',
        }}
        loading={isLoading}
        dataSource={orders}
        columns={columns}
        expandable={{
          expandedRowRender: ({ owners, type, id }) =>
            owners.map((owner) => (
              <OrderOwnerDescription key={owner.ownerId} owner={owner} orderType={type} orderId={id} />
            )),
          rowExpandable: ({ owners }) => owners && owners.length > 0,
        }}
      />
    </div>
  );
};
