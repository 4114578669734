import Echo from 'laravel-echo';
import 'pusher-js';
import { PRESERVED_USER_TOKEN_KEY } from 'modules/auth/store/auth/reducers';

export const echo = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  wsHost: import.meta.env.VITE_WEBSOCKET_HOST,
  wssHost: import.meta.env.VITE_WEBSOCKET_HOST,
  wsPort: import.meta.env.VITE_WEBSOCKET_PORT,
  wssPort: import.meta.env.VITE_WEBSOCKET_PORT,
  encrypted: import.meta.env.VITE_WEBSOCKET_ENCRYPTED === 'true',
  disableStats: false,
  forceTLS: import.meta.env.VITE_WEBSOCKET_TLS === 'true',
  enabledTransports: ['ws', 'wss'],
  authEndpoint: `${import.meta.env.VITE_API_URL}/api/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem(PRESERVED_USER_TOKEN_KEY),
    },
  },
});
