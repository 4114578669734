import { CurrencyNameEnum } from 'enums/CurrencyEnum';

export interface BankAccount {
  ownerId: number;
  id: number;
  accountNumber: string;
  accountType: {
    id: BankAccountType;
    name: string;
  };
  amount: number;
  currencyCode: CurrencyNameEnum;
  bank: { id: number; name: string; image: string };
  bic?: string;
  status: string;
  inEnabled: boolean;
  outEnabled: boolean;
}

export enum BankAccountType {
  OPERATING = 'operating',
  PERSONAL = 'personal',
}
