import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';
import { useAgentsFromServer } from 'hooks/useDataFromServer';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'ID Владельца',
    name: 'ownerId',
    type: 'input-number',
  },
  {
    title: 'Агент',
    name: 'agentId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useAgentsFromServer,
    cacheKey: 'agent-filter',
    width: 200,
  },
  {
    title: 'UUID устройства',
    name: 'uuid',
    type: 'input',
  },
];
