import React, { useEffect } from 'react';

import { filtersSchema } from './filters';

import { Table } from 'antd';
import { Filters } from 'components/Filters';
import { Pagination } from 'components/Pagination';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';
import { changePagination, fetchMerchantsBalances } from 'modules/merchants/store/balances/actions';
import { FetchMerchantsBalancesParams } from 'modules/merchants/store/balances/interfaces/fetchMerchantsBalancesParams.interface';
import { useMerchantsBalancesColumns } from './hooks/useMerchantsBalancesColumns.hook';

export const MerchantsBalancesPage: React.FC = () => {
  const {
    merchantsBalances,
    pagination,
    pagination: { perPage, page },
    isLoading,
  } = useAppSelector(({ merchantsBalances }) => merchantsBalances);

  const dispatch = useAppDispatch();

  const { memoFilters, setMemoFilters } = useMemoFilters<FetchMerchantsBalancesParams>();

  const columns = useMerchantsBalancesColumns();

  useEffect(() => {
    dispatch(fetchMerchantsBalances({ ...memoFilters, page, perPage }));
  }, [memoFilters, page, perPage]);

  function handlePaginationChange(page: number, perPage: number) {
    dispatch(changePagination(page, perPage));
  }

  return (
    <>
      <Filters headerTitle="Балансы клиентов" filters={filtersSchema} value={memoFilters} onSubmit={setMemoFilters} />
      <Table
        size={'small'}
        scroll={{ x: true }}
        columns={columns}
        loading={isLoading}
        rowKey={(customer: { id: number }) => customer.id}
        dataSource={merchantsBalances}
        pagination={false}
        bordered
      />
      <Pagination pagination={pagination} isLoading={isLoading} onPaginationChange={handlePaginationChange} />
    </>
  );
};
