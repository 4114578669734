import React, { useEffect, useState } from 'react';
import { Drawer, Form, DatePicker, Space, Button, message } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { Dayjs } from 'dayjs';

import { InputNumber } from 'components/Input';
import { LazySelect } from 'components/LazySelect';

import { disableDatesAfterToday } from './utils';
import { downloadAndSaveFrom } from 'utils/export';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';

import { ownerIdFieldValidatorRules, fromFieldValidatorRules, toFieldValidatorRules } from './formValidationRules';
import { OwnersTransactionsFetchParams } from 'modules/owners/store/ownersTransactions/types';
import { useBanksFromServer } from 'hooks/useDataFromServer';

interface Props {
  isShown: boolean;
  onClose: () => void;
}

export const OwnersPageSmsHistoryExportDrawer: React.FC<Props> = ({ isShown, onClose }) => {
  const [form] = Form.useForm<OwnersTransactionsFetchParams>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    isShown && form.setFieldsValue({});
  }, [isShown]);

  async function handleSubmit() {
    const dateFormat = 'YYYY-MM-DD';
    const { from, to, ...restFields } = await form.validateFields();

    try {
      setIsLoading(true);

      const params = sanitizeRequestParams({
        ...restFields,
        from: (from as Dayjs).format(dateFormat),
        to: (to as Dayjs).format(dateFormat),
      });

      await downloadAndSaveFrom<OwnersTransactionsFetchParams>('/owners/export/sms', params);

      form.resetFields();

      onClose();
    } catch {
      message.error('Ошибка при скачивании');
      onClose();
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Drawer open={isShown} onClose={onClose} title="Экспорт СМС владельцев">
      <Form form={form} disabled={isLoading}>
        <Form.Item name="from" label="От" rules={fromFieldValidatorRules(form)}>
          <DatePicker locale={locale} className="w-full" disabledDate={disableDatesAfterToday} />
        </Form.Item>
        <Form.Item name="to" label="До" rules={toFieldValidatorRules(form)}>
          <DatePicker locale={locale} className="w-full" disabledDate={disableDatesAfterToday} />
        </Form.Item>
        <Form.Item name="ownerId" label="ID владельца" rules={ownerIdFieldValidatorRules}>
          <InputNumber className="w-full" min={0} />
        </Form.Item>
        <Form.Item name="bankId" label="ID банка">
          <LazySelect
            allowClear
            placeholder="ID Банка"
            style={{ minWidth: 120 }}
            useDataHook={useBanksFromServer}
            cacheKey="bank-select"
          />
        </Form.Item>
      </Form>
      <Space>
        <Button type="primary" onClick={handleSubmit} loading={isLoading} disabled={isLoading}>
          Скачать
        </Button>
        <Button onClick={onClose}>Отмена</Button>
      </Space>
    </Drawer>
  );
};
