import React from 'react';
import { Navigate } from 'react-router-dom';

import { ProtectedComponent } from '../ProtectedComponent';
import { NotAuthorizedPage } from 'modules/auth/pages/notAuthorized';

import { useUserPermissions } from 'policies/hooks/useUserPermissions';

interface Props {
  requiredPermissions: string[];
  pageComponent: React.ReactElement;
}

export const ProtectedPage: React.FC<Props> = ({ pageComponent, ...props }) => {
  const { isUserAuthenticated } = useUserPermissions();

  if (!isUserAuthenticated) return <Navigate to="/login" />;

  return (
    <ProtectedComponent {...props} fallbackComponent={<NotAuthorizedPage />}>
      {pageComponent}
    </ProtectedComponent>
  );
};
