import instance from 'utils/axios';
import { BankAccount } from 'modules/bankAccounts/types/bankAccount.interface';

export async function disableIncomingBankAccountRequest(id: number): Promise<BankAccount> {
  const {
    data: { data },
  } = await instance.post('/bank-accounts/disable-incoming', { id });

  return data;
}
