import { OwnerTransaction } from 'modules/owners/types/OwnerTransaction';
import { IMetaPagination } from 'types/IMetaPagination';
import { Dayjs } from 'dayjs';
import { ReducerAction } from 'types/reducerAction.interface';

export interface OwnerTransactionsState {
  transactions: OwnerTransaction[];
  isLoading: boolean;
  pagination: IMetaPagination;
}

export interface OwnersTransactionsFetchParams {
  ownerId?: number;
  sourceId?: number;
  providerId?: number;
  from?: string | Dayjs;
  to?: string | Dayjs;
  page?: number;
  perPage?: number;
}

export interface IFetchOwnersTransactionsReducerActionPayload {
  transactions: OwnerTransaction[];
  pagination: IMetaPagination;
}

export interface IChangePaginationOwnersTransactionsReducerActionPayload {
  page: number;
  perPage: number;
}

export type SetLoadingReducerAction = ReducerAction<boolean, OwnersTransactionsActionsTypes.SET_LOADING>;

export type FetchOwnersTransactionsReducerAction = ReducerAction<
  IFetchOwnersTransactionsReducerActionPayload,
  OwnersTransactionsActionsTypes.FETCH_OWNERS_TRANSACTIONS
>;

export type CreateOwnersTransactionsReducerAction = ReducerAction<
  OwnerTransaction,
  OwnersTransactionsActionsTypes.CREATE_OWNER_TRANSACTION
>;

export type IChangePaginationOwnersTransactionsReducerAction = ReducerAction<
  IChangePaginationOwnersTransactionsReducerActionPayload,
  OwnersTransactionsActionsTypes.CHANGE_PAGINATION
>;

export type OwnersTransactionsReducersActions =
  | SetLoadingReducerAction
  | FetchOwnersTransactionsReducerAction
  | CreateOwnersTransactionsReducerAction
  | IChangePaginationOwnersTransactionsReducerAction;

export enum OwnersTransactionsActionsTypes {
  FETCH_OWNERS_TRANSACTIONS = 'FETCH_OWNERS_TRANSACTIONS',
  CREATE_OWNER_TRANSACTION = 'CREATE_OWNER_TRANSACTION',
  SET_LOADING = 'SET_LOADING',
  CHANGE_PAGINATION = 'CHANGE_PAGINATION',
}
