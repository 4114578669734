import instance from 'utils/axios';
import { BankAccount } from 'modules/bankAccounts/types/bankAccount.interface';

export interface CreateBankAccountRequestParams {
  ownerId: number;
  bankId: number;
  type: string;
  accountNumber: string;
  bic: string;
  cards: string[];
  phone: string;
  limitMinIn: number;
}

export async function createBankAccountRequest(params: CreateBankAccountRequestParams): Promise<BankAccount> {
  const {
    data: { data },
  } = await instance.post('bank-accounts/create', params);

  return data;
}
