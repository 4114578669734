import { createAgentRequest, CreateAgentRequestParams } from 'modules/agents/api/createAgent.request';
import useSWRMutation from 'swr/mutation';

export function useCreateAgentRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'craete-agent-request',
    async (
      _,
      {
        arg,
      }: {
        arg: CreateAgentRequestParams;
      }
    ) => {
      await createAgentRequest(arg);
    },
    {
      throwOnError: true,
    }
  );

  return { createAgent: trigger, isCreatingAgent: isMutating };
}
