import { Dispatch } from 'redux';

import instance from 'utils/axios';
import {
  IFetchSmsOwnersSmsReducerAction,
  OwnersSmsActionsTypes,
  ISetIsLoadingOwnersSmsReducerAction,
  IFetchSmsOwnersSmsParams,
  IChangePaginationOwnersSmsReducerAction,
} from 'modules/owners/store/ownersSms/types';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';

export function fetchSms(params: IFetchSmsOwnersSmsParams & RequestParamsWithPagination) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setIsLoading(true));

      const { data } = await instance.get('/owners/sms', { params });

      dispatch<IFetchSmsOwnersSmsReducerAction>({
        type: OwnersSmsActionsTypes.FETCH_SMS,
        payload: {
          sms: data.data,
          pagination: data.meta,
        },
      });
    } finally {
      dispatch<any>(setIsLoading(false));
    }
  };
}

export function setIsLoading(payload: boolean) {
  return (dispatch: Dispatch) => {
    dispatch<ISetIsLoadingOwnersSmsReducerAction>({
      type: OwnersSmsActionsTypes.SET_IS_LOADING,
      payload,
    });
  };
}

export function changePagination(page: number, perPage: number) {
  return (dispatch: Dispatch) => {
    dispatch<IChangePaginationOwnersSmsReducerAction>({
      type: OwnersSmsActionsTypes.CHANGE_PAGINATION,
      payload: { page, perPage },
    });
  };
}
