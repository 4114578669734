import React from 'react';
import { Statistic } from 'antd';

interface Props {
  signal: number;
}

/**
 * Отображение сигнала с маркировкой уровня
 *
 * @author Рябущиц Иван <telegram: @ivangeli0n>
 */
export const GoipChannelSignal: React.FC<Props> = ({ signal }) => {
  const [error, success, warning] = ['#cf1322', '#30AB1C', '#d8c027'];

  let textColor = '#000';
  if (signal <= 10) {
    textColor = error;
  } else if (signal <= 15) {
    textColor = warning;
  } else {
    textColor = success;
  }

  return (
    <Statistic
      value={signal}
      valueStyle={{
        fontSize: '15px',
        color: textColor,
      }}
    />
  );
};
