import React, { useState } from 'react';
import { Button, Drawer, Space } from 'antd';

import { InputNumber } from 'components/Input';

import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { changeStatusOfActiveOrder } from 'modules/orders/store/activeOrders/actions';
import { ChangeStatusOfActiveOrderStatusesEnum } from 'modules/orders/enums/changeStatusOfActiveOrderStatuses.enum';
import { Order } from 'modules/orders/types/order.interface';

interface Props {
  order?: Order;
  onCancel: () => void;
}

export const ActiveOrdersPageCommissionSetDrawer: React.FC<Props> = ({ order, onCancel }) => {
  const [commissionAmount, setCommissionAmount] = useState<{
    [key: number]: number;
  }>({});

  const dispatch = useAppDispatch();

  function handleSubmit() {
    if (!order) return;

    dispatch(
      changeStatusOfActiveOrder({
        orderId: order.id,
        status: ChangeStatusOfActiveOrderStatusesEnum.DONE,
        ownersProviderCommissions: commissionAmount,
      })
    );
    setCommissionAmount({});
    onCancel();
  }

  return (
    <Drawer
      open={!!order}
      onClose={() => {
        setCommissionAmount({});
        onCancel();
      }}
      title={`Введите сумму коммиссии по транзакции №${order?.id}`}
    >
      <Space direction="vertical">
        {order?.owners.length !== 0 ? (
          order?.owners.map((owner) => (
            <InputNumber
              key={owner.ownerId}
              value={commissionAmount[owner.ownerId]}
              addonBefore={`Коммиссия агента №${owner.ownerId}`}
              onChange={(value) =>
                value &&
                setCommissionAmount((state) => {
                  state[owner.ownerId] = value as number;

                  return state;
                })
              }
              className="w-full"
            />
          ))
        ) : (
          <span className="text-red-500">Необходимо привязать владельцев</span>
        )}
        <Button type="primary" disabled={order?.owners.length === 0} onClick={handleSubmit}>
          Подтвердить с коммиссией
        </Button>
      </Space>
    </Drawer>
  );
};
