import React from 'react';

import { ArrowDownOutlined, ArrowUpOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Card, Row, Spin, Tooltip, Typography } from 'antd';
import { PaymentStatisticValues } from './types/paymentStatisticValues.interface';

interface Props {
  statistic?: PaymentStatisticValues;
  isLoading: boolean;
}

export const PaymentStatistic: React.FC<Props> = ({ statistic, isLoading }) => {
  return (
    <div className="flex">
      <Tooltip title="Запрос статистики осуществляется в соответствии с заданными фильтрами">
        <QuestionCircleOutlined className="ml-2 mr-4" />
      </Tooltip>
      <Spin spinning={isLoading}>
        <Row className="flex">
          <Card className="mr-1" size="small">
            <div className="flex">
              <div className="mr-5">
                <Typography.Text className="text-customGray">Входящие</Typography.Text>
                <div>
                  <ArrowDownOutlined className="text-xl text-customGreen mr-2" />
                  <Typography.Text className="text-lg">
                    {statistic ? statistic.incomingCount.toLocaleString('ru-RU') : '-'}
                  </Typography.Text>
                </div>
              </div>
              <div>
                <Typography.Text className="text-customGray">Сумма</Typography.Text>
                <div>
                  <Typography.Text className="text-lg">
                    {statistic?.incomingSum.toLocaleString('ru-RU')} {'\u20BD'}
                  </Typography.Text>
                </div>
              </div>
            </div>
          </Card>
          <Card className="mr-1" size="small">
            <div className="flex">
              <div className="mr-5">
                <Typography.Text className="text-customGray">Исходящие</Typography.Text>
                <div>
                  <ArrowUpOutlined className="text-xl text-primaryColor mr-2" />
                  <Typography.Text className="text-lg">
                    {statistic ? statistic.outcomingCount.toLocaleString('ru-RU') : '-'}
                  </Typography.Text>
                </div>
              </div>
              <div>
                <Typography.Text className="text-customGray">Сумма</Typography.Text>
                <div>
                  <Typography.Text className="text-lg">
                    {statistic?.outcomingSum.toLocaleString('ru-RU')} {'\u20BD'}
                  </Typography.Text>
                </div>
              </div>
            </div>
          </Card>
        </Row>
      </Spin>
    </div>
  );
};
