import React, { useEffect, useMemo, useState } from 'react';

import { AssignOwnersToTransactionsFormTotalSumCounter } from 'modules/orders/components/AssignOwnersToOrderForm/components/TotalSumCounter';
import { AssignOwnersToTransactionsFormOwnerCard } from 'modules/orders/components/AssignOwnersToOrderForm/components/OwnerCard';
import { AssignOwnersToTransactionsFormOwnersSelect } from 'modules/orders/components/AssignOwnersToOrderForm/components/OwnerSelect';

import { Order } from 'modules/orders/types/order.interface';
import { SelectedOwner } from 'modules/orders/components/AssignOwnersToOrderForm/interfaces/selectedOwner.interface';

interface Props {
  order?: Order;
  onChangeErrors: (errors: string[]) => void;
  selectedOwners: SelectedOwner[];
  onChangeSelectedOwners: (selectedOwners: SelectedOwner[]) => void;
}

export const AssignOwnersToOrderForm: React.FC<Props> = ({
  order,
  onChangeSelectedOwners,
  onChangeErrors,
  selectedOwners,
}) => {
  const [isMultipleOwners, setIsMultipleOwners] = useState<boolean>(!!order?.owners.length && order.owners.length > 1);

  const totalSumLimit = useMemo<number>(() => {
    if (!isMultipleOwners) return order?.amount ?? 0;
    console.log(selectedOwners, 'selectedOwners');
    return selectedOwners.reduce(
      (previousValue, owner) => previousValue + (owner.amount ?? getAmountByBankAccount(owner.bankAccountId)),
      0
    );
  }, [selectedOwners, isMultipleOwners]);

  const errors = useMemo<string[]>(() => {
    const errorsArray: string[] = [];

    const isYoomoney = order?.provider?.id === 53;
    const customerCard = order?.customerCard ?? '';

    if (
      isYoomoney &&
      customerCard.length >= 11 &&
      customerCard.length <= 20 &&
      /^4100/.test(customerCard) &&
      (selectedOwners.some((owner) => owner.amount && owner.amount > 15000) ||
        (!isMultipleOwners && order.amount > 15000 && selectedOwners.length !== 0))
    ) {
      errorsArray.push('Для заявок по Юmoney сумма на владельца не может превышать 15.000');
    }

    if (!isMultipleOwners && selectedOwners.length > 1) {
      errorsArray.push('Укажите только одного владельца');
    }

    if (!isMultipleOwners) return errorsArray;

    if (totalSumLimit !== order?.amount && selectedOwners.length !== 0) {
      errorsArray.push('Распределенная сумма на владельцев не совпадает с суммой транзакции!');
    }

    if (selectedOwners.length < 2) {
      errorsArray.push('Укажите минимум нескольких владельцев');
    }

    const ownersHasNoSum = selectedOwners.some((owner) => owner.amount === 0 || !owner?.amount);

    if (selectedOwners.length > 1 && ownersHasNoSum) {
      errorsArray.push('Владелец не может отправить сумму равную НУЛЮ');
    }

    return errorsArray;
  }, [selectedOwners, totalSumLimit, isMultipleOwners]);

  useEffect(() => {
    onChangeErrors(errors);
  }, [errors]);

  useEffect(() => {
    const isMultiple = !!order?.owners.length && order.owners.length > 1;

    setIsMultipleOwners(isMultiple);
  }, [order]);

  function getAmountByBankAccount(id: number): number {
    return selectedOwners.find((o) => o.bankAccountId === id)?.amount ?? 0;
  }

  function handleOwnerSumChange(bankAccountId: number, sum: number) {
    onChangeSelectedOwners(
      selectedOwners.map((owner) =>
        owner.bankAccountId === bankAccountId ? { ...owner, amount: Math.abs(sum) } : owner
      )
    );
  }

  if (!order) return null;

  return (
    <>
      {errors.map((error) => (
        <div className="text-red-600" key={error}>
          {error}
        </div>
      ))}
      <AssignOwnersToTransactionsFormOwnersSelect
        value={selectedOwners}
        onChange={onChangeSelectedOwners}
        transaction={order}
        isMultipleOwners={isMultipleOwners}
        onIsMultipeOwnersChange={setIsMultipleOwners}
      />
      {selectedOwners.length > 0 && (
        <AssignOwnersToTransactionsFormTotalSumCounter
          ownersTotalSum={totalSumLimit}
          transactionAmount={order?.amount ?? 0}
        />
      )}
      {isMultipleOwners &&
        selectedOwners.map((owner) => (
          <AssignOwnersToTransactionsFormOwnerCard
            owner={owner}
            onInputChange={(value) => handleOwnerSumChange(owner.bankAccountId, value ?? 0)}
            key={owner.ownerId}
          />
        ))}
    </>
  );
};
