import React, { FC, useEffect } from 'react';
import { UnifiedForm } from 'components/UnifiedForm';
import { fieldsSchema } from 'modules/bankAccounts/components/UpdateBankAccountForm/fields';
import { useForm } from 'antd/es/form/Form';
import { App } from 'antd';
import { UpdateBankAccountRequestParams } from 'modules/bankAccounts/api/updateBankAccount.request';
import { useUpdateBankAccountRequest } from 'modules/bankAccounts/hooks/useUpdateBankAccountRequest.hook';
import { useFetchBankAccountRequest } from 'modules/bankAccounts/hooks/useFetchBankAccountRequest.hook';

interface Props {
  id: number;
  onSuccess: () => void;
}

export const UpdateBankAccountForm: FC<Props> = ({ onSuccess, id }) => {
  const [form] = useForm<UpdateBankAccountRequestParams>();

  const { bankAccount, isFetchingBankAccount } = useFetchBankAccountRequest(id);
  const { updateBankAccount, isUpdatingBankAccount } = useUpdateBankAccountRequest();

  const { message } = App.useApp();

  useEffect(() => {
    if (!bankAccount) return;

    form.setFieldsValue({
      id: bankAccount.id,
      type: bankAccount.type.id,
      accountNumber: bankAccount.accountNumber,
      bankId: bankAccount.bank.id,
      bic: bankAccount.bic,
      sbpEnabled: bankAccount.sbpEnabled,
      phone: bankAccount.phone,
      limitMinIn: bankAccount.limitMinIn,
    });
  }, [bankAccount]);

  async function handleSubmit() {
    try {
      const params = form.getFieldsValue();

      await updateBankAccount(params);

      message.success('Счет владельца успешно обновлен');
      onSuccess();
    } catch (error) {
      message.error('Не удалось обновить счет владельца.');
    }
  }

  return (
    <UnifiedForm
      form={form}
      onSubmitClick={handleSubmit}
      className="flex flex-col min-h-full"
      fields={fieldsSchema}
      isSubmitButtonAtBottom
      isLoading={isFetchingBankAccount || isUpdatingBankAccount}
    />
  );
};
