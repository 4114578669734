import React from 'react';
import { InputProps, Input as BaseInput, Form } from 'antd';

interface Props {
  name?: string;
  noStyle?: boolean;
  label?: string;
}

export const Input: React.FC<Props & InputProps> = ({ name, noStyle = true, label, ...props }) => {
  const baseInput = <BaseInput {...props} />;

  if (!name) return baseInput;

  return (
    <Form.Item name={name} label={label} noStyle={noStyle}>
      {baseInput}
    </Form.Item>
  );
};

export { InputNumber } from './InputNumber';
