import useSWRMutation from 'swr/mutation';
import {
  createBankAccountRequest,
  CreateBankAccountRequestParams,
} from 'modules/bankAccounts/api/createBankAccount.request';

export function useCreateBankAccountRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'create-bank-account-request',
    (
      _,
      {
        arg,
      }: {
        arg: CreateBankAccountRequestParams;
      }
    ) => createBankAccountRequest(arg)
  );

  return {
    createBankAccount: trigger,
    isCreatingBankAccount: isMutating,
  };
}
