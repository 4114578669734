import React, { ReactElement } from 'react';
import { Space, Switch, Form } from 'antd';

interface Props {
  /**
   * Значение поля
   */
  value?: boolean;
  /**
   * Колбэк при изменении значения поля
   */
  onChange?: (value: boolean) => void;
  /**
   * Дочерний элемент (заголовок) поля
   */
  children: ReactElement | string;
  /**
   * Название поля (если это часть формы)
   */
  name?: string;
  className?: string;
  defaultValue?: boolean;
}

/**
 * Общий для проекта компонент поля с заголовком и свитчом
 *
 * @author Собянин Виктор <telegram: @victorsobyanin>
 */
export const SwitchField: React.FC<Props> = ({ value, onChange, children, name, className, defaultValue }) => {
  let baseComponent = <Switch checked={value} onChange={onChange} defaultChecked={defaultValue} />;

  if (name) {
    baseComponent = (
      <Form.Item name={name} initialValue={defaultValue} noStyle>
        {baseComponent}
      </Form.Item>
    );
  }

  return (
    <Space className={className}>
      {baseComponent}
      <p>{children}</p>
    </Space>
  );
};
