import { IOwnersSmsState, OwnersSmsReducerActions, OwnersSmsActionsTypes } from 'modules/owners/store/ownersSms/types';

const initialState: IOwnersSmsState = {
  sms: [],
  isLoading: false,
  pagination: {
    page: 1,
    total: 0,
    perPage: 20,
    pages: 0,
  },
};

export function ownersSmsReducer(state = initialState, action: OwnersSmsReducerActions): IOwnersSmsState {
  switch (action.type) {
    case OwnersSmsActionsTypes.FETCH_SMS:
      return {
        ...state,
        sms: action.payload.sms,
        pagination: action.payload.pagination,
      };
    case OwnersSmsActionsTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case OwnersSmsActionsTypes.CHANGE_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          perPage: action.payload.perPage,
        },
      };
    default:
      return state;
  }
}
