import { Radio, Spin } from 'antd';
import { RadioProps } from 'antd/lib';
import { LazySelectDataTransformer } from 'components/LazySelect/types/dataTransformer.type';
import { UseDataFromServerHookStatesEnum } from 'hooks/useDataFromServer';
import React, { useMemo, useState } from 'react';

interface Props extends RadioProps {
  useDataHook: (autoFetch: boolean) => readonly [any, UseDataFromServerHookStatesEnum, () => Promise<any>];
  dataTransformer?: LazySelectDataTransformer;
  optionType?: 'button' | 'default';
  cacheKey: string;
  title: string;
}

export const LazyRadio: React.FC<Props> = ({
  useDataHook,
  dataTransformer,
  optionType,
  cacheKey,
  title,
  ...radioProps
}) => {
  const [data, state, fetchData] = useDataHook(false);
  const [cachedData, setCachedData] = useState(() => readOptionsCache());

  const CACHE_EXPIRY_TIME = 60 * 60 * 1000;

  function readOptionsCache() {
    const cachedOptions = localStorage.getItem(getCacheKey());
    const expiryTime = localStorage.getItem(getExpiryCacheKey());

    if (!cachedOptions || !expiryTime) {
      return null;
    }

    if (Date.now() > parseInt(expiryTime, 10)) {
      localStorage.removeItem(getCacheKey());
      localStorage.removeItem(getExpiryCacheKey());

      return null;
    }

    return JSON.parse(cachedOptions);
  }

  function saveOptionsCache(optionsToCache: any) {
    if (optionsToCache.length > 0) {
      localStorage.setItem(getCacheKey(), JSON.stringify(optionsToCache));
      localStorage.setItem(getExpiryCacheKey(), (Date.now() + CACHE_EXPIRY_TIME).toString());
    }
  }

  function getCacheKey() {
    return 'lazy_radio_options_cache_' + cacheKey;
  }

  function getExpiryCacheKey() {
    return getCacheKey() + '_expiry';
  }

  const fetchAndCacheData = async () => {
    if (data.length === 0) {
      await fetchData();

      return;
    }

    saveOptionsCache(data);
    setCachedData(data);
  };

  const options = useMemo(() => {
    if (cachedData) {
      return cachedData.map(dataTransformer);
    }

    fetchAndCacheData();

    return (data || []).map(dataTransformer);
  }, [cachedData, data]);

  return (
    <div className="flex items-center">
      <div className="leading-4">{title}</div>
      {state === UseDataFromServerHookStatesEnum.LOADING ? (
        <Spin className="ml-4" />
      ) : (
        <Radio.Group {...radioProps} options={options} className="flex whitespace-nowrap" optionType={optionType} />
      )}
    </div>
  );
};
