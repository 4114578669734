import React from 'react';
import CIcon from '@coreui/icons-react';
import { cilChevronCircleDownAlt, cilChevronCircleUpAlt } from '@coreui/icons';
import { OrderTypes } from 'modules/orders/enums/orderTypes.enum';

interface Props {
  typeValue?: number;
}

export const OrdersPageTableIconCellType: React.FC<Props> = ({ typeValue }) => {
  const isIncomingOrder = typeValue === OrderTypes.INCOMING;

  return (
    <CIcon
      className={isIncomingOrder ? 'text-red-500' : 'text-green-500'}
      icon={isIncomingOrder ? cilChevronCircleDownAlt : cilChevronCircleUpAlt}
      style={{ width: 30 }}
    />
  );
};
