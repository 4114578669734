import instance from 'utils/axios';
import { MerchantToken } from '../types/merchantToken.interface';

export async function createMerchantTokenRequest(merchantId: number): Promise<MerchantToken> {
  const {
    data: { data },
  } = await instance.post('/merchants/tokens/create', {
    merchantId,
  });

  return data;
}
