import { Space } from 'antd';
import React from 'react';

import { ValueColumns, TableMerchantCommission } from '../../types';
import { InputNumber } from 'components/Input';

interface Props {
  commission: TableMerchantCommission;
  p2pColumnName: keyof ValueColumns;
  c2cColumnName: keyof ValueColumns;
  index: number;
}

export const EditMerchantCommissionsDrawerInputColumn: React.FC<Props> = ({
  commission,
  p2pColumnName,
  c2cColumnName,
  index,
}) => {
  function normalizeValue(value: number | undefined): number | undefined {
    return value && parseFloat((value * 100).toFixed(2));
  }

  const p2pColumn = commission[p2pColumnName];
  const c2cColumn = commission[c2cColumnName];

  return (
    <Space direction="vertical">
      <InputNumber
        defaultValue={normalizeValue(p2pColumn)}
        size="middle"
        className="w-60"
        name={[index, p2pColumnName]}
        addonBefore="P2P"
        addonAfter="%"
      />
      <InputNumber
        defaultValue={normalizeValue(c2cColumn)}
        size="middle"
        name={[index, c2cColumnName]}
        className="w-60"
        addonBefore="C2C"
        disabled={commission.currencyCode !== 'RUB'}
        addonAfter="%"
      />
    </Space>
  );
};
