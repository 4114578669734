import React, { useState, useEffect } from 'react';
import { Input, Button, Modal } from 'antd';

import { ActiveOrder } from 'modules/orders/store/activeOrders/interfaces/activeOrder.interface';
import { InputNumber } from 'components/Input';
import { Tooltip } from 'components/Tooltip';

interface Props {
  order?: ActiveOrder;
  loading: boolean;
  onConfirm: (id: number, amount: number, customerName?: string) => void;
  onCancel: () => void;
}

export const ActiveOrdersPageUnknownOrderModal: React.FC<Props> = ({ loading, order, onConfirm, onCancel }) => {
  const [customerName, setCustomerName] = useState<string>();
  const [amount, setAmount] = useState<number>();

  useEffect(() => {
    if (!order) return;

    setCustomerName(order.customerName);
    setAmount(order.amount);
  }, [order]);

  return (
    <Modal
      title={`Транзакция №${order?.id}`}
      open={!!order}
      closable={!loading}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" disabled={loading} onClick={onCancel}>
          Отмена
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => order && onConfirm(order?.id, amount!, customerName)}
          loading={loading}
        >
          Подтвердить
        </Button>,
      ]}
    >
      <Tooltip title="ФИО Плательщика (необязательное)" trigger={['focus']} placement="leftTop">
        <Input
          className="mb-3"
          placeholder="ФИО Плательщика (необязательное)"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
        />
      </Tooltip>
      <Tooltip title="Сумма платежа" trigger={['focus']} placement="leftTop">
        <InputNumber
          className="w-full"
          placeholder="Сумма платежа"
          parser={(value) => parseFloat(value?.replace(',', '.') ?? '0')}
          value={amount}
          onChange={(value) => setAmount(value as number)}
        />
      </Tooltip>
    </Modal>
  );
};
