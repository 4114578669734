import React, { useEffect, useState } from 'react';
import { message, Select } from 'antd';
import { BankAccount } from 'modules/bankAccounts/types/bankAccount.interface';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { permissions } from 'policies/permissions';
import { BankAccountStatusEnum } from 'modules/bankAccounts/enums/bankAccountStatus.enum';
import { useActivateBankAccountRequest, useBlockBankAccountRequest } from 'modules/bankAccounts/hooks';

interface Props {
  account: BankAccount;
}

export const BankAccountStatusSelect: React.FC<Props> = ({ account }) => {
  const [currentStatus, setCurrentStatus] = useState<BankAccountStatusEnum>(account.status);

  const { isActivatingBankAccount, activateBankAccount } = useActivateBankAccountRequest();
  const { isBlockingBankAccount, blockBankAccount } = useBlockBankAccountRequest();

  const { userCan } = useUserPermissions();

  useEffect(() => {
    setCurrentStatus(account.status);
  }, [account]);

  async function handleBankAccountStatusChange(newStatus: BankAccountStatusEnum) {
    try {
      switch (newStatus) {
        case BankAccountStatusEnum.ACTIVE:
          await activateBankAccount(account.id);
          break;
        case BankAccountStatusEnum.BLOCKED:
          await blockBankAccount(account.id);
          break;
      }

      message.success({
        content: 'Статус счёта успешно изменён!',
        duration: 1.5,
      });
      setCurrentStatus(newStatus);
    } catch {
      message.error('Ошибка изменения статуса счёта!');
    }
  }

  return (
    <>
      <Select
        options={[
          {
            value: BankAccountStatusEnum.ACTIVE,
            label: 'Активный',
            disabled: !userCan(permissions.bankAccount.activate),
          },
          {
            value: BankAccountStatusEnum.BLOCKED,
            label: 'Заблокирован',
            disabled: !userCan(permissions.bankAccount.block),
          },
        ]}
        value={currentStatus}
        onChange={handleBankAccountStatusChange}
        style={{ minWidth: 150 }}
        disabled={isBlockingBankAccount || isActivatingBankAccount}
        loading={isBlockingBankAccount || isActivatingBankAccount}
      />
    </>
  );
};
