export function maskAccountNumber(accountNumber: string) {
  return accountNumber
    .split('')
    .map((char, index) => {
      if (index < 3 || index > 9) {
        return char;
      }

      return '*';
    })
    .join('');
}
