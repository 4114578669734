import useSWRMutation from 'swr/mutation';
import { updateAgentRequest, UpdateAgentRequestParams } from 'modules/agents/api/updateAgent.request';

export function useUpdateAgentRequest() {
  const { isMutating, trigger } = useSWRMutation(
    'update-agent-api',
    async (
      _,
      {
        arg,
      }: {
        arg: UpdateAgentRequestParams;
      }
    ) => {
      await updateAgentRequest(arg);
    },
    {
      throwOnError: true,
    }
  );

  return { isAgentUpdating: isMutating, updateAgent: trigger };
}
