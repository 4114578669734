import useSWR from 'swr';
import { fetchMerchantBalancesRequest } from '../api/fetchMerchantBalances.request';

export function useFetchMerchantBalancesRequest(id: number) {
  const { data, isLoading, mutate } = useSWR([id, 'fetch-merchant-balances-request'], ([id]) =>
    fetchMerchantBalancesRequest(id, 100)
  );

  return {
    merchantBalances: data,
    isFetchingMerchantBalances: isLoading,
    fetchMerchantBalances: mutate,
  };
}
