import React, { useMemo } from 'react';

import { Button, Form, Select, Space } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { DeleteOutlined } from '@ant-design/icons';

import { ICurrency } from 'types/currency.interface';
import { InputNumber } from 'components/Input';
import { CommissionColumn, TableMerchantModalCommission } from '../../../types';

export const useEditMerchantCommissionsDrawerModalColumns = (
  currencies: ICurrency[],
  tableData: TableMerchantModalCommission[],
  onCurrencySelect: (currency: string, index: number) => void,
  onCommissionChange: (commission: number | undefined, index: number, commissionName: CommissionColumn) => void,
  handleRemoveRowByIndex: (index: number) => void
): ColumnType<TableMerchantModalCommission>[] => {
  const options = useMemo(() => {
    const usedCurrencies = tableData.map((item) => item.currencyCode);

    return currencies.map((currency) => ({
      label: currency.code,
      value: currency.code,
      disabled: usedCurrencies.includes(currency.code!),
    }));
  }, [tableData, currencies]);

  return useMemo<ColumnType<TableMerchantModalCommission>[]>(
    () => [
      {
        title: 'Валюта',
        dataIndex: 'currencyCode',
        key: 'currencyCode',
        render: (_, { currencyCode }, index) => {
          return (
            <Form.Item className="w-20" name={[index, 'currencyCode']}>
              <Select
                placeholder="Валюта"
                onChange={(value) => onCurrencySelect(value, index)}
                options={options}
                defaultValue={currencyCode}
              />
            </Form.Item>
          );
        },
      },
      {
        title: 'Входящие',
        dataIndex: 'incomingValue',
        key: 'incomingValue',
        render: (_, { p2pIncomingValue, c2cIncomingValue, currencyCode }, index) => {
          return (
            <Space direction="vertical">
              <InputNumber
                onChange={(value) => {
                  onCommissionChange(value === null ? undefined : Number(value), index, 'p2pIncomingValue');
                }}
                defaultValue={p2pIncomingValue}
                size="middle"
                className="w-60"
                name={[index, 'p2pIncomingValue']}
                addonBefore="P2P"
                addonAfter="%"
              />
              <InputNumber
                onChange={(value) => {
                  onCommissionChange(value === null ? undefined : Number(value), index, 'c2cIncomingValue');
                }}
                defaultValue={c2cIncomingValue}
                size="middle"
                name={[index, 'c2cIncomingValue']}
                className="w-60"
                addonBefore="C2C"
                disabled={currencyCode !== 'RUB'}
                addonAfter="%"
              />
            </Space>
          );
        },
      },
      {
        title: 'Исходящие',
        dataIndex: 'outcomingValue',
        key: 'outcomingValue',
        render: (_, { p2pOutcomingValue, c2cOutcomingValue, currencyCode }, index) => {
          return (
            <Space direction="vertical">
              <InputNumber
                onChange={(value) => {
                  onCommissionChange(value === null ? undefined : Number(value), index, 'p2pOutcomingValue');
                }}
                defaultValue={p2pOutcomingValue}
                size="middle"
                className="w-60"
                name={[index, 'p2pOutcomingValue']}
                addonBefore="P2P"
                addonAfter="%"
              />
              <InputNumber
                onChange={(value) => {
                  onCommissionChange(value === null ? undefined : Number(value), index, 'c2cOutcomingValue');
                }}
                defaultValue={c2cOutcomingValue}
                size="middle"
                name={[index, 'c2cOutcomingValue']}
                className="w-60"
                addonBefore="C2C"
                disabled={currencyCode !== 'RUB'}
                addonAfter="%"
              />
            </Space>
          );
        },
      },
      {
        render: (_, __, index) => {
          return <Button onClick={() => handleRemoveRowByIndex(index)} danger type="text" icon={<DeleteOutlined />} />;
        },
      },
    ],
    [options]
  );
};
