import React, { useMemo } from 'react';
import { Button } from 'antd';
import { LinkOutlined, PoweroffOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import Link from 'antd/lib/typography/Link';
import { Divider, Popconfirm } from 'antd/es';

import { Goip, IGoipChannelAuthOnlineStatus } from 'modules/goips/store/goips/types';

import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';

import { useTheme } from 'contexts';
import { StatusesEnum } from 'components/Status/StatusesEnum';
import { Status } from 'components/Status/Status';
import { Tooltip } from 'components/Tooltip';

export interface Props {
  disableOwners: (goipName: string) => void;
}

export function useGoipsTableColumns(props: Props) {
  const {
    currentTheme: {
      token: { colorPrimary },
    },
  } = useTheme();

  const { disableOwners } = props;

  return useMemo<ColumnsType<Goip>>(() => {
    return [
      {
        align: 'left',
        dataIndex: 'name',
        key: 'name',
        title: () => <div style={{ padding: '16px 8px 16px 0' }}>Название шлюза</div>,
      },
      {
        align: 'center',
        title: 'Статус шлюза',
        render: (goip: Goip) => {
          const hasSuccessStatus = (goip.channels ?? []).every((channel) => {
            return IGoipChannelAuthOnlineStatus.includes(channel.login);
          });

          const hasWarningStatus = (goip?.channels ?? []).some((channel) => {
            return IGoipChannelAuthOnlineStatus.includes(channel.login);
          });

          let status: StatusesEnum = StatusesEnum.ERROR;
          if (hasSuccessStatus) {
            status = StatusesEnum.SUCCESS;
          } else if (hasWarningStatus) {
            status = StatusesEnum.WARNING;
          }

          return <Status status={status} />;
        },
      },
      {
        align: 'center',
        title: 'Кол-во свободных портов',
        key: 'availableChannelsCount',
        render: (_: any, goip) => <div>{goip.channels.filter((port) => !port.owner).length}</div>,
      },
      {
        align: 'right',
        title: 'Действия',
        render: (goip: Goip) => {
          const ownerIdsToDisable = goip.channels
            .map((channel) => channel.owner)
            .filter((owner) => owner?.isActive)
            .map((owner) => owner.id)
            .flat();

          return (
            <>
              <Tooltip title="Владельцы">
                <Link target="_blank" href={'/owners?goips[]=' + goip.name}>
                  <LinkOutlined className="text-xl" style={{ color: colorPrimary }} />
                </Link>
              </Tooltip>
              <Divider type="vertical" />
              {ownerIdsToDisable.length > 0 && (
                <ProtectedComponent requiredPermissions={[permissions.owner.status.update]}>
                  {ownerIdsToDisable.length !== 0 ? (
                    <Popconfirm
                      title="Вы действительно хотите отключить владельцев шлюза?"
                      okType="danger"
                      okText="Отключить"
                      cancelText="Нет"
                      onConfirm={() => {
                        disableOwners(goip.name);
                      }}
                    >
                      <Tooltip title="Отключить">
                        <Button
                          className="bg-red-500 text-white"
                          type="default"
                          shape="circle"
                          icon={<PoweroffOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  ) : (
                    <></>
                  )}
                </ProtectedComponent>
              )}
            </>
          );
        },
      },
    ];
  }, []);
}
