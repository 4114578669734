import { ActiveOrdersState } from './interfaces/activeOrdersState.interface';
import { ActiveOrdersActionTypes, ActiveOrdersReducerActions } from '../activeOrders/types';
import { OrderStatusEnum } from 'modules/orders/enums/orderStatus.enum';

const initialState: ActiveOrdersState = {
  orders: [],
  isLoading: false,
  isBuffering: false,
  createdOrdersBuffer: [],
  createdOrdersIdsBuffer: [],
  pagination: {
    page: 1,
    total: 0,
    pages: 0,
    perPage: 20,
  },
  ordersIds: [],
};

export function activeOrdersReducer(
  state: ActiveOrdersState = initialState,
  action: ActiveOrdersReducerActions
): ActiveOrdersState {
  switch (action.type) {
    case ActiveOrdersActionTypes.GET_ACTIVE_ORDERS: {
      let orders = action.payload.orders;

      if (!Array.isArray(orders)) {
        orders = Object.values(orders);
      }

      return {
        ...state,
        orders: orders,
        pagination: action.payload.pagination,
        isLoading: false,
        ordersIds: orders.map((order) => order.id),
        isBuffering: false,
        createdOrdersBuffer: [],
        createdOrdersIdsBuffer: [],
      };
    }
    case ActiveOrdersActionTypes.ADD_ACTIVE_ORDER: {
      const { order, isTableView, sortBy, sortDirection } = action.payload;

      if (state.ordersIds.includes(order.id)) return state;

      if (state.isBuffering)
        return {
          ...state,
          createdOrdersBuffer: [order, ...state.createdOrdersBuffer],
          createdOrdersIdsBuffer: [order.id, ...state.createdOrdersIdsBuffer],
        };

      const orders = [order, ...state.orders];
      const ordersIds = [order.id, ...state.ordersIds];

      if (sortBy !== 'created_at' || sortDirection !== 'desc') {
        orders.sort((a, b) => {
          if (sortBy === 'created_at') {
            return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
          }

          if (sortBy === 'sum') {
            return sortDirection === 'asc' ? a.amount - b.amount : b.amount - a.amount;
          }

          return 0;
        });
      }

      if (isTableView && state.pagination.perPage < orders.length) {
        orders.pop();
        ordersIds.pop();
      }

      return {
        ...state,
        pagination: { ...state.pagination, total: state.pagination.total + 1 },
        orders,
        ordersIds,
      };
    }
    case ActiveOrdersActionTypes.EDIT_ACTIVE_ORDER: {
      const editedOrder = action.payload;
      let workField: 'createdOrdersBuffer' | 'orders' | null = null;
      let workIdsField: 'createdOrdersIdsBuffer' | 'ordersIds' | null = null;

      const allowedStatuses = [
        OrderStatusEnum.IN_PROGRESS,
        OrderStatusEnum.AWAITING,
        OrderStatusEnum.WAITING_FOR_AUTO_ASSIGN_OWNER,
      ];

      if (state.createdOrdersIdsBuffer.includes(editedOrder.id)) {
        workField = 'createdOrdersBuffer';
        workIdsField = 'createdOrdersIdsBuffer';
      }

      if (state.ordersIds.includes(editedOrder.id)) {
        workField = 'orders';
        workIdsField = 'ordersIds';
      }

      if (!workField || !workIdsField) return state;

      if (!allowedStatuses.includes(editedOrder.status.id)) {
        const updatedOrders = state[workField].filter((order) => order.id !== editedOrder.id);

        return {
          ...state,
          [workField]: updatedOrders,
          [workIdsField]: state[workIdsField].filter((id) => id !== editedOrder.id),
          pagination: { ...state.pagination, total: updatedOrders.length },
        };
      }

      return {
        ...state,
        [workField]: state.orders.map((order) => {
          if (editedOrder.id !== order.id) {
            return order;
          }

          return editedOrder;
        }),
      };
    }
    case ActiveOrdersActionTypes.MARK_AS_UNKNOWN_ORDER:
    case ActiveOrdersActionTypes.CHANGE_ORDER_STATUS:
      return {
        ...state,
        orders: state.orders.filter(({ id }) => id !== action.payload),
        ordersIds: state.ordersIds.filter((id) => id !== action.payload),
        pagination: { ...state.pagination, total: state.pagination.total - 1 },
        isLoading: false,
      };
    case ActiveOrdersActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ActiveOrdersActionTypes.UPDATE_OWNERS_BINDINGS_TO_ORDER:
    case ActiveOrdersActionTypes.BIND_OWNER_TO_ORDER:
    case ActiveOrdersActionTypes.DISABLE_AUTO_ASSIGNMENT: {
      const updatedOrder = action.payload;

      return {
        ...state,
        orders: state.orders.map((order) => (order.id === updatedOrder.id ? updatedOrder : order)),
        isLoading: false,
      };
    }
    case ActiveOrdersActionTypes.BIND_OPERATOR: {
      const { orderId, operatorId } = action.payload;

      const changedOrder = state.orders.find((tr) => tr.id === orderId);
      if (!changedOrder) return { ...state, isLoading: false };

      changedOrder.linkedOperatorId = operatorId;
      return {
        ...state,
        isLoading: false,
        orders: state.orders.map((order) => {
          return orderId === order.id ? changedOrder : order;
        }),
      };
    }
    case ActiveOrdersActionTypes.CHANGE_BUFFERING:
      if (!action.payload)
        return {
          ...state,
          isBuffering: false,
          orders: [...state.createdOrdersBuffer, ...state.orders],
          ordersIds: [...state.createdOrdersIdsBuffer, ...state.ordersIds],
          createdOrdersIdsBuffer: [],
          createdOrdersBuffer: [],
        };

      return {
        ...state,
        isBuffering: true,
      };
    case ActiveOrdersActionTypes.CHANGE_PAGINATION: {
      const { page, perPage } = action.payload;

      return {
        ...state,
        pagination: {
          ...state.pagination,
          page,
          perPage,
        },
      };
    }
    case ActiveOrdersActionTypes.CLEAR_ACTIVE_ORDERS:
      return {
        ...state,
        orders: [],
        pagination: { ...state.pagination, page: 1, perPage: 20 },
        isLoading: false,
      };
    default:
      return state;
  }
}
