import useSWRMutation from 'swr/mutation';
import { deleteReportRequest } from 'modules/reports/api';

export function useDeleteReportRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'delete-report-request',
    (_, { arg }: { arg: number }) => deleteReportRequest(arg),
    {
      throwOnError: true,
    }
  );

  return { deleteReport: trigger, isDeletingReport: isMutating };
}
