import { IBanksState, BanksReducerActions, BanksActionsTypes } from 'modules/banks/store/banks/types';

const initialState: IBanksState = {
  banks: [],
  isLoading: false,
  pagination: {
    total: 0,
    page: 1,
    pages: 0,
    perPage: 20,
  },
  foundBinBank: null,
  duplicates: {
    mainBank: null,
    mergeBanks: [],
  },
};

export function banksReducer(state = initialState, action: BanksReducerActions): IBanksState {
  switch (action.type) {
    case BanksActionsTypes.FETCH_BANKS: {
      const { banks, pagination } = action.payload;

      return { ...state, banks, pagination };
    }
    case BanksActionsTypes.DISABLED_CHECKED_MAIN_BANK: {
      return {
        ...state,
        banks: state.banks.map((bank) => (bank.id === action.payload.id ? action.payload : bank)),
      };
    }
    case BanksActionsTypes.DISABLED_CHECKED_MERGE_BANK: {
      return {
        ...state,
        banks: state.banks.map((bank) => (bank.id === action.payload.id ? action.payload : bank)),
      };
    }
    case BanksActionsTypes.CLEAR_CHECKED_MAIN_BANK: {
      return {
        ...state,
        banks: state.banks.map((bank) => (bank.checkedMain ? { ...bank, checkedMain: false } : bank)),
      };
    }
    case BanksActionsTypes.CLEAR_CHECKED_MERGE_BANK: {
      return {
        ...state,
        banks: state.banks.map((bank) => (bank.checkedMerge ? { ...bank, checkedMerge: false } : bank)),
      };
    }
    case BanksActionsTypes.CLEAR_DISABLED_CHECKED_BANKS: {
      return {
        ...state,
        banks: state.banks.map((bank) => ({
          ...bank,
          disabledMerge: false,
          disabledMain: false,
        })),
      };
    }
    case BanksActionsTypes.SET_IS_LOADING: {
      return { ...state, isLoading: action.payload };
    }
    case BanksActionsTypes.SET_PAGINATION: {
      const { page, perPage } = action.payload;

      return { ...state, pagination: { ...state.pagination, page, perPage } };
    }
    case BanksActionsTypes.ADD_MAIN_BANK_TO_REMOVE_DUPLICATE: {
      return {
        ...state,
        duplicates: {
          ...state.duplicates,
          mainBank: action.payload,
        },
      };
    }
    case BanksActionsTypes.ADD_MERGE_BANKS_TO_REMOVE_DUPLICATE: {
      return {
        ...state,
        duplicates: {
          ...state.duplicates,
          mergeBanks: [...state.duplicates.mergeBanks, action.payload],
        },
      };
    }
    case BanksActionsTypes.DELETE_MERGE_BANKS_TO_REMOVE_DUPLICATE: {
      return {
        ...state,
        duplicates: {
          ...state.duplicates,
          mergeBanks: state.duplicates.mergeBanks.filter((bank) => bank.id !== action.payload.id),
        },
      };
    }
    case BanksActionsTypes.CLEAR_MAIN_AND_MERGE_BANK: {
      return {
        ...state,
        duplicates: {
          ...state.duplicates,
          mainBank: null,
          mergeBanks: [],
        },
      };
    }
    default: {
      return state;
    }
  }
}
