import React, { useState, useEffect, useRef } from 'react';
import { Table } from 'antd';

import { IOrderDispute } from 'modules/disputes/types/IOrderDispute';

import { useOrderDisputesTableColumns } from './columns';

interface Props {
  /** Данные таблицы */
  data: IOrderDispute[];
  /** Идет ли в данный момент загрузка */
  isLoading: boolean;
  onShowSmsModal: (dispute: IOrderDispute) => void;
}

/**
 * Компонент таблицы на странице диспутов
 *
 * @author Собянин Виктор <telegram: @victorsobyanin>
 */
export const OrderDisputesPageTable: React.FC<Props> = ({ data, isLoading, onShowSmsModal }) => {
  const [timer, setTimer] = useState<number>(0);

  const intervalRef = useRef<NodeJS.Timer>();

  const columns = useOrderDisputesTableColumns(timer, onShowSmsModal);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimer((state) => state + 1);
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, [data]);

  return (
    <Table
      className="big-table"
      rowKey={(record) => record.id}
      dataSource={data}
      loading={isLoading}
      pagination={false}
      columns={columns}
      rowClassName={(record) => (record.new ? 'animate-pulse bg-green-50' : '')}
      expandable={{
        expandedRowRender: ({ receipts }) => {
          return (
            <Table
              className="bg-sky-500"
              dataSource={receipts}
              pagination={false}
              onRow={() => ({
                style: {
                  height: '55px',
                },
              })}
              columns={[
                { title: 'Сумма', dataIndex: 'amount' },
                { title: 'ФИО получателя', dataIndex: 'fioReceiver' },
                { title: 'Карта получателя', dataIndex: 'cardReceiver' },
                { title: 'ФИО отправителя', dataIndex: 'fioSender' },
                { title: 'Карта отправителя', dataIndex: 'cardSender' },
              ]}
            />
          );
        },
        rowExpandable: ({ receipts }) => receipts && receipts.length > 0,
      }}
    />
  );
};
