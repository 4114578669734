import React from 'react';

import { Image, Popover, Space } from 'antd';

import { OrderBank } from 'modules/orders/types/orderBank.interface';
import { Tooltip } from 'components/Tooltip';

interface Props {
  provider?: OrderBank;
  isSbp: boolean;
}

export const ActiveOrdersPageColumnsViewProviderImage: React.FC<Props> = ({ provider, isSbp }) => {
  if (!provider) return <div>Нет данных</div>;

  if (!provider.image) return <div>{provider.name}</div>;

  if (isSbp) {
    return (
      <Popover
        title="Банк перевода"
        content={<Image src={provider.image} width={60} preview={false} alt={provider.name} />}
      >
        <Image src={'https://paytransferbot.h1n.ru/img/sbp.png'} width={60} preview={false} alt={provider.name} />
      </Popover>
    );
  }

  const content = (
    <Tooltip title={provider?.name}>
      <Image src={provider?.image} width={60} preview={false} alt={provider?.name} />
    </Tooltip>
  );
  // Для банка Юmoney (id 53) нужно выводить ссылку на особые условия
  if (provider.id !== 53) return content;

  return (
    <Popover
      title="Особые условия для банка Юmoney"
      content="Нажмите, чтобы перейти на инструкцию по работе с банком Юmoney"
    >
      <Space
        className="cursor-pointer hover:opacity-50"
        onClick={() => window.open('https://t.me/c/1568231806/1720', '_blank')}
      >
        {content}
        <div className="text-blue-500 text-xl">*</div>
      </Space>
    </Popover>
  );
};
