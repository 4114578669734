import { OrderStatusEnum } from 'modules/orders/enums/orderStatus.enum';

export const incomingStatusOptions = [
  { value: OrderStatusEnum.IN_PROGRESS, label: 'В обработке' },
  { value: OrderStatusEnum.ENTERED, label: 'Получено' },
  { value: OrderStatusEnum.NOT_ENTERED, label: 'Не получено' },
  {
    value: OrderStatusEnum.UNKNOWN_ENTERED,
    label: 'Неизвестный платеж',
  },
  { value: OrderStatusEnum.AWAITING, label: 'В ожидании' },
];

export const notEnteredAvailableStatusOptions = [
  { value: OrderStatusEnum.ENTERED, label: 'Получено' },
  { value: OrderStatusEnum.NOT_ENTERED, label: 'Не получено' },
  {
    value: OrderStatusEnum.UNKNOWN_ENTERED,
    label: 'Неизвестный платеж',
  },
];

export const undoneAvailableStatusOptions = [
  { value: OrderStatusEnum.EXECUTED, label: 'Выполнено' },
  { value: OrderStatusEnum.NOT_EXECUTED, label: 'Не выполнено' },
];

export const outcomingStatusOptions = [
  { value: OrderStatusEnum.IN_PROGRESS, label: 'В обработке' },
  { value: OrderStatusEnum.EXECUTED, label: 'Выполнено' },
  { value: OrderStatusEnum.NOT_EXECUTED, label: 'Не выполнено' },
  { value: OrderStatusEnum.AWAITING, label: 'В ожидании' },
  {
    value: OrderStatusEnum.WAITING_FOR_AUTO_ASSIGN_OWNER,
    label: 'В ожидании автоматической привязки',
  },
];
