import React from 'react';

import { LazySelect } from 'components/LazySelect';

import { useBanksFromServer } from 'hooks/useDataFromServer';

interface Props {
  selectedFilters?: number[];
  onSelectedFilterChange: (value: number[]) => void;
}

export const EditMerchantCommissionsDrawerFilters: React.FC<Props> = ({
  selectedFilters: selectedFilter,
  onSelectedFilterChange,
}) => {
  return (
    <LazySelect
      style={{ minWidth: '128px' }}
      className="mb-4"
      allowClear
      mode="multiple"
      placeholder="Провайдер"
      value={selectedFilter}
      onChange={(newValue) => onSelectedFilterChange(newValue)}
      useDataHook={useBanksFromServer}
      cacheKey="provider-filter"
    />
  );
};
