import React from 'react';
import { Link } from 'react-router-dom';
import { CloseOutlined, LoginOutlined, MenuOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import LogoWhiteBlackIconSvg from 'assets/svg/Logo-white-black.svg';

import { useTheme } from 'contexts';
import { ThemeSwitcher } from 'components/ThemeSwitcher/ThemeSwitcher';
import { logout } from 'modules/auth/store/auth/actions';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useUserData } from 'components/Layout/hooks/useUserData.hook';

interface Props {
  isSiderShown: boolean;
  onSiderToggle: (isSiderShown: boolean) => void;
}

export const LayoutHeader: React.FC<Props> = ({ isSiderShown, onSiderToggle }) => {
  const dispatch = useAppDispatch();

  const { userName, userRole } = useUserData();

  const {
    currentTheme: {
      token: { colorPrimary, colorBgTableHeader },
    },
  } = useTheme();

  return (
    <div
      className="flex justify-between items-center w-full h-12 fixed z-10"
      style={{ background: colorPrimary, boxShadow: '0 4px 4px rgba(0, 0, 0, 0.2)', zIndex: 1115 }}
    >
      <div className="flex items-center">
        <Button
          type="text"
          icon={isSiderShown ? <CloseOutlined /> : <MenuOutlined />}
          onClick={() => onSiderToggle(isSiderShown)}
          style={{ backgroundColor: colorPrimary, color: '#ffffffd9' }}
          className="ml-5"
        ></Button>
        <div className="mr-6 ml-2">
          <ThemeSwitcher />
        </div>
      </div>
      <div>
        <Link to="/">
          <img src={LogoWhiteBlackIconSvg} className="w-36 m-auto pt-3 pb-3" alt="Logo" />
        </Link>
      </div>
      <Button
        style={{
          border: 'none',
          boxShadow: 'none',
          padding: '4px 6px',
          lineHeight: 0,
          color: 'white',
          transition: 'background-color 0.5s ease',
        }}
        className="mr-6 bg-transparent hover:bg-customBlue"
        onClick={() => dispatch<any>(logout())}
        icon={<LoginOutlined />}
      >
        {userName}:&nbsp; <span style={{ color: colorBgTableHeader }}>{userRole}</span>
      </Button>
    </div>
  );
};
