import React, { useMemo } from 'react';
import { Button, Divider, Popconfirm } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  QuestionOutlined,
  RobotOutlined,
  StopOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import cn from 'classnames';

import { Tooltip } from 'components/Tooltip';

import { OwnerLinkedByEnum } from 'modules/owners/types/IOwner';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { ActiveOrder } from 'modules/orders/store/activeOrders/interfaces/activeOrder.interface';
import { ChangeStatusOfActiveOrderStatusesEnum } from 'modules/orders/enums/changeStatusOfActiveOrderStatuses.enum';
import { Order } from 'modules/orders/types/order.interface';
import { OrderTypes } from 'modules/orders/enums/orderTypes.enum';
import { useBindOperator } from 'modules/orders/pages/active/components/RowActions/components/BindOperatorToOrder/hooks/useBindOperator';
import { OrderAssigningStatus } from 'modules/orders/enums/orderAssigningStatus.enum';
import { BindOperatorToOrder } from 'modules/orders/pages/active/components/RowActions/components/BindOperatorToOrder';

interface Props {
  order: ActiveOrder;
  onChangeStatusPress: (status: ChangeStatusOfActiveOrderStatusesEnum, id: number) => void;
  onUnknownOrderPress: (order: ActiveOrder) => void;
  onAssignOwnerForOrderPress: (order: ActiveOrder) => void;
  onCommissionSetForOrderPress: (order: Order, isPartly: boolean) => void;
  onDisableAutoAssignmentPress: (order: ActiveOrder) => void;
  onConfirmOutcommingPress: (order: ActiveOrder) => void;
}

export const ActiveOrdersPageTableRowActions: React.FC<Props> = ({
  order,
  onChangeStatusPress,
  onUnknownOrderPress,
  onAssignOwnerForOrderPress,
  onDisableAutoAssignmentPress,
  onConfirmOutcommingPress,
}) => {
  const isOutcomingOrder = order.type === OrderTypes.OUTCOMING;
  const isIncomingOrder = order.type === OrderTypes.INCOMING;
  const hasAssignedOwners = order.owners.length !== 0;
  const isAutoAssigned =
    hasAssignedOwners && order.owners.some((owner) => owner.linkedBy === OwnerLinkedByEnum.OUT_AUTO_ASSIGMENT);

  const { user } = useAppSelector((state) => state.auth);

  const [bindOperatorCheck] = useBindOperator();
  // проверка нужна только для операторов, которые работают с исходящими заявками

  const hasTransferred = useMemo(() => {
    return (order.owners?.length ?? 0) !== 0;
  }, [order.owners?.length]);

  const assignOwnerIcon = useMemo(() => {
    if (isAutoAssigned) {
      return <RobotOutlined className="text-green-600" />;
    }

    if (hasAssignedOwners) {
      return <UserSwitchOutlined className="text-green-600" />;
    }

    return <UserAddOutlined />;
  }, [hasAssignedOwners, isAutoAssigned]);

  const isOnlyManualAssigned = order.assigningStatus === OrderAssigningStatus.ONLY_MANUAL_ASSIGNING;
  const isNotBoundToSystem = order.linkedOperatorId !== 1; // 1 - ID системы
  const bindStatus = order.linkedOperatorId !== user?.id && isNotBoundToSystem;

  if (isOutcomingOrder && bindStatus && isOnlyManualAssigned) {
    return <BindOperatorToOrder orderId={order.id} linkedOperatorId={order.linkedOperatorId} />;
  }

  return (
    <div className="flex flex-row items-center">
      {isOutcomingOrder && (
        <>
          {hasTransferred && order.sumTransferred && (
            <ProtectedComponent requiredPermissions={[permissions.order.update]}>
              <Tooltip title="Выполнить" className="mr-1">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<CheckOutlined />}
                  onClick={() => onConfirmOutcommingPress(order)}
                />
              </Tooltip>
            </ProtectedComponent>
          )}
        </>
      )}
      {isIncomingOrder && (
        <ProtectedComponent requiredPermissions={[permissions.order.update]}>
          <Popconfirm
            title="Вы действительно хотите пометить транзакцию как полученную?"
            okText="Да"
            cancelText="Нет"
            onConfirm={() => {
              onChangeStatusPress(ChangeStatusOfActiveOrderStatusesEnum.DONE, order.id);
            }}
          >
            <Tooltip title="Выполнено/Получено" className="mr-1">
              <Button type="primary" shape="circle" icon={<CheckOutlined />} />
            </Tooltip>
          </Popconfirm>
        </ProtectedComponent>
      )}
      {isOutcomingOrder && (
        <>
          <Divider type="vertical" />
          <Tooltip title={hasAssignedOwners ? 'Редактировать владельца' : 'Привязать владельца'}>
            <Button
              type={hasAssignedOwners ? 'link' : 'primary'}
              className={cn(hasAssignedOwners && 'border-green-600 hover:border-green-600 focus:border-green-600')}
              shape="circle"
              icon={assignOwnerIcon}
              onClick={async () => {
                await bindOperatorCheck(order.id, onAssignOwnerForOrderPress, [order]);
              }}
              disabled={order.assigningStatus !== OrderAssigningStatus.ONLY_MANUAL_ASSIGNING}
            />
          </Tooltip>
          {order.assigningStatus === OrderAssigningStatus.TURNING_OFF_AUTO_ASSIGNING_ALLOWED && (
            <Tooltip title="Отключить автоматическую привязку">
              <Button
                type="primary"
                danger
                className="ml-1"
                shape="circle"
                icon={<StopOutlined />}
                onClick={() => onDisableAutoAssignmentPress(order)}
              />
            </Tooltip>
          )}
        </>
      )}
      {isIncomingOrder && (
        <ProtectedComponent requiredPermissions={[permissions.order.update]}>
          <>
            <Divider type="vertical" />
            <Tooltip title="Неизвестный платеж">
              <Button
                shape="circle"
                icon={<QuestionOutlined />}
                onClick={() => {
                  onUnknownOrderPress(order);
                }}
              />
            </Tooltip>
          </>
        </ProtectedComponent>
      )}
      <Divider type="vertical" />
      <ProtectedComponent requiredPermissions={[permissions.processingOrder.cancel]}>
        <Popconfirm
          title="Вы действительно хотите пометить транзакцию как не выполненную/не полученную?"
          okText="Да"
          cancelText="Нет"
          placement="topRight"
          onConfirm={async () => {
            if (isOutcomingOrder) {
              await bindOperatorCheck(order.id, onChangeStatusPress, [
                ChangeStatusOfActiveOrderStatusesEnum.UNDONE,
                order.id,
              ]);
            } else {
              onChangeStatusPress(ChangeStatusOfActiveOrderStatusesEnum.UNDONE, order.id);
            }
          }}
        >
          <Tooltip title="Не выполнено/Не получено">
            <Button type="primary" danger shape="circle" icon={<CloseOutlined />} />
          </Tooltip>
        </Popconfirm>
      </ProtectedComponent>
    </div>
  );
};
