import React, { useState } from 'react';

import { ArrowUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { useOnScroll } from 'hooks/useOnScroll';

export const ScrollToTopButton: React.FC = () => {
  const [visible, setVisible] = useState(false);

  function toggleVisible() {
    const scrolled = document.documentElement.scrollTop;

    setVisible(scrolled > 300);
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  useOnScroll(toggleVisible);

  return (
    <div className="fixed bottom-0" style={{ zIndex: 10, marginBottom: '110px', marginLeft: '-94px' }}>
      <Button
        onClick={scrollToTop}
        style={{ display: visible ? 'inline' : 'none' }}
        type="primary"
        size="large"
        shape="circle"
        icon={<ArrowUpOutlined />}
      />
    </div>
  );
};
