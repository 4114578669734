import React, { useMemo } from 'react';
import { Statistic } from 'antd';

interface Props {
  balanceActual?: number;
  trustLimit: number;
}

export const OwnersPageDataViewAcceptableBalanceColumn: React.FC<Props> = ({ balanceActual, trustLimit }) => {
  const color = useMemo<string>(() => {
    if (!balanceActual) return 'green';

    const acceptableBalance = 100 - (balanceActual / trustLimit) * 100;

    if (acceptableBalance >= 50) {
      return 'green';
    } else if (acceptableBalance >= 11) {
      return 'orange';
    } else {
      return 'red';
    }
  }, [balanceActual, trustLimit]);

  const acceptableValue = useMemo<string>(
    () => (balanceActual ? (trustLimit - balanceActual).toFixed(0) : trustLimit.toFixed(0)),
    [balanceActual, trustLimit]
  );

  return (
    <Statistic
      valueStyle={{
        fontSize: '14px',
        fontWeight: 'unset',
        color,
      }}
      value={acceptableValue}
      groupSeparator={' '}
    />
  );
};
