import React, { useEffect, useMemo, useState } from 'react';
import { Alert, App, Button, Modal, Spin } from 'antd';
import { captureException } from '@sentry/react';

import instance from 'utils/axios';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { ActiveOrder } from 'modules/orders/store/activeOrders/interfaces/activeOrder.interface';
import { bindOwnersToOrder, updateOwnersBindingsToOrder } from 'modules/orders/store/activeOrders/actions';
import { SelectedOwner } from 'modules/orders/components/AssignOwnersToOrderForm/interfaces/selectedOwner.interface';
import { AssignOwnersToOrderForm } from 'modules/orders/components/AssignOwnersToOrderForm/AssignOwnersToOrderForm';

interface Props {
  order: ActiveOrder | undefined;
  onCancel: () => void;
}

export const ActiveOrdersPageAssignOwnerModal: React.FC<Props> = ({ order, onCancel }) => {
  const [selectedOwners, setSelectedOwners] = useState<SelectedOwner[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { message, modal } = App.useApp();

  const isInEditMode = useMemo(() => order?.owners.length !== 0, [order]);

  const dispatch = useAppDispatch();

  const isChanged = useMemo(() => {
    const bindedOwners = order?.owners ?? [];

    if (bindedOwners.length !== selectedOwners.length) return true;

    return selectedOwners.some(({ amount, bankAccountId }) => {
      const bindedOwner = bindedOwners.find(
        ({ bankAccountId: bindedBankAccountId }) => bindedBankAccountId === bankAccountId
      );

      if (!bindedOwner) return true;

      return bindedOwner.amount !== amount;
    });
  }, [order, selectedOwners]);

  useEffect(() => {
    if (!order || order.owners.length === 0) {
      setSelectedOwners([]);

      return;
    }

    setSelectedOwners(
      order.owners.map((bindedOwner) => ({
        ownerId: bindedOwner.ownerId,
        amount: bindedOwner.amount,
        bankAccountId: bindedOwner.bankAccountId ?? 0,
        bankId: bindedOwner.bankId,
        bankAccountNumber: bindedOwner.bankAccountNumber,
      }))
    );
  }, [order]);

  async function handleSubmit() {
    if (!order) return;

    const currentTotalSum = selectedOwners.reduce((previousValue, owner) => previousValue + owner.amount, 0);

    if (selectedOwners.length > 1 && currentTotalSum !== order.amount) {
      message.error('Общая сумма привязки не соответствует сумме из транзакции');

      return;
    }

    setIsLoading(true);

    try {
      if (isInEditMode) {
        await processOwnerChanging();
      } else {
        await processOwnerBinding();
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function processOwnerBinding(skip: boolean = false): Promise<void> {
    if (!order || errors.length !== 0) return;

    if (selectedOwners.length === 0) {
      message.error('Не выбраны владельцы для привязки');

      return;
    }

    const ownersWithOrders = skip ? [] : await ownersWithActiveOrders();

    if (ownersWithOrders.length !== 0) {
      modal.confirm({
        title: 'Вы уверены, что хотите продолжить привязку?',
        content: `У некоторых выбранных владельцев уже есть активные заявки: ${ownersWithOrders.join(', ')}`,
        onOk: () => processOwnerBinding(true),
        okText: 'Привязать',
        cancelText: 'Отмена',
      });

      return;
    }

    const ownersToBind = selectedOwners.map(({ ownerId, amount, bankAccountId }) => ({
      ownerId,
      sum: (selectedOwners.length === 1 ? order.amount : amount) ?? 0,
      bankAccountId: bankAccountId,
    }));

    try {
      await dispatch(bindOwnersToOrder(order.id, ownersToBind));

      message.success('Владельцы успешно привязаны!');
    } catch {
      message.error('Не удалось привязать владельцев');
    }

    clearState();
  }

  async function processOwnerChanging(): Promise<void> {
    if (!order) return;

    await dispatch(updateOwnersBindingsToOrder(order, selectedOwners));

    message.success('Привязанные владельцы успешно изменены!');

    clearState();
  }

  function clearState(): void {
    setSelectedOwners([]);
    setIsLoading(false);
    onCancel();
  }

  async function ownersWithActiveOrders(): Promise<number[]> {
    try {
      const ownersWithActiveOrders: number[] = [];

      for (const owner of selectedOwners) {
        const {
          data: { data },
        } = await instance.get(`/owners/${owner.ownerId}/has-active-out-orders`);

        if (!data) continue;

        ownersWithActiveOrders.push(owner.ownerId);
      }

      return ownersWithActiveOrders;
    } catch (err) {
      captureException(err);

      message.error('Не удалось проверить наличие активной заявки у владельца');

      return [];
    }
  }

  let content;
  const ownersWithoutBankAccount = selectedOwners.filter((owner) => !owner.bankId || !owner.bankAccountId);

  if (ownersWithoutBankAccount.length !== 0) {
    content = (
      <Alert
        message="Перепривязка владельцев запрещена"
        description={`У владельцев ${ownersWithoutBankAccount
          .map((owner) => '№' + owner.ownerId)
          .join(', ')} нет банковского счета`}
        type="error"
      />
    );
  } else {
    content = (
      <Spin tip={`Идет ${isInEditMode ? 'пере' : ''}привязка владельца...`} spinning={isLoading} size="large">
        <AssignOwnersToOrderForm
          order={order}
          onChangeErrors={setErrors}
          onChangeSelectedOwners={setSelectedOwners}
          selectedOwners={selectedOwners}
        />
      </Spin>
    );
  }

  return (
    <Modal
      open={!!order}
      width={700}
      title={
        <>
          {isInEditMode ? 'Редактирование привязки' : 'Привязка'} владельца к заявке
          <br />
          {order?.customerTransactionId}
        </>
      }
      onCancel={clearState}
      footer={[
        ownersWithoutBankAccount.length === 0 ? (
          <Button
            type="primary"
            className="w-full"
            onClick={handleSubmit}
            key=""
            disabled={isLoading || (isInEditMode && !isChanged) || errors.length !== 0}
          >
            Сохранить
          </Button>
        ) : null,
      ]}
    >
      {content}
    </Modal>
  );
};
