import React from 'react';
import { Button } from 'antd';

import { IOrderDispute } from 'modules/disputes/types/IOrderDispute';

interface Props {
  dispute: IOrderDispute;
  onShowSmsModal: (dispute: IOrderDispute) => void;
}

export const OrderDisputesPageTableActionsColumn: React.FC<Props> = ({ dispute, onShowSmsModal }) => {
  return (
    <div className="flex flex-row px-4">
      <Button
        type="default"
        shape="circle"
        className="bg-green-600 border-green-600 hover:bg-green-500"
        onClick={() => onShowSmsModal(dispute)}
        style={{ color: 'white', border: 'none' }}
      >
        <p style={{ fontSize: '10px' }} className="font-bold ">
          СМС
        </p>
      </Button>
    </div>
  );
};
