export enum RequisiteTypeEnum {
  ACCOUNT = 'account',
  CARD = 'card',
  PHONE = 'phone',
}

export const requisiteTypesToTitlesMap: Record<RequisiteTypeEnum, string> = {
  [RequisiteTypeEnum.ACCOUNT]: 'Счет',
  [RequisiteTypeEnum.CARD]: 'Карта',
  [RequisiteTypeEnum.PHONE]: 'Телефон',
};
