import React, { useEffect } from 'react';
import { App, Table } from 'antd';

import { useGoipsTableColumns } from 'modules/goips/pages/list/components/Table/columns';
import { GoipsPageChannelTable } from 'modules/goips/pages/list/components/Table/components/channelTable';

import { Goip } from 'modules/goips/store/goips/types';
import { getGoips } from 'modules/goips/store/goips/action';

import api from 'utils/axios';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';

interface Props {}

export const GoipsPageTable: React.FC<Props> = () => {
  const columns = useGoipsTableColumns({ disableOwners });

  const dispatch = useAppDispatch();

  const { message } = App.useApp();

  const { goips, isLoading } = useAppSelector((state) => state.goips);

  useEffect(() => {
    dispatch<any>(getGoips());
  }, []);

  async function disableOwners(goipName: string): Promise<void> {
    await api.post('goip/owners/disable', { goipName });

    message.error(`Владельцы шлюза ${goipName} были отключены`);

    dispatch<any>(getGoips());
  }

  return (
    <Table
      size="small"
      dataSource={goips}
      columns={columns}
      rowKey={'id'}
      pagination={false}
      loading={isLoading}
      sticky={{ offsetHeader: 64 }}
      scroll={{ y: 'calc(100vh - 170px)' }}
      expandable={{
        expandedRowRender: (goip: Goip) => {
          return <GoipsPageChannelTable data={goip.channels} />;
        },
        rowExpandable: ({ channels }) => channels && channels.length > 0,
      }}
    />
  );
};
