import { Dispatch } from 'redux';

import {
  IFetchUsersParams,
  IFetchUsersReducerAction,
  UsersActionsTypes,
  ISetIsLoadingReducerAction,
  ISetPaginationReducerAction,
} from 'modules/users/store/users/types';

import instance from 'utils/axios';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';

export function fetchUsers(params: IFetchUsersParams & RequestParamsWithPagination) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setIsLoading(true));

      const {
        data: { data, meta },
      } = await instance.get('/users/list', { params });

      dispatch<IFetchUsersReducerAction>({
        type: UsersActionsTypes.FETCH_USERS,
        payload: {
          users: data,
          pagination: meta,
        },
      });
    } finally {
      dispatch<any>(setIsLoading(false));
    }
  };
}

export function setIsLoading(value: boolean) {
  return (dispatch: Dispatch) => {
    dispatch<ISetIsLoadingReducerAction>({
      type: UsersActionsTypes.SET_IS_LOADING,
      payload: value,
    });
  };
}

export function setPagination(page: number, perPage: number) {
  return (dispatch: Dispatch) => {
    dispatch<ISetPaginationReducerAction>({
      type: UsersActionsTypes.SET_PAGINATION,
      payload: { page, perPage },
    });
  };
}
