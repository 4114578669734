import React, { useState } from 'react';
import { Modal } from 'antd';

interface Props {
  src: string;
  width: number;
  height: number;
}

export const PdfPreview: React.FC<Props> = ({ src, width, height }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  function handlePreviewClick() {
    setIsModalVisible(true);
  }

  function handlePreviewClose() {
    setIsModalVisible(false);
  }

  return (
    <>
      <Modal
        open={isModalVisible}
        onCancel={handlePreviewClose}
        className="h-full"
        wrapClassName="h-full"
        footer={null}
        bodyStyle={{ height: '70vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <iframe src={src} width={400} height="100%" />
      </Modal>
      <div className="relative" style={{ width, height }}>
        <iframe src={src} width={width} height={height} onClick={handlePreviewClick} />
        <div
          className="opacity-0 hover:opacity-100 cursor-pointer text-white flex items-center justify-center absolute top-0 left-0 z-10"
          style={{
            width,
            height,
            background: '#000000aa',
          }}
          onClick={handlePreviewClick}
        >
          Открыть
        </div>
      </div>
    </>
  );
};
