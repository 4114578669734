import { IAuthState, AuthReducerActions, AuthReducerActionTypes, ILoginPayload } from 'modules/auth/store/auth/types';
import { User } from 'modules/users/types/user.interface';

// Суффикс для ключей. Нужен, чтобы сбрасывать при необходимости авторизацию у пользователей после
// деплоя нового релиза.
// Если необходимо, чтобы после деплоя у пользователей сбросилась авторизация,
// то нужно изменить значение суффикса.
const SUFFIX = 2;

export const PRESERVED_AUTH_STATE_KEY = 'preserved-auth-state-' + SUFFIX;
export const PRESERVED_USER_TOKEN_KEY = 'userToken-' + SUFFIX;

const initialState: IAuthState = {
  isAuth: false,
  token: undefined,
  user: undefined,
};

const preservedAuthState = localStorage.getItem(PRESERVED_AUTH_STATE_KEY);

export function auth(
  state = preservedAuthState ? (JSON.parse(preservedAuthState) as IAuthState) : initialState,
  { type, payload }: AuthReducerActions
): IAuthState {
  switch (type) {
    case AuthReducerActionTypes.LOGIN: {
      const { access_token, user } = payload as ILoginPayload;

      const newState: IAuthState = { isAuth: true, token: access_token, user };

      localStorage.setItem(PRESERVED_AUTH_STATE_KEY, JSON.stringify(newState));
      localStorage.setItem(PRESERVED_USER_TOKEN_KEY, access_token);

      return newState;
    }
    case AuthReducerActionTypes.REFRESH_ME: {
      const me = payload as User;

      const updatedState: IAuthState = {
        ...state,
        user: me,
      };

      localStorage.setItem(PRESERVED_AUTH_STATE_KEY, JSON.stringify(updatedState));

      return updatedState;
    }
    case AuthReducerActionTypes.LOGOUT:
      localStorage.removeItem(PRESERVED_AUTH_STATE_KEY);
      localStorage.removeItem(PRESERVED_USER_TOKEN_KEY);

      return initialState;
    default:
      return state;
  }
}
