import instance from 'utils/axios';
import { Report } from '../types/report.interface';
import { IMetaPagination } from 'types/IMetaPagination';

interface FetchReportRequestParams {
  perPage: number;
  page: number;
}

interface FetchReportsResponse {
  data: Report[];
  meta: IMetaPagination;
}

export async function fetchReportsRequest(params: FetchReportRequestParams): Promise<FetchReportsResponse> {
  const { data } = await instance.get('/reports', { params });

  return data;
}
