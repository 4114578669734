import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';
import { useMerchantsFromServer, useBanksFromServer } from 'hooks/useDataFromServer';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'Клиент',
    name: 'merchantId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useMerchantsFromServer,
    cacheKey: 'customers-select',
  },
  {
    title: 'Провайдер',
    name: 'bankId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useBanksFromServer,
    cacheKey: 'providers-select',
  },
];
