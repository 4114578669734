import {
  IOwnersState,
  OwnerReducerActions,
  OwnersActionTypes,
  IFetchOwnersPayload,
  IChangePagePayload,
  UpdaterOwnerFieldPayload,
  IEditOwnerBalanceReducerPayload,
} from 'modules/owners/store/owners/types';

import { IOwner, IOwnerCommission } from 'modules/owners/types/IOwner';

const initialState: IOwnersState = {
  owners: [],
  isLoading: false,
  pagination: {
    page: 1,
    pages: 1,
    perPage: 20,
    total: 0,
  },
};

export function ownersReducer(state = initialState, { type, payload }: OwnerReducerActions): IOwnersState {
  switch (type) {
    case OwnersActionTypes.FETCH_OWNERS: {
      const { pagination, owners } = payload as IFetchOwnersPayload;

      return { ...state, owners, isLoading: false, pagination };
    }
    case OwnersActionTypes.SET_LOADING:
      return { ...state, isLoading: payload as boolean };
    case OwnersActionTypes.CREATE_OWNER:
      return {
        ...state,
        owners: [payload, ...state.owners],
        isLoading: false,
        pagination: { ...state.pagination, page: 1 },
      };

    case OwnersActionTypes.EDIT_OWNER: {
      const editedOwner = payload as IOwner;
      return {
        ...state,
        owners: state.owners.map((owner) => (owner.ownerId === editedOwner.ownerId ? editedOwner : owner)),
        isLoading: false,
      };
    }
    case OwnersActionTypes.UPDATE_OWNER_FIELD: {
      const updatedData = payload as UpdaterOwnerFieldPayload;
      return {
        ...state,
        owners: state.owners.map((owner) =>
          owner.ownerId === updatedData.ownerId ? { ...owner, ...updatedData } : owner
        ),
        isLoading: false,
      };
    }
    case OwnersActionTypes.EDIT_OWNER_BALANCE: {
      const { ownerId, balanceReal } = payload as IEditOwnerBalanceReducerPayload;

      return {
        ...state,
        owners: state.owners.map((owner) =>
          owner.ownerId === ownerId ? { ...owner, balanceActual: balanceReal } : owner
        ),
        isLoading: false,
      };
    }

    case OwnersActionTypes.DELETE_OWNER: {
      const deletedOwnerId = payload as number;
      return {
        ...state,
        owners: [...state.owners.filter((owner) => owner.ownerId !== deletedOwnerId)],
        isLoading: false,
        pagination: { ...state.pagination, total: state.pagination.total - 1 },
      };
    }
    case OwnersActionTypes.CHANGE_PAGE: {
      const { page, perPage } = payload as IChangePagePayload;

      return {
        ...state,
        pagination: {
          ...state.pagination,
          page,
          perPage,
        },
      };
    }
    case OwnersActionTypes.CLEAR_OWNERS:
      return { ...state, owners: [], pagination: { ...state.pagination, page: 1, perPage: 20 }, isLoading: false };
    case OwnersActionTypes.UPDATE_OWNERS: {
      const updatedOwners = payload as IOwner[];
      const mergedOwners = state.owners.map((i) => {
        const findedOwner = updatedOwners.find((x) => x.ownerId === i.ownerId);

        if (findedOwner) return findedOwner;

        return i;
      });

      return { ...state, isLoading: false, owners: mergedOwners };
    }
    case OwnersActionTypes.UPDATE_OWNER_COMMISSIONS: {
      const { ownerId, commissions } = payload as unknown as {
        ownerId: number;
        commissions: IOwnerCommission[];
      };

      return {
        ...state,
        isLoading: false,
        owners: [
          ...state.owners.map((owner) => {
            if (owner.ownerId === ownerId) {
              owner.ownerCommissions = commissions;
              return owner;
            } else {
              return owner;
            }
          }),
        ],
      };
    }
    default:
      return state;
  }
}
