import { GoipsActionTypes, GoipsReducerActions, IGoipState } from 'modules/goips/store/goips/types';

const initialState: IGoipState = {
  goips: [],
  isLoading: false,
};

export function goipsReducer(state: IGoipState = initialState, action: GoipsReducerActions) {
  switch (action.type) {
    case GoipsActionTypes.GET_GOIPS: {
      return { ...state, goips: action.payload.goips };
    }
    case GoipsActionTypes.SET_IS_LOADING: {
      return { ...state, isLoading: action.payload };
    }
    default: {
      return state;
    }
  }
}
