import React from 'react';
import { Button, Divider, Popconfirm, Space } from 'antd';
import { CloseOutlined, CreditCardOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';

import { ProtectedComponent } from 'policies/components/ProtectedComponent';

import { Tooltip } from 'components/Tooltip';
import { permissions } from 'policies/permissions';

interface Props {
  onEditPress: () => void;
  onCardPress: () => void;
  onDeletePress: () => void;
  onAssignToCustomerPress: () => void;
}

export const OwnersPageTableRowActions: React.FC<Props> = ({
  onAssignToCustomerPress,
  onEditPress,
  onDeletePress,
  onCardPress,
}) => {
  return (
    <Space size="small">
      <ProtectedComponent requiredPermissions={[permissions.owner.view]}>
        <Tooltip title="Редактировать владельца">
          <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={onEditPress} />
        </Tooltip>
      </ProtectedComponent>
      <ProtectedComponent requiredPermissions={[permissions.owner.delete]}>
        <Popconfirm cancelText="Отмена" title="Вы уверенны, что хотите удалить владельца?" onConfirm={onDeletePress}>
          <Button type="primary" danger shape="circle" icon={<CloseOutlined />} />
        </Popconfirm>
      </ProtectedComponent>
      <Divider type="vertical" className="m-0" />
      <ProtectedComponent requiredPermissions={[permissions.owner.merchantLink.update]}>
        <Tooltip title="Привязать к клиенту">
          <Button type="primary" shape="circle" icon={<LinkOutlined />} onClick={onAssignToCustomerPress} />
        </Tooltip>
      </ProtectedComponent>
      <ProtectedComponent requiredPermissions={[permissions.card.view]}>
        <Tooltip title="Перейти к картам владельца">
          <Button shape="circle" icon={<CreditCardOutlined />} onClick={onCardPress} />
        </Tooltip>
      </ProtectedComponent>
    </Space>
  );
};
