import useSWRMutation from 'swr/mutation';
import { enableBankRequest } from 'modules/banks/api/enableBank.request';
import { disableBankRequest } from 'modules/banks/api/disableBank.request';

interface UseToggleBankRequestParams {
  id: number;
  enabled: boolean;
}

export function useToggleBankRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'toggle-bank-request',
    async (
      _,
      {
        arg,
      }: {
        arg: UseToggleBankRequestParams;
      }
    ) => {
      if (arg.enabled) {
        await enableBankRequest(arg.id);
      } else {
        await disableBankRequest(arg.id);
      }
    }
  );

  return { toggleBank: trigger, isTogglingBank: isMutating };
}
