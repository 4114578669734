import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { UpdateMessageBanner } from './components/UpdateMessageBanner';

const UPDATE_CHECK_INTERVAL = 10 * 60 * 1000;

async function fetchBuildDate(): Promise<string> {
  return await (
    await axios.get('/build-meta.json')
  ).data?.buildDate;
}

export const UpdateMessage: React.FC = () => {
  const [isNewBuildAvailable, setIsNewBuildAvailable] = useState(false);
  const initBuildTime = useRef<string>();
  const checkIntervalRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    fetchInitBuildDate();

    checkIntervalRef.current = setInterval(checkNewBuild, UPDATE_CHECK_INTERVAL);

    return () => {
      clearInterval(checkIntervalRef.current);
    };
  }, []);

  async function fetchInitBuildDate() {
    initBuildTime.current = await fetchBuildDate();
  }

  async function checkNewBuild() {
    const buildDate = await fetchBuildDate();

    setIsNewBuildAvailable(buildDate !== initBuildTime.current);
  }

  return (
    <>
      {isNewBuildAvailable && (
        <UpdateMessageBanner
          onCloseClick={() => setIsNewBuildAvailable(false)}
          onReloadClick={() => location.reload()}
        />
      )}
    </>
  );
};
