import React from 'react';
import { CheckOutlined, CloseOutlined, WarningOutlined } from '@ant-design/icons';

import { StatusesEnum } from './StatusesEnum';

interface Props {
  status: StatusesEnum;
}

/**
 * Отображение статуса: положительного или отрицательного.
 *
 * @author Рябущиц Иван <telegram: @ivangeli0n>
 */
export const Status: React.FC<Props> = ({ status }) => {
  switch (status) {
    case StatusesEnum.ERROR: {
      return <CloseOutlined className="text-red-700" />;
    }
    case StatusesEnum.SUCCESS: {
      return <CheckOutlined className="text-green-700" style={{ color: '#30AB1C' }} />;
    }
    case StatusesEnum.WARNING: {
      return <WarningOutlined className="text-yellow-700" />;
    }
    default: {
      return null;
    }
  }
};
