import { App, Button, Form, Input, Popconfirm } from 'antd';
import React, { useEffect } from 'react';
import { OrdersPageEditModalInOrderOwnerSelect } from '../InOrderOwnerSelect';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { Order } from 'modules/orders/types/order.interface';
import { editOrder } from 'modules/orders/store/orders/actions';
import { CommonFields } from 'modules/orders/pages/list/components/EditModal/OrdersPageEditModal';

interface Props {
  order?: Order;
  onSubmit: () => CommonFields;
  onClose: () => void;
}

export const OrdersPageEditModalInOrder: React.FC<Props> = ({ order, onSubmit, onClose }) => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();
  const { message } = App.useApp();

  useEffect(() => {
    if (!order) {
      form.resetFields();

      return;
    }

    form.setFieldsValue({
      ownerId: order.owners[0].ownerId,
      cardId: order.owners[0].cardId,
      bankAccountId: order.owners[0].bankAccountId,
      realSum: order.realSum,
    });
  }, [order]);

  async function handleSubmit() {
    if (!order) return;

    const params = { ...form.getFieldsValue(), ...onSubmit() };

    params.owners = [
      {
        ownerId: params.ownerId ?? order.owners[0].ownerId ?? undefined,
        amount: params.realSum,
        cardId: params.cardId ?? order.owners[0]?.cardId,
        bankAccountId: params.bankAccountId ?? order.owners[0]?.bankAccountId,
        requisitesType: order.owners[0].requisitesType,
      },
    ];

    if (params.realCustomerName === '') {
      delete params.realCustomerName;
    }

    delete params.ownerId;
    delete params.cardId;
    delete params.bankAccountId;
    delete params.requisitesType;

    try {
      await dispatch(editOrder(params));

      message.success('Транзакция успешно изменена!');
    } catch {
      message.error('Ошибка при сохранении изменений в транзакции');
    } finally {
      onClose();
    }
  }

  if (!order) return null;

  return (
    <Form form={form}>
      <Form.Item name="realCustomerName" label="Реальное имя клиента">
        <Input />
      </Form.Item>
      <OrdersPageEditModalInOrderOwnerSelect
        order={order}
        ownerId={order.owners[0]?.ownerId}
        initialRequisitesType={order.owners[0]?.requisitesType}
        onRequisitesChange={(bankAccountId, cardId?: number) => {
          form.setFieldValue('bankAccountId', bankAccountId);

          if (cardId) {
            form.setFieldValue('cardId', cardId);
          }
        }}
      />
      <Popconfirm cancelText="Отмена" title="Вы уверенны, что хотите сохранить изменения?" onConfirm={handleSubmit}>
        <Button className="w-full mt-4" type="primary">
          Сохранить
        </Button>
      </Popconfirm>
    </Form>
  );
};
