import React from 'react';

interface Props {
  children: React.ReactNode;

  onEnterPress: () => void;
}

/**
 * Компонент для обработки Enter.
 *
 * @author Рябущиц Иван <telegram: @ivangeli0n>
 */
export const EnterPressHandlerProvider: React.FC<Props> = ({ children, onEnterPress }) => {
  /**
   * Обработчик нажатия клавиши Enter
   *
   * @author Рябущиц Иван <telegram: @ivangeli0>
   */
  function onEnterHandler(e: React.BaseSyntheticEvent) {
    const event = e.nativeEvent as KeyboardEvent;
    if (event.code === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onEnterPress();
    }
  }

  return <div onKeyDown={onEnterHandler}>{children}</div>;
};
