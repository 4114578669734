export function formatPhoneNumber(phoneNumber: string): string {
  let formattedNumber = phoneNumber.replace(/[-\s]/g, '').split('').reverse().join('');

  if (formattedNumber.length > 2 && formattedNumber.length > 4) {
    formattedNumber = `${formattedNumber.slice(0, 2)}-${formattedNumber.slice(2, 4)}-${formattedNumber.slice(4)}`;
  }

  if (formattedNumber.length > 5) {
    formattedNumber = formattedNumber.replace(/(.{3})/g, '$1 ');
  }

  formattedNumber = formattedNumber
    .replace(/(-\s+)/g, '-')
    .split('')
    .reverse()
    .join('')
    .trim();

  return formattedNumber;
}
