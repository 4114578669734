import { Pagination as BasePagination } from 'antd';
import { IMetaPagination } from 'types/IMetaPagination';
import React from 'react';

interface Props {
  pagination: IMetaPagination;
  isLoading: boolean;
  customStyle?: string;
  onPaginationChange: (page: number, perPage: number) => void;
}

export const Pagination: React.FC<Props> = ({ pagination, isLoading, onPaginationChange, customStyle }) => {
  return (
    <BasePagination
      className={customStyle}
      pageSize={pagination.perPage}
      current={pagination.page}
      total={pagination.total}
      locale={{ items_per_page: ' на странице' }}
      disabled={isLoading}
      showSizeChanger
      onChange={onPaginationChange}
      pageSizeOptions={['20', '50', '100', '500', '1000', '2000']}
    />
  );
};
