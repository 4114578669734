import { fetchCardsRequest, FetchCardsRequestParams } from 'modules/bankAccounts/api/fetchCards.request';
import useSWR from 'swr';

export function useFetchCardsRequest(params: FetchCardsRequestParams) {
  const { data, isLoading, mutate } = useSWR([params, 'fetch-cards-request'], ([params]) => fetchCardsRequest(params));

  return {
    cards: data,
    isFetchingCards: isLoading,
    fetchCards: mutate,
  };
}
