import React from 'react';
import { useNavigate } from 'react-router-dom';
import { App, Button } from 'antd';
import { Divider } from 'antd/es';

import { AuthReducerActionTypes } from 'modules/auth/store/auth/types';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';

export const NotAuthorizedPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { message } = App.useApp();

  function handleLogout() {
    dispatch({ type: AuthReducerActionTypes.LOGOUT });

    message.success('Вы успешно вышли из аккаунта. До свидания :)');
  }

  return (
    <div className="flex flex-col min-h-screen min-w-full justify-center items-center">
      <h1 className="font-bold text-3xl mb-5">
        У вас не хватает прав доступа
        <br />
        для просмотра этой страницы
      </h1>
      <Button type="primary" onClick={() => navigate('/')}>
        Перейти на главную
      </Button>
      <Divider />
      <Button type="primary" onClick={handleLogout}>
        Выйти
      </Button>
    </div>
  );
};
