import React from 'react';
import { Button, Form, Select } from 'antd';
import { Divider } from 'antd/es';
import { DeleteOutlined } from '@ant-design/icons';

import { useLogicChatsOptions } from './hooks/useLogicChatsOptions.hook';
import { useCurrenciesFromServer } from 'hooks/useDataFromServer';

import { InputNumber } from 'components/Input';
import { MerchantChat } from 'modules/merchants/types';

interface Props {
  customerChats: MerchantChat[];
  onCustomerChatsChange: (e: React.SetStateAction<MerchantChat[]>) => void;
}

export const MerchantChatsForm: React.FC<Props> = ({ customerChats, onCustomerChatsChange }) => {
  const [currencies] = useCurrenciesFromServer();

  const { currencySelectOptions, onCustomerChatChange, addChat, removeChat } = useLogicChatsOptions({
    chats: customerChats,
    onChatsChange: onCustomerChatsChange,
    currencies,
  });

  return (
    <>
      {customerChats.map((chat, index) => (
        <div className="flex flex-col gap-5 mb-5" key={'chats-list-form-' + index + chat.currencyId}>
          <div className="flex items-center">
            <label className="w-48">Валюта чата</label>
            <Select
              className="w-full"
              defaultValue={chat.currencyId}
              key={chat.currencyId + '_currency'}
              options={currencySelectOptions}
              allowClear
              onChange={(value: number) => onCustomerChatChange(index, 'currencyId', value)}
            />
            <div
              className="pl-3 cursor-pointer"
              onClick={() => {
                removeChat(index);
              }}
            >
              <DeleteOutlined className="text-red-600" size={16} />
            </div>
          </div>
          <div className="flex items-center">
            <label className="w-48">ID чата • Входящие</label>
            <InputNumber
              defaultValue={chat.incomingChatId}
              key={chat.incomingChatId + '_incoming'}
              className="w-full"
              onChange={(value) => onCustomerChatChange(index, 'incomingChatId', Number(value))}
            />
          </div>
          <div className="flex items-center">
            <label className="w-48">ID чата • Исходящие</label>
            <InputNumber
              defaultValue={chat.outcomingChatId}
              key={chat.outcomingChatId + '_outcoming'}
              className="w-full"
              onChange={(value) => onCustomerChatChange(index, 'outcomingChatId', Number(value))}
            />
          </div>
          <Divider style={{ margin: '6px 0' }} />
        </div>
      ))}
      <Form.Item>
        <Button className="w-full h-11" type="dashed" onClick={addChat}>
          Добавить еще чат с валютой
        </Button>
      </Form.Item>
    </>
  );
};
