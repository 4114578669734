import instance from 'utils/axios';
import { BankAccountCard } from 'modules/bankAccounts/types/bankAccountCard.interface';

export interface FetchCardsRequestParams {
  bankAccountId: number;
}

export async function fetchCardsRequest(params: FetchCardsRequestParams): Promise<BankAccountCard[]> {
  const {
    data: { data },
  } = await instance.get('/cards', {
    params,
  });

  return data;
}
