import React from 'react';
import { App } from 'antd';

import { IOrderDispute } from 'modules/disputes/types/IOrderDispute';

import { useUserPermissions } from 'policies/hooks/useUserPermissions';

import { updateOrderDisputeStatus, setBuffering } from 'modules/disputes/store/orderDisputes/actions';
import { permissions } from 'policies/permissions';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { LazySelect } from 'components/LazySelect';
import { useDisputeStatusesFromServer } from 'hooks/useDataFromServer';

interface Props {
  /** Диспут текущей строки */
  orderDispute: IOrderDispute;
}

export const OrderDisputesPageTableStatusColumn: React.FC<Props> = ({ orderDispute }) => {
  const dispatch = useAppDispatch();

  const { message } = App.useApp();

  const { userCan } = useUserPermissions();

  async function handleStatusChange(value: number) {
    try {
      const loadingMessageKey = 'update-dispute-status-message-' + orderDispute.id;

      message.open({
        content: 'Обновление статуса диспута №' + orderDispute.id,
        duration: 10,
        key: loadingMessageKey,
        type: 'loading',
      });

      await dispatch(updateOrderDisputeStatus(orderDispute.id, value));

      message.open({
        content: 'Статус успешно обновлен!',
        duration: 2,
        key: loadingMessageKey,
        type: 'success',
      });
    } catch {
      message.error('Не удалось обновить статус диспута');
    }
  }

  return (
    <LazySelect
      placeholder="Cтатус"
      value={orderDispute.status}
      className="w-28"
      onChange={handleStatusChange}
      disabled={!userCan(permissions.dispute.update)}
      onFocus={() => dispatch<any>(setBuffering(true))}
      useDataHook={useDisputeStatusesFromServer}
      cacheKey="dispute-status"
      fetchIfNoCache
    />
  );
};
