import useSWRMutation from 'swr/mutation';
import { enableOutcomingBankAccountRequest } from 'modules/bankAccounts/api/enableOutcomingBankAccount.request';

export function useEnableOutcomingBankAccountRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'enable-outcoming-bank-account',
    (
      _,
      {
        arg,
      }: {
        arg: number;
      }
    ) => enableOutcomingBankAccountRequest(arg)
  );

  return {
    enableOutcomingBankAccount: trigger,
    isEnablingOutcomingBankAccount: isMutating,
  };
}
