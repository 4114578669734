import useSWRMutation from 'swr/mutation';
import { revokeMerchantTokenRequest } from '../api/revokeMerchantToken.request';

export function useRevokeMerchantTokenRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'revoke-merchant-token-request',
    (_, { arg }: { arg: { merchantId: number; tokenId: number } }) =>
      revokeMerchantTokenRequest(arg.merchantId, arg.tokenId),
    { throwOnError: true }
  );

  return {
    revokeToken: trigger,
    isRevoking: isMutating,
  };
}
