import React, { useEffect, useState } from 'react';
import { App, Switch } from 'antd';
import { useEnableCardRequest } from 'modules/bankAccounts/hooks/useEnableCardRequest.hook';
import { useDisableCardRequest } from 'modules/bankAccounts/hooks/useDisableCardRequest.hook';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { permissions } from 'policies/permissions';

interface Props {
  checked: boolean;
  cardId: number;
  onChange: (status: boolean) => void;
}

export const CardEnabledSwitch: React.FC<Props> = ({ checked, onChange, cardId }) => {
  const [currentChecked, setCurrentChecked] = useState<boolean>(false);

  const { enableCard, isEnablingCard } = useEnableCardRequest();
  const { disableCard, isDisablingCard } = useDisableCardRequest();

  const { message } = App.useApp();

  const { userCanAll } = useUserPermissions();

  useEffect(() => {
    setCurrentChecked(checked);
  }, [checked]);

  async function handleChange(newChecked: boolean) {
    try {
      if (newChecked) {
        await enableCard(cardId);
      } else {
        disableCard(cardId);
      }

      message.success('Статус карты успешно изменен!');
      setCurrentChecked(newChecked);
      onChange(newChecked);
    } catch {
      message.error('Не удалось изменить статус карты');
    }
  }

  return (
    <Switch
      checked={currentChecked}
      onClick={handleChange}
      loading={isEnablingCard || isDisablingCard}
      disabled={!userCanAll(permissions.card.update)}
    />
  );
};
