import React, { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';

import { GoipPort, IGoipChannelAuthOnlineStatus, IGoipChannelLoginStatusEnum } from 'modules/goips/store/goips/types';
import { GoipChannelSignal } from 'modules/goips/pages/list/components/Table/components/GoipChannelSignal';
import { GoipLinkedOwner } from 'modules/goips/pages/list/components/Table/components/channelTable/components/GoipLinkedOwner';
import { formatPhoneNumber } from 'utils/formatPhoneNumber.util';
import { Status } from 'components/Status/Status';
import { StatusesEnum } from 'components/Status/StatusesEnum';

export interface Props {
  switchOwners: (ownerId: number, isActive: boolean) => void;
}

export function useGoipsChannelTableColumns(props: Props) {
  const { switchOwners } = props;

  return useMemo<ColumnsType<GoipPort>>(() => {
    return [
      {
        align: 'left',
        title: () => <div style={{ padding: '16px 8px 16px 0' }}>№ Порта</div>,
        dataIndex: 'channelId',
        key: 'channelId',
        width: '9%',
      },
      {
        align: 'left',
        title: 'Номера',
        dataIndex: 'mainPhone',
        key: 'mainPhone',
        width: '20%',
        render: (mainPhone: string, record: GoipPort) => {
          return (
            <>
              {mainPhone && formatPhoneNumber(mainPhone)}
              {record.additionalPhone && (
                <div style={{ color: '#888' }}>
                  {formatPhoneNumber(record.additionalPhone)} <span>(доп)</span>
                </div>
              )}
            </>
          );
        },
      },
      {
        align: 'left',
        title: 'Баланс',
        dataIndex: 'balance',
        key: 'balance',
        width: '13%',
        render: (balance: number) => {
          const balanceStyle = balance < 0 ? { color: 'red' } : {};
          return <div style={balanceStyle}> {balance ? balance.toLocaleString('ru-RU') + ' ₽' : ''}</div>;
        },
      },
      {
        align: 'center',
        title: 'Сигнал',
        dataIndex: 'signal',
        key: 'signal',
        width: '8%',
        render: (signal: number) => {
          return <GoipChannelSignal signal={signal} />;
        },
      },
      {
        align: 'center',
        title: 'Статус',
        dataIndex: 'login',
        width: '7%',
        render: (login: IGoipChannelLoginStatusEnum) => {
          const status = IGoipChannelAuthOnlineStatus.includes(login) ? StatusesEnum.SUCCESS : StatusesEnum.ERROR;

          return <Status status={status} />;
        },
      },
      {
        align: 'right',
        title: 'Привязанные владельцы',
        width: '43%',
        render: ({ owner }: GoipPort) =>
          owner && (
            <GoipLinkedOwner
              owner={owner}
              switchOwnerStatus={() => {
                switchOwners(owner.id, !owner.isActive);
              }}
            />
          ),
      },
    ];
  }, []);
}
