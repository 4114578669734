import React, { useState } from 'react';

import { changePagination, fetchAgentsThunk, fetchMoreAgents, resetAgents } from 'modules/agents/store/agents';
import { IFetchAgentsParams } from 'modules/agents/store/agents/interfaces/fetchAgentsParams.interface';

import { UserAddOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';

import { ProtectedComponent } from 'policies/components/ProtectedComponent';

import { Filters } from 'components/Filters';

import { Agent } from 'modules/agents/types/agent.interface';

import { filtersSchema } from 'modules/agents/pages/list/filters';
import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';
import { permissions } from 'policies/permissions';
import { useLazyTable } from 'hooks/useLazyTable.hook';
import { LazyTable } from 'components/LazyTable';
import { makeColumns } from 'modules/agents/pages/list/columns';
import { AgentReferralsTree } from 'modules/agents/components/AgentReferralsTree';
import { CreateAgentForm } from 'modules/agents/components/CreateAgentForm';
import { UpdateAgentForm } from 'modules/agents/components/UpdateAgentForm/UpdateAgentForm';

export const AgentsPage: React.FC = () => {
  const { memoFilters, setMemoFilters } = useMemoFilters<IFetchAgentsParams>();

  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
  const [updateAgentId, setUpdateAgentId] = useState<number>();

  const [props, { fetch }] = useLazyTable({
    filters: memoFilters,
    fetchAction: fetchAgentsThunk,
    store: 'agents',
    dataField: 'data',
    loadMoreAction: fetchMoreAgents,
    changePageAction: changePagination,
    resetAction: resetAgents,
  });

  return (
    <>
      <Drawer open={isAddModalVisible} title="Создание агента" onClose={() => setIsAddModalVisible(false)}>
        {isAddModalVisible && (
          <CreateAgentForm
            onSubmit={() => {
              fetch();
              setIsAddModalVisible(false);
            }}
          />
        )}
      </Drawer>
      <Drawer
        open={!!updateAgentId}
        title={`Редактирование агента №${updateAgentId}`}
        onClose={() => setUpdateAgentId(undefined)}
      >
        {updateAgentId && (
          <UpdateAgentForm
            agentId={updateAgentId}
            onSubmit={() => {
              setUpdateAgentId(undefined);

              fetch();
            }}
          />
        )}
      </Drawer>
      <Filters
        headerTitle="Агенты"
        filters={filtersSchema}
        value={memoFilters}
        onSubmit={setMemoFilters}
        extraHeaderButtons={() => (
          <>
            <ProtectedComponent requiredPermissions={[permissions.agent.create]} fallbackComponent={<div />}>
              <Button
                type="primary"
                shape="round"
                icon={<UserAddOutlined />}
                onClick={() => setIsAddModalVisible(true)}
              >
                Добавить
              </Button>
            </ProtectedComponent>
          </>
        )}
      />
      <LazyTable
        columns={makeColumns({
          onEditPress: (agent) => {
            setUpdateAgentId(agent.id);
          },
        })}
        rowKey={({ id }: Agent) => id}
        rowExpandable={({ referralsCount }: Agent) => referralsCount > 0}
        expandedRowRender={({ id }: Agent) => <AgentReferralsTree agentId={id} />}
        {...props}
        isTableView
      />
    </>
  );
};
