import useSWRMutation from 'swr/mutation';
import { createCardRequest, CreateCardRequestParams } from 'modules/bankAccounts/api/createCard.request';

export function useCreateCardRequest() {
  const { isMutating, trigger } = useSWRMutation(
    'create-card-request',
    (
      _,
      {
        arg,
      }: {
        arg: CreateCardRequestParams;
      }
    ) => createCardRequest(arg)
  );

  return {
    createCard: trigger,
    isCreatingCard: isMutating,
  };
}
