import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Menu, Space } from 'antd';
import { ItemType, MenuItemGroupType } from 'antd/es/menu/hooks/useItems';
import { LayoutSidebarDisputesCounter } from './components/DisputesCounter';

import { refreshMe } from 'modules/auth/store/auth/actions';

import { privateRoutes } from 'app/navigation/routes/privateRoutes';
import { haveAccessTo } from 'policies/methods/haveAccessTo';

import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';

interface Props {}

interface AntMenuItemType {
  key: string;
  label: string;
  icon?: React.ReactNode;
  children?: AntMenuItemType[];
}

function getActiveKeys(items: AntMenuItemType[], pathname: string): string[] {
  const result: string[] = [];

  for (const item of items) {
    if (item.key === pathname) {
      result.push(item.key);
      break;
    }

    if (item.children) {
      const childResult = getActiveKeys(item.children, pathname);
      if (childResult.length > 0) {
        result.push(item.key, ...childResult);
        break;
      }
    }
  }

  return result;
}

export const LayoutSidebar: React.FC<Props> = () => {
  const location = useLocation();
  const history = useNavigate();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.auth);

  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const activeItemId = location.pathname;

  const items = useMemo<ItemType[]>(() => {
    if (!user) return [];

    return (
      privateRoutes
        .map((route) => {
          if (route.showInSidebar === false) return null;

          const childrenRoutes =
            route.subroutes?.map((subroute) => {
              if (!haveAccessTo(subroute.permissions, user)) return null;

              return {
                label: subroute.title,
                key: route.path + subroute.path,
              };
            }) ?? [];

          const children = [
            haveAccessTo(route.permissions, user) && {
              label: route.title,
              key: route.path,
            },
            ...childrenRoutes,
          ].filter(Boolean);

          if (children.length === 0) {
            return null;
          }

          let icon = route.icon;

          if (route.path === '/order_disputes') {
            icon = (
              <Space>
                {route.icon}
                <LayoutSidebarDisputesCounter />
              </Space>
            );
          }

          return {
            label: route.title,
            key: route.subroutes ? route.title + route.path : route.path,
            children: route.subroutes ? children : undefined,
            icon,
          };
        })
        // Typescript по какой-то причине (баг?) не учитывает какие типы filter отфильтровывает
        // из массива и подолжает генерировать тип (SidebarMenuItem | null)[], так что приходится
        // вручную приводить к нужному типу
        .flat()
        .filter((item) => item !== null)
    );
  }, []);

  const activeKeys = useMemo<string[]>(() => {
    return getActiveKeys(items as AntMenuItemType[], location.pathname);
  }, [items, location.pathname]);

  useEffect(() => {
    setOpenKeys(activeKeys.slice(0, -1));
  }, [activeKeys]);

  const handleOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
  };

  // TODO: Разобраться с типизацией в этом эффекте
  useEffect(() => {
    dispatch<any>(refreshMe());

    let titlePage: string;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    titlePage = items.find((item) => item.key === location.pathname)?.label ?? '';

    if (!titlePage) {
      items.forEach((item) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const title = (item as MenuItemGroupType).children?.find((sub) => sub?.key?.endsWith(location.pathname))?.label;

        if (title !== undefined) {
          titlePage = title;
        }
      });
    }

    document.title = titlePage ?? 'Админ панель';
  }, [activeItemId, items]);

  function handleChangeMenu({ key }: { key: string }) {
    history(key);
  }

  const menuItems = useMemo(() => {
    return (items as AntMenuItemType[])
      .map((item) => {
        if (!item) {
          return null;
        }

        if (item.children) {
          return {
            key: item.key,
            icon: item.icon,
            label: item.label,
            children: item.children.map((child) => ({
              key: child.key,
              label: <Link to={child.key}>{child.label}</Link>,
            })),
          };
        }

        return {
          key: item.key,
          icon: item.icon,
          label: <Link to={item.key}>{item.label}</Link>,
        };
      })
      .filter(Boolean);
  }, [items]);

  const activeKey = [activeKeys[activeKeys.length - 1]];

  return (
    <div style={{ width: 200 }}>
      <Menu
        items={menuItems}
        defaultSelectedKeys={activeKey}
        selectedKeys={activeKey}
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
        mode="inline"
        theme="light"
        onClick={handleChangeMenu}
      />
    </div>
  );
};
