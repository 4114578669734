export function formatAmount(amount: number | undefined, fallbackValue: string = 'Нет данных'): string {
  if (amount === undefined || amount === 0) {
    return fallbackValue;
  }

  return amount?.toLocaleString('ru-RU', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
