import React, { useEffect } from 'react';
import { App, Pagination, Table, Typography } from 'antd';

import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useChannel } from 'hooks/useChannel.hook';

import { useFetchReportsRequest, useSetReportsAsSeenRequest } from 'modules/reports/hooks';
import { Report } from 'modules/reports/types/report.interface';
import { paginationLocale } from 'constants/locale';
import { useReportsTableColumns } from 'modules/reports/pages/list/columns';
import { useTheme } from 'contexts';

interface Props {}

export const ReportsPage: React.FC<Props> = () => {
  const { message } = App.useApp();

  const [params, setParams] = React.useState({ page: 1, perPage: 20 });
  const { reportsData, isFetchingReports, fetchReports } = useFetchReportsRequest(params);
  const { setReportsAsSeen } = useSetReportsAsSeenRequest();

  const { user } = useAppSelector((state) => state.auth);

  const { choose } = useTheme();

  const columns = useReportsTableColumns(() => fetchReports());

  useEffect(() => {
    markAsSeen();
  }, [reportsData]);

  useChannel(
    'private-reports',
    {
      '.created': (newReport: Report) => {
        if (user && user.id === newReport.requestUserId) {
          // фильтрация по пользователю
          newReport.new = true;

          fetchReports();
        }
      },
    },
    [user]
  );

  async function markAsSeen() {
    if (!reportsData?.data) {
      return;
    }

    const ids = reportsData.data.filter((report) => !report.isSeen).map((report) => report.id);

    if (ids.length === 0) return;

    try {
      await setReportsAsSeen(ids);
      await fetchReports();
    } catch {
      message.error('Не удалось пометить отчеты как прочитанные');
    }
  }

  return (
    <>
      <Typography.Title>Отчёты</Typography.Title>
      <Table
        dataSource={reportsData?.data || []}
        columns={columns}
        loading={isFetchingReports}
        pagination={false}
        rowClassName={(record) => (record.new ? 'animate-pulse ' + choose('bg-green-900', 'bg-green-100') : '')}
        rowKey={(record) => record.id}
      />
      <Pagination
        current={params.page}
        total={reportsData?.meta?.total || 0}
        pageSize={params.perPage}
        locale={paginationLocale}
        onChange={(page: number, perPage: number) => setParams({ page, perPage })}
        disabled={isFetchingReports}
      />
    </>
  );
};
