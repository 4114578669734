import React, { useState } from 'react';

import { App, Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { updateCardCount } from 'modules/bankAccounts/store';

import { useCreateCardRequest } from 'modules/bankAccounts/hooks';
import { CreateCardRequestParams } from 'modules/bankAccounts/api/createCard.request';
import cn from 'classnames';

interface Props {
  bankAccountId: number;
  onSubmit: () => void;
  className?: string;
  addButtonPosition?: 'left' | 'right';
}

export const BankAccountCardsListAddCard: React.FC<Props> = ({
  bankAccountId,
  onSubmit,
  className,
  addButtonPosition,
}) => {
  const [showAddCard, setShowAddCard] = useState(false);

  const { createCard, isCreatingCard } = useCreateCardRequest();

  const [form] = useForm<CreateCardRequestParams>();

  const { message } = App.useApp();

  const dispatch = useAppDispatch();

  async function handleAddCard() {
    try {
      await form.validateFields();

      await createCard(form.getFieldsValue());

      message.success('Карта добавлена');

      form.resetFields();

      onSubmit();
      dispatch(updateCardCount({ bankAccountId, type: 'increment' }));
    } catch {
      message.error('Не удалось добавить карту');
    } finally {
      handleCancel();
    }
  }

  function handleCancel() {
    form.resetFields();
    setShowAddCard(false);
  }

  if (!showAddCard) {
    return (
      <div className={cn('flex mb-2', addButtonPosition === 'right' ? 'justify-end' : 'justify-start', className)}>
        <Button type="default" onClick={() => setShowAddCard(true)}>
          + Добавить карту
        </Button>
      </div>
    );
  }

  return (
    <Form form={form}>
      <div className={cn('flex mb-2', className)}>
        <Form.Item hidden name="accountId" initialValue={bankAccountId} />
        <Form.Item
          className="mb-0 mr-6"
          name="cardNumber"
          rules={[{ required: true, message: 'Пожалуйста, введите номер карты!' }]}
        >
          <Input
            placeholder="0000 0000 0000 0000"
            addonBefore="Номер карты"
            className="w-full leading-6"
            disabled={isCreatingCard}
          />
        </Form.Item>
        <Button type="default" className="mr-2" onClick={handleAddCard} loading={isCreatingCard}>
          Сохранить
        </Button>
        <Button type="default" onClick={handleCancel} disabled={isCreatingCard}>
          Отмена
        </Button>
      </div>
    </Form>
  );
};
