import React, { FC, PropsWithChildren, ReactElement, useMemo } from 'react';
import { Form } from 'antd';

interface Props extends PropsWithChildren {
  label: string;
  name?: string;
  after?: ReactElement | string;
}

export const UpdateBankFormItem: FC<Props> = ({ children, label, name, after }) => {
  const content = useMemo(() => {
    if (!name) {
      return children;
    }

    return (
      <Form.Item name={name} noStyle>
        {children}
      </Form.Item>
    );
  }, [name, children]);

  return (
    <div className="flex items-center mb-3">
      <label className="w-48 mr-2 min-w-48">{label}</label>
      {content}
      {after}
    </div>
  );
};
