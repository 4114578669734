import React, { useState } from 'react';
import { App, Button, Drawer, Form, Space, Spin, Steps } from 'antd';
import { Divider } from 'antd/es';
import { useForm } from 'antd/lib/form/Form';

import { useBanksFromServer } from 'hooks/useDataFromServer';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';

import { CreateMerchantParams, MerchantChat, MerchantCommission } from 'modules/merchants/types';
import { CreateMerchantStepsEnum } from 'modules/merchants/enums';

import { createMerchant } from 'modules/merchants/store/merchants/actions';

import { CreateMerchantDrawerCustomerStep } from './components/CustomerStep';
import { CreateMerchantDrawerCommissionsStep } from './components/CommissionsStep';

interface Props {
  isShown: boolean;
  onCancel: () => void;
}

export const CreateMerchantDrawer: React.FC<Props> = ({ isShown, onCancel }) => {
  const [isCreatingInProgress, setIsCreatingInProgress] = useState<boolean>(false);
  const [isEditCommissionsModalVisible, setIsEditCommissionsModalVisible] = useState<boolean>(false);
  const [chats, setChats] = useState<MerchantChat[]>([]);

  const [form] = useForm();
  const [currentStep, setCurrentStep] = useState<CreateMerchantStepsEnum>(CreateMerchantStepsEnum.MERCHANT);
  const [providers] = useBanksFromServer();

  const { message } = App.useApp();

  const dispatch = useAppDispatch();

  function clearFormValues(): void {
    setCurrentStep(CreateMerchantStepsEnum.MERCHANT);
    form.resetFields();
    form.setFieldsValue({
      checkSmsFio: false,
      onExtraCommission: false,
      users: [],
    });

    setChats([]);
  }

  async function handleSubmitPress(commissions: MerchantCommission[]) {
    setIsCreatingInProgress(true);

    const values = form.getFieldsValue();

    const params: CreateMerchantParams = {
      ...values,
      commissions,
      chats,
    };

    const isSuccess = await dispatch(createMerchant(params));

    setIsCreatingInProgress(false);

    if (isSuccess) {
      message.success('Клиент успешно создан');

      onCancel();

      return;
    }

    message.error('Не удалось создать клиента.');
  }

  function handleClose() {
    clearFormValues();
    onCancel();
  }

  return (
    <Drawer
      extra={
        CreateMerchantStepsEnum.COMMISSIONS === currentStep && (
          <Space>
            <Button type="primary" shape="round" onClick={() => setIsEditCommissionsModalVisible(true)}>
              Массовое заполнение комиссии
            </Button>
          </Space>
        )
      }
      size="large"
      title="Добавление клиента"
      open={isShown}
      onClose={handleClose}
      destroyOnClose
    >
      <Steps current={currentStep} className="mb-5">
        <Steps.Step title="Данные клиента" onClick={() => setCurrentStep(CreateMerchantStepsEnum.MERCHANT)} />
        <Steps.Step title="Комиссии" onClick={() => setCurrentStep(CreateMerchantStepsEnum.COMMISSIONS)} />
      </Steps>
      <Divider />
      <Spin tip="Идет создание клиента..." spinning={isCreatingInProgress} size="large">
        <Form form={form} name="createCustomerForm" component={false}>
          <CreateMerchantDrawerCustomerStep
            onChangeChats={setChats}
            isShown={CreateMerchantStepsEnum.MERCHANT === currentStep}
            onNextButtonClick={() => setCurrentStep(CreateMerchantStepsEnum.COMMISSIONS)}
            chats={chats}
          />
          <CreateMerchantDrawerCommissionsStep
            isShown={CreateMerchantStepsEnum.COMMISSIONS === currentStep}
            providers={providers}
            onPrevButtonClick={() => setCurrentStep(CreateMerchantStepsEnum.MERCHANT)}
            onSubmit={handleSubmitPress}
            isEditCommissionsModalVisible={isEditCommissionsModalVisible}
            setIsEditCommissionsModalVisible={setIsEditCommissionsModalVisible}
          />
        </Form>
      </Spin>
    </Drawer>
  );
};
