import { User } from 'modules/users/types/user.interface';
import instance from 'utils/axios';

export interface UpdateUserRequestParams {
  id: number;
  name: string;
  email: string;
  newPassword?: string;
  roles: number[];
  telegramUsername?: string;
}

export async function updateUserRequest(params: UpdateUserRequestParams): Promise<User> {
  const {
    data: { data },
  } = await instance.post('/users/update', params);

  return data;
}
