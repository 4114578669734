import instance from 'utils/axios';
import { User } from 'modules/users/types/user.interface';

export interface CreateUserRequestParams {
  name: string;
  email: string;
  password: string;
  roles: number[];
  telegramUsername?: string;
  agentId?: number;
}

export async function createUserRequest(params: CreateUserRequestParams): Promise<User> {
  const {
    data: { data },
  } = await instance.post('/users/create', params);

  return data;
}
