import React from 'react';

import { useUserPermissions } from 'policies/hooks/useUserPermissions';

interface Props {
  /** Права, необходимые для отображения */
  requiredPermissions: string[];
  /** Компонент для отображения */
  children: React.ReactElement;
  /** Опционально: компонент, отображаемый в случае, если у пользователя нет необходимых прав */
  fallbackComponent?: React.ReactElement;
}

/**
 * Защищеный политикой компонент.
 * Отображает дочерний компонент в зависимости от прав доступа пользователя и
 * необходимых для отображения прав
 *
 * @author Собянин Виктор <telegram: @victorsobyanin>
 */
export const ProtectedComponent: React.FC<Props> = ({ requiredPermissions, children, fallbackComponent = null }) => {
  const { userCan } = useUserPermissions();

  if (userCan(...requiredPermissions)) return children;

  return fallbackComponent;
};
