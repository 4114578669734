import useSWR from 'swr';
import { fetchUserRequest } from 'modules/users/api/fetchUser.request';

export function useFetchUserRequest(id: number) {
  const { data, isLoading, mutate } = useSWR([id, 'fetch-user-request-hook'], async ([id]) => {
    return await fetchUserRequest(id);
  });

  return { user: data, isFetchingUser: isLoading, fetchUser: mutate };
}
