import { User } from 'modules/users/types/user.interface';
import { IMetaPagination } from 'types/IMetaPagination';
import { ReducerAction } from 'types/reducerAction.interface';

export interface IFetchUsersParams {
  id?: number;
  name?: string;
}

export interface IFetchUsersPayload {
  users: User[];
  pagination: IMetaPagination;
}

export interface ISetPaginationPayload {
  page: number;
  perPage: number;
}

export interface IUsersState {
  users: User[];
  isLoading: boolean;
  pagination: IMetaPagination;
}

export type IFetchUsersReducerAction = ReducerAction<IFetchUsersPayload, UsersActionsTypes.FETCH_USERS>;

export type ISetIsLoadingReducerAction = ReducerAction<boolean, UsersActionsTypes.SET_IS_LOADING>;

export type ISetPaginationReducerAction = ReducerAction<ISetPaginationPayload, UsersActionsTypes.SET_PAGINATION>;

export type UsersReducerActions = IFetchUsersReducerAction | ISetIsLoadingReducerAction | ISetPaginationReducerAction;

export enum UsersActionsTypes {
  FETCH_USERS = 'FETCH_USERS',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_PAGINATION = 'SET_PAGINATION',
}
