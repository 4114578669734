import React, { useEffect, useMemo, useState } from 'react';
import { Button, Drawer, Input, Select, Space, Typography } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import { IOwner, IOwnerStatus } from 'modules/owners/types/IOwner';
import { useOwnerStatusesFromServer, useBanksFromServer } from 'hooks/useDataFromServer';
import { GoipCascader } from '../GoipCascader/GoipCascader';
import { useTheme } from 'contexts';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';
import { SwitchField } from 'components/SwitchField';
import { InputNumber } from 'components/Input';
import { LazySelect } from 'components/LazySelect';

interface Props {
  isShown: boolean;
  params: IOwner;
  onChange: (newValue: IOwner) => void;
  onSubmit: () => void | Promise<void>;
  onCancel: () => void;
}

export const OwnersPageEditOwnerModal: React.FC<Props> = ({ isShown, params, onCancel, onSubmit, onChange }) => {
  const {
    status,
    fio,
    limitOut,
    chatId,
    mainPhone,
    secondaryPhone,
    isDeposit,
    tgNick,
    incommingTransactionsIsAllowed,
    outcommingTransactionsIsAllowed,
    pingInterval,
    imei,
    login,
    password,
    trustLimit,
    ownerId,
    priority,
    outcomingOrdersPriority,
    ping,
    goip,
    isAutoAssigmentEnabled,
    isSbpEnabled,
    disputesLimit,
    trusted,
  } = params;

  // todo не смог нормально поймать изменения до и после для проверки goips временно потому что надо уже на проде
  const [initialParams, setInitialParams] = useState<{ data?: IOwner; isInit: boolean }>({ isInit: false });

  useEffect(() => {
    if (!initialParams.isInit && 0 !== Object.keys(params).length) {
      setInitialParams({ isInit: true, data: params });
    }
  }, [params]);

  const [providers] = useBanksFromServer();

  const { isDarkMode } = useTheme();

  const isAllowedSbp = useMemo(() => {
    return providers.filter((provider) => provider.currencyCode === 'RUB').map((provider) => provider.id);
  }, [providers]);

  function handleSubmitPress() {
    onSubmit();
  }

  useEffect(() => {
    onChange(params);
  }, []);

  const onValueChange = (key: keyof IOwner, value: null | number | string | boolean) => {
    if (key === 'status') {
      const statusObj = { ...params.status, id: value };
      onChange({ ...params, [key]: statusObj as IOwnerStatus });
      return;
    }
    if (value || value === 0 || value === false) {
      onChange({ ...params, [key]: value });
      return;
    } else {
      delete params[key];
      onChange({ ...params });
    }
  };

  if (Object.keys(params).length === 0) return null;

  return (
    <>
      <Drawer title={'Редактирование владельца №' + ownerId} open={isShown} onClose={onCancel}>
        <Space direction="vertical" size="large">
          <LazySelect
            allowClear
            style={{ minWidth: 150 }}
            placeholder="Статус"
            value={status?.id ?? status}
            onChange={(value: number) => onValueChange('status', value)}
            useDataHook={useOwnerStatusesFromServer}
            cacheKey="owner-status-select"
          />
          <Input
            placeholder="ФИО Владельца"
            addonBefore="ФИО"
            value={fio}
            className="w-full"
            onChange={(event) => onValueChange('fio', event.target.value)}
          />
          <InputNumber
            placeholder="Лимит исходящих"
            addonBefore="Лимит исходящих"
            isAmountInput
            value={limitOut}
            className="w-full"
            onChange={(value) => onValueChange('limitOut', value)}
          />
          <InputNumber
            placeholder="ID чата"
            addonBefore="ID чата"
            value={chatId}
            className="w-full"
            onChange={(value) => onValueChange('chatId', value)}
          />
          {isAllowedSbp && (
            <SwitchField value={isSbpEnabled} onChange={(value) => onValueChange('isSbpEnabled', value)}>
              Работа с СБП
            </SwitchField>
          )}
          <Input
            placeholder="Основной телефон"
            addonBefore="Основной телефон"
            value={mainPhone}
            onChange={(e) => onValueChange('mainPhone', e.target.value)}
          />
          <Input
            placeholder="Доп. телефон"
            addonBefore="Доп. телефон"
            value={secondaryPhone}
            onChange={(e) => onValueChange('secondaryPhone', e.target.value)}
          />
          <Input
            placeholder="Ник телеграма"
            addonBefore="Ник"
            value={tgNick}
            className="w-full"
            onChange={(event) => onValueChange('tgNick', event.target.value)}
          />
          <Input
            placeholder="Введите IMEI устройства"
            addonBefore="IMEI"
            value={imei}
            className="w-full"
            onChange={(event) => onValueChange('imei', event.target.value)}
          />
          <Input
            placeholder="Введите логин"
            addonBefore="Логин"
            value={login}
            className="w-full"
            onChange={(event) => onValueChange('login', event.target.value)}
          />
          <div className="flex items-center">
            <div
              className={`px-2 py-1 border border-solid rounded-tl-md rounded-bl-md ${
                isDarkMode ? 'border-gray-500 bg-opacity-0 text-white text-opacity-85' : 'border-hmGray bg-grayTrue-50'
              }`}
            >
              Пароль
            </div>
            <div
              className={`p-1 border border-solid ${
                isDarkMode ? 'border-gray-500 bg-opacity-0' : 'border-hmGray bg-grayTrue-50'
              }`}
            >
              <Typography.Text
                className="text-black"
                copyable={{
                  text: password,
                  icon: <CopyOutlined style={{ color: `${isDarkMode ? 'white' : 'black'}` }} className="mr-1" />,
                  tooltips: ['Скопировать пароль', 'Скопировано!'],
                }}
              />
            </div>
            <Input
              placeholder="Введите пароль"
              value={password}
              onChange={(event) => onValueChange('password', event.target.value)}
            />
          </div>
          <GoipCascader
            isShown={isShown}
            goip={goip}
            onChange={(value) => {
              onValueChange('goip', value);
            }}
          />
          <Input
            placeholder="Интервал пинга"
            addonBefore="Интервал"
            value={pingInterval}
            className="w-full"
            onChange={(event) => onValueChange('pingInterval', event.target.value)}
          />
          <InputNumber
            placeholder="Лимит доверия"
            addonBefore="Лимит доверия"
            value={trustLimit}
            isAmountInput
            className="w-full"
            onChange={(value) => onValueChange('trustLimit', value ?? 0)}
          />
          <InputNumber
            placeholder="Лимит по диспутам"
            addonBefore="Лимит по диспутам"
            value={disputesLimit}
            className="w-full"
            onChange={(value) => onValueChange('disputesLimit', value)}
          />
          <InputNumber
            placeholder="Приоритет входящих заявок"
            addonBefore="Приоритет входящих заявок"
            value={priority}
            className="w-full"
            onChange={(value) => onValueChange('priority', value)}
          />
          <InputNumber
            placeholder="Приоритет исходящих заявок"
            addonBefore="Приоритет исходящих заявок"
            value={outcomingOrdersPriority}
            className="w-full"
            onChange={(value) => onValueChange('outcomingOrdersPriority', value)}
          />
          <Select
            allowClear
            placeholder={'Статус депозитности'}
            style={{ minWidth: 150 }}
            options={[
              { value: true, label: 'Депозитный' },
              { value: false, label: 'Недепозитный' },
            ]}
            value={isDeposit}
            onChange={(value: boolean) => onValueChange('isDeposit', value)}
          />
          <SwitchField value={ping} onChange={(value) => onValueChange('ping', value)}>
            Пинг
          </SwitchField>
          <SwitchField
            value={incommingTransactionsIsAllowed}
            onChange={(value) => onValueChange('incommingTransactionsIsAllowed', value)}
          >
            Входящие транзакции разрешены
          </SwitchField>
          <SwitchField
            value={outcommingTransactionsIsAllowed}
            onChange={(value) => onValueChange('outcommingTransactionsIsAllowed', value)}
          >
            Исходящие транзакции разрешены
          </SwitchField>
          <SwitchField
            value={isAutoAssigmentEnabled}
            onChange={(value) => onValueChange('isAutoAssigmentEnabled', value)}
          >
            Участвует в автоматическом распределении заявок
          </SwitchField>
          <SwitchField value={trusted} onChange={(value) => onValueChange('trusted', value)}>
            Доверенный
          </SwitchField>
          <ProtectedComponent requiredPermissions={[permissions.owner.update]}>
            <Button onClick={handleSubmitPress} className="w-full" type="primary">
              Сохранить изменения
            </Button>
          </ProtectedComponent>
        </Space>
      </Drawer>
    </>
  );
};
