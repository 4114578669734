import instance from 'utils/axios';
import { MerchantToken } from '../types/merchantToken.interface';

export async function revokeMerchantTokenRequest(merchantId: number, tokenId: number): Promise<MerchantToken> {
  const {
    data: { data },
  } = await instance.post('/merchants/tokens/revoke', {
    merchantId,
    tokenId,
  });

  return data;
}
