import React, { useState } from 'react';
import { Button, Drawer, Empty, Space, Spin } from 'antd';

import { CreditCardOutlined } from '@ant-design/icons';

import { BankAccountCard, CreateBankAccountForm } from 'modules/bankAccounts/components';
import { useFetchBankAccountsRequest } from 'modules/bankAccounts/hooks/useFetchBankAccountsRequest.hook';

interface Props {
  ownerId: number;
}

export const OwnerBankAccountsList: React.FC<Props> = ({ ownerId }) => {
  const [isAddAccountModalVisible, setIsAddAccountModalVisible] = useState<boolean>(false);

  const { bankAccountsData, isFetchingBankAccounts, fetchBankAccounts } = useFetchBankAccountsRequest({
    ownerId,
    perPage: 100,
    page: 0,
  });

  return (
    <Space direction="vertical" className="w-full p-2">
      <Drawer open={isAddAccountModalVisible} onClose={() => setIsAddAccountModalVisible(false)} title="Создание счета">
        {isAddAccountModalVisible && (
          <CreateBankAccountForm
            ownerId={ownerId}
            onSuccess={() => {
              setIsAddAccountModalVisible(false);
              fetchBankAccounts();
            }}
          />
        )}
      </Drawer>
      <Space className="justify-between w-full">
        <Button
          onClick={() => setIsAddAccountModalVisible(true)}
          type="primary"
          shape="round"
          className="mb-4"
          icon={<CreditCardOutlined />}
        >
          Добавить счет
        </Button>
      </Space>
      <Spin spinning={isFetchingBankAccounts}>
        {bankAccountsData?.data?.length !== 0 ? (
          <div className="flex flex-col gap-y-4">
            {bankAccountsData?.data.map((bankAccount) => (
              <BankAccountCard id={bankAccount.id} key={bankAccount.id} onDeleteSuccess={() => fetchBankAccounts()} />
            ))}
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Нет данных" />
        )}
      </Spin>
    </Space>
  );
};
