export async function downloadFileAsBase64(url: string): Promise<string> {
  const res = await fetch(url);
  const reader = new FileReader();

  const resultPromise = new Promise<string>((resolve) => {
    reader.onloadend = () => resolve(reader.result as string);
  });

  reader.readAsDataURL(await res.blob());

  return resultPromise;
}
