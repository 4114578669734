import useSWRMutation from 'swr/mutation';
import { deleteBankAccountRequest } from 'modules/bankAccounts/api/deleteBankAccount.request';

export function useDeleteBankAccountRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'delete-bank-account',
    (
      _,
      {
        arg,
      }: {
        arg: number;
      }
    ) => deleteBankAccountRequest(arg)
  );

  return { deleteBankAccount: trigger, isDeletingBankAccount: isMutating };
}
