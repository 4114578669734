import React, { useEffect, useState } from 'react';
import { App, Table } from 'antd';
import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';
import { usePartnersColumns } from './hooks/usePartnersColumns.hook';
import { partnersFilters } from './filters';
import { PartnersPageUpdateDrawer } from './components/UpdateDrawer';
import { PartnersPageMerchantsDrawer } from './components/MerchantsDrawer';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { Partner } from 'modules/partners/types/partner.interface';
import { FetchPartnersParams } from 'modules/partners/store/types/fetchPartnersParams.interface';
import { fetchPartners } from 'modules/partners/store';
import { Filters } from 'components/Filters';
import { Pagination } from 'components/Pagination';

export const PartnersMainPage: React.FC<unknown> = () => {
  const [partnerToUpdate, setPartnerToUpdate] = useState<Partner>();
  const [partnerToViewMerchants, setPartnerToViewMerchants] = useState<Partner>();

  const { message } = App.useApp();

  const {
    partners,
    isLoading,
    pagination: { page, perPage },
    pagination,
  } = useAppSelector((state) => state.partners);

  const { memoFilters, setMemoFilters } = useMemoFilters<FetchPartnersParams>(true);

  const dispatch = useAppDispatch();

  const columns = usePartnersColumns({
    onUpdateClick: handleUpdateClick,
    onViewMerchantsClick: (partner) => setPartnerToViewMerchants(partner),
  });

  useEffect(() => {
    fetch(memoFilters);
  }, []);

  function handleUpdateClick(partner: Partner) {
    setPartnerToUpdate(partner);
  }

  function handleFiltersChange(filters: FetchPartnersParams) {
    setMemoFilters(filters);
    fetch(filters);
  }

  function handlePaginationChange(page: number, perPage: number): void {
    fetch(memoFilters, page, perPage);
  }

  async function fetch(filters: FetchPartnersParams, requestPage = page, requestPerPage = perPage) {
    try {
      await dispatch(fetchPartners({ ...filters, page: requestPage, perPage: requestPerPage })).unwrap();
    } catch {
      message.error('Не удалось получить список партнеров');
    }
  }

  return (
    <>
      <PartnersPageUpdateDrawer partner={partnerToUpdate} onClose={() => setPartnerToUpdate(undefined)} />
      <PartnersPageMerchantsDrawer
        partner={partnerToViewMerchants}
        onClose={() => setPartnerToViewMerchants(undefined)}
      />
      <Filters headerTitle="Партнеры" filters={partnersFilters} value={memoFilters} onSubmit={handleFiltersChange} />
      <Table
        columns={columns}
        dataSource={partners}
        loading={isLoading}
        pagination={false}
        rowKey={(record) => record.id}
      />
      <Pagination pagination={pagination} isLoading={isLoading} onPaginationChange={handlePaginationChange} />
    </>
  );
};
