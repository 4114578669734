import React, { useEffect, useState } from 'react';
import { message, Switch } from 'antd';
import { BankAccount } from 'modules/bankAccounts/types/bankAccount.interface';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { permissions } from 'policies/permissions';
import { useDisableOutcomingBankAccountRequest } from 'modules/bankAccounts/hooks/useDisableOutcomingBankAccountRequest.hook';
import { useEnableOutcomingBankAccountRequest } from 'modules/bankAccounts/hooks/useEnableOutcomingBankAccountRequest.hook';

interface Props {
  account: BankAccount;
}

export const BankAccountOutcomingEnabledSwitch: React.FC<Props> = ({ account }) => {
  const [checked, setChecked] = useState<boolean>(account.outEnabled);

  const { disableOutcomingBankAccount, isDisablingOutcomingBankAccount } = useDisableOutcomingBankAccountRequest();
  const { enableOutcomingBankAccount, isEnablingOutcomingBankAccount } = useEnableOutcomingBankAccountRequest();

  const { userCanAll } = useUserPermissions();

  useEffect(() => {
    setChecked(account.outEnabled);
  }, [account]);

  async function handleChange(newChecked: boolean) {
    try {
      if (newChecked) {
        await enableOutcomingBankAccount(account.id);
      } else {
        await disableOutcomingBankAccount(account.id);
      }

      message.success({
        content: 'Исходящие платежи успешно изменены!',
        duration: 1.5,
      });
      setChecked(newChecked);
    } catch {
      message.error('Ошибка изменения Исходящих платежей!');
    }
  }

  return (
    <Switch
      checked={checked}
      onChange={handleChange}
      disabled={!userCanAll(permissions.bankAccount.out.enable, permissions.bankAccount.out.disable)}
      loading={isDisablingOutcomingBankAccount || isEnablingOutcomingBankAccount}
    />
  );
};
