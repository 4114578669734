import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';
import { useDisputeStatusesFromServer, useMerchantsFromServer } from 'hooks/useDataFromServer';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'ID Диспута',
    name: 'id',
    type: 'input-number',
  },
  {
    title: 'ID Заявки',
    name: 'transactionId',
    type: 'input-number',
  },
  {
    title: 'ID Заявки клиента',
    name: 'customerTransactionId',
    type: 'input',
    allowClear: true,
  },
  {
    title: 'ID Владельца',
    name: 'ownerId',
    type: 'input-number',
  },
  {
    title: 'Клиент',
    name: 'customerId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useMerchantsFromServer,
    cacheKey: 'customers-select',
  },
  {
    title: 'Статус диспута',
    name: 'status',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useDisputeStatusesFromServer,
    cacheKey: 'dispute-status',
  },
];
