import React from 'react';
import { Table } from 'antd';

import { useTableColumns } from 'modules/users/pages/list/components/Table/columns';

import { User } from 'modules/users/types/user.interface';
import { useAppSelector } from 'hooks/useAppSelector.hook';

interface Props {
  onUserEditPress: (user: User) => void;
}

export const UsersPageTable: React.FC<Props> = ({ onUserEditPress }) => {
  const { users, isLoading } = useAppSelector((state) => state.users);

  const columns = useTableColumns({ onUserEditPress });

  return (
    <Table columns={columns} dataSource={users} loading={isLoading} pagination={false} rowKey={(user) => user.id} />
  );
};
