import { MerchantCommissionStep } from 'modules/merchants/types/merchantCommissionStep.interface';

export const emptyCommissionRow: MerchantCommissionStep = {
  incomingValue: {
    p2p: undefined,
    c2c: undefined,
  },
  outcomingValue: {
    p2p: undefined,
    c2c: undefined,
  },
  currencyCode: '',
};
