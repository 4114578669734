import { ReducerAction } from 'types/reducerAction.interface';

export interface Goip {
  id: number;
  name: string;
  channels: GoipPort[];
}

export interface GoipPort {
  login: IGoipChannelLoginStatusEnum;
  gsmStatus: IGoipChannelGsmStatusEnum;
  signal: number;
  owner: GoipPortOwner;
  channelId: number;
  balance: number;
  mainPhone: string;
  additionalPhone?: string;
}

export interface GoipPortOwner {
  id: number;
  fio: string;
  isActive: boolean;
}

export enum IGoipChannelAuthStatusEnum {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  IDLE = 'IDLE',
}

export enum IGoipChannelGsmStatusEnum {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

export enum IGoipChannelLoginStatusEnum {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

export const IGoipChannelAuthOnlineStatus = [IGoipChannelLoginStatusEnum.LOGIN];

export interface IGoipState {
  goips: Goip[];
  isLoading: boolean;
}

export interface IGetGoipsReducerActionPayload {
  goips: Goip[];
}

export type IGetGoipsReducerAction = ReducerAction<IGetGoipsReducerActionPayload, GoipsActionTypes.GET_GOIPS>;

export type ISetIsLoadingGoipsReducerAction = ReducerAction<boolean, GoipsActionTypes.SET_IS_LOADING>;

export type GoipsReducerActions = IGetGoipsReducerAction | ISetIsLoadingGoipsReducerAction;

export enum GoipsActionTypes {
  GET_GOIPS = 'GET_GOIPS',
  SET_IS_LOADING = 'SET_IS_LOADING',
  CHANGE_PAGINATION = 'CHANGE_PAGINATION',
}
