import { Drawer, Spin, Table, Typography } from 'antd';
import { useFetchMerchantBalancesByCurrenciesRequest, useFetchMerchantBalancesRequest } from 'modules/merchants/hooks';
import React from 'react';
import { useMerchantBalancesDrawerColumns } from './hooks/useMerchantBalancesDrawerColumns.hook';

interface Props {
  merchantId: number;
  onClose: () => void;
}

export const MerchantBalancesDrawer: React.FC<Props> = ({ merchantId, onClose }) => {
  const { merchantBalancesByCurrencies, isFetchingMerchantBalancesByCurrencies } =
    useFetchMerchantBalancesByCurrenciesRequest(merchantId);
  const { merchantBalances, isFetchingMerchantBalances } = useFetchMerchantBalancesRequest(merchantId);

  const columns = useMerchantBalancesDrawerColumns();

  return (
    <Drawer open={!!merchantId} width="50%" title="Балансы клиента" onClose={onClose}>
      <Spin spinning={isFetchingMerchantBalancesByCurrencies || isFetchingMerchantBalances}>
        <Typography.Title level={5}>Балансы по валютам</Typography.Title>
        <Table
          columns={[
            {
              title: 'Валюта',
              dataIndex: 'currencyCode',
              key: 'currencyCode',
            },
            {
              title: 'Сумма',
              dataIndex: 'amount',
              key: 'amount',
              render: (amount) => <span>{Number(amount).toLocaleString('ru-RU')}</span>,
            },
          ]}
          dataSource={merchantBalancesByCurrencies}
          pagination={false}
        />
        <Typography.Title level={5} className="mt-4">
          Балансы по банкам
        </Typography.Title>
        <Table columns={columns} dataSource={merchantBalances} pagination={false} />
      </Spin>
    </Drawer>
  );
};
