import { Bank } from 'modules/banks/types/bank.interface';
import { IMetaPagination } from 'types/IMetaPagination';
import { ReducerAction } from 'types/reducerAction.interface';

export interface IBanksState {
  banks: Bank[];
  isLoading: boolean;
  pagination: IMetaPagination;
  foundBinBank: Bank | null;
  duplicates: {
    mainBank: Bank | null;
    mergeBanks: Bank[];
  };
}

export interface IFetchBanksPayload {
  banks: Bank[];
  pagination: IMetaPagination;
}

export interface ISetPaginationPayload {
  page: number;
  perPage: number;
}

export interface IFetchBanksParams {
  id?: number;
  name?: string;
}

export enum BanksActionsTypes {
  FETCH_BANKS = 'FETCH_BANKS',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_PAGINATION = 'SET_PAGINATION',
  UPDATE_BANK = 'UPDATE_BANK',
  TOGGLE_FOUND_BANK = 'TOGGLE_FOUND_BANK',
  ADD_MAIN_BANK_TO_REMOVE_DUPLICATE = 'ADD_MAIN_BANK_TO_REMOVE_DUPLICATE',
  ADD_MERGE_BANKS_TO_REMOVE_DUPLICATE = 'ADD_MERGE_BANKS_TO_REMOVE_DUPLICATE',
  DELETE_MERGE_BANKS_TO_REMOVE_DUPLICATE = 'DELETE_MERGE_BANKS_TO_REMOVE_DUPLICATE',
  CLEAR_CHECKED_MAIN_BANK = 'CLEAR_CHECKED_MAIN_BANK',
  CLEAR_CHECKED_MERGE_BANK = 'CLEAR_CHECKED_MERGE_BANK',
  CLEAR_MAIN_AND_MERGE_BANK = 'CLEAR_MAIN_AND_MERGE_BANK',
  DISABLED_CHECKED_MAIN_BANK = 'DISABLED_CHECKED_MAIN_BANK',
  DISABLED_CHECKED_MERGE_BANK = 'DISABLED_CHECKED_MERGE_BANK',
  CLEAR_DISABLED_CHECKED_BANKS = 'CLEAR_DISABLED_CHECKED_BANKS',
}

export type IFetchBanksReducerAction = ReducerAction<IFetchBanksPayload, BanksActionsTypes.FETCH_BANKS>;

export type ISetIsLoadingReducerAction = ReducerAction<boolean, BanksActionsTypes.SET_IS_LOADING>;

export type ISetPaginationReducerAction = ReducerAction<ISetPaginationPayload, BanksActionsTypes.SET_PAGINATION>;

export type IToggleFoundBankReducerAction = ReducerAction<Bank, BanksActionsTypes.TOGGLE_FOUND_BANK>;

export type IClearCheckedMainBankReducerAction = ReducerAction<Bank, BanksActionsTypes.CLEAR_CHECKED_MAIN_BANK>;

export type IClearCheckedMergeBankReducerAction = ReducerAction<Bank, BanksActionsTypes.CLEAR_CHECKED_MERGE_BANK>;

export type IDisabledCheckedMainBankReducerAction = ReducerAction<Bank, BanksActionsTypes.DISABLED_CHECKED_MAIN_BANK>;

export type IClearDisabledCheckedBanksReducerAction = ReducerAction<
  Bank,
  BanksActionsTypes.CLEAR_DISABLED_CHECKED_BANKS
>;

export type IDisabledCheckedMergeBankReducerAction = ReducerAction<Bank, BanksActionsTypes.DISABLED_CHECKED_MERGE_BANK>;

export type IAddMainBankToRemoveDuplicateReducerAction = ReducerAction<
  Bank,
  BanksActionsTypes.ADD_MAIN_BANK_TO_REMOVE_DUPLICATE
>;

export type IAddMergeBanksToRemoveDuplicateReducerAction = ReducerAction<
  Bank,
  BanksActionsTypes.ADD_MERGE_BANKS_TO_REMOVE_DUPLICATE
>;

export type IDeleteMergeBanksToRemoveDuplicateReducerAction = ReducerAction<
  Bank,
  BanksActionsTypes.DELETE_MERGE_BANKS_TO_REMOVE_DUPLICATE
>;

export type IDeleteMainAndMergeBanksReducerAction = ReducerAction<Bank[], BanksActionsTypes.CLEAR_MAIN_AND_MERGE_BANK>;

export type BanksReducerActions =
  | IFetchBanksReducerAction
  | ISetIsLoadingReducerAction
  | ISetPaginationReducerAction
  | IToggleFoundBankReducerAction
  | IAddMainBankToRemoveDuplicateReducerAction
  | IAddMergeBanksToRemoveDuplicateReducerAction
  | IDeleteMergeBanksToRemoveDuplicateReducerAction
  | IDeleteMainAndMergeBanksReducerAction
  | IClearCheckedMainBankReducerAction
  | IClearCheckedMergeBankReducerAction
  | IDisabledCheckedMainBankReducerAction
  | IDisabledCheckedMergeBankReducerAction
  | IClearDisabledCheckedBanksReducerAction;
