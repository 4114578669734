import instance from 'utils/axios';
import { MerchantBalance } from '../types/merchantBalance.interface';

export async function fetchMerchantBalancesRequest(merchantId: number, perPage: number): Promise<MerchantBalance[]> {
  const {
    data: { data },
  } = await instance.get('/merchants/balances', { params: { merchantId, perPage } });

  return data;
}
