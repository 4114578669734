import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';
import { useBanksFromServer } from 'hooks/useDataFromServer';
import { BankTransactionDirectionEnum } from 'modules/bankTransactions/enums/bankTransactionDirection.enum';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'ID владельца',
    name: 'ownerId',
    type: 'input-number',
  },
  {
    title: 'Сумма ОТ',
    name: 'amountMin',
    type: 'input-number',
    isAmountInput: true,
  },
  {
    title: 'Сумма ДО',
    name: 'amountMax',
    type: 'input-number',
    isAmountInput: true,
  },
  {
    title: 'Банк',
    name: 'bankId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useBanksFromServer,
    cacheKey: 'provider-filter',
  },
  {
    title: 'Тип транзакции',
    name: 'direction',
    type: 'select',
    allowClear: true,
    width: 142,
    options: [
      {
        label: 'Любой',
        value: '',
      },
      {
        label: 'Входящие',
        value: BankTransactionDirectionEnum.IN,
      },
      {
        label: 'Исходящие',
        value: BankTransactionDirectionEnum.OUT,
      },
    ],
  },
  {
    title: 'ID платежа',
    name: 'paymentId',
    type: 'input-number',
  },
  {
    title: 'ID заявки',
    name: 'orderId',
    type: 'input-number',
  },
  {
    title: 'Номер счёта',
    name: 'accountNumber',
    type: 'input',
    allowClear: true,
    width: 200,
  },
  {
    title: 'Привязана заявка',
    name: 'paymentAttached',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Не привязана',
        value: 0,
      },
      {
        label: 'Привязана',
        value: 1,
      },
    ],
  },
];
