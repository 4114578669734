import { OrderStatusEnum } from 'modules/orders/enums/orderStatus.enum';

export const finalStatusesAllowedToEdit = [
  OrderStatusEnum.EXECUTED,
  OrderStatusEnum.UNKNOWN_ENTERED,
  OrderStatusEnum.ENTERED,
  OrderStatusEnum.NOT_ENTERED,
  OrderStatusEnum.NOT_EXECUTED,
];

export const checkAllowedUserToEditOrder = (currentStatus: OrderStatusEnum, canUpdateFinalOrder = false): boolean => {
  return (
    // заявки с финальным статусом могут редактировать только админы
    finalStatusesAllowedToEdit.includes(currentStatus) && canUpdateFinalOrder
  );
};
