import { IConfigurationState, ConfigurationReducerActions, ConfigurationActionTypes } from './types';

const initialState: IConfigurationState = {
  configuration: {},
  isLoading: false,
};

export function configurationReducer(state = initialState, action: ConfigurationReducerActions): IConfigurationState {
  switch (action.type) {
    case ConfigurationActionTypes.FETCH_CONFIGURATION:
      return { ...state, configuration: action.payload };
    case ConfigurationActionTypes.UPDATE_CONFIGURATION_VALUE:
      return { ...state };
    case ConfigurationActionTypes.SET_IS_LODAING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}
