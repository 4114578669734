import useSWRMutation from 'swr/mutation';
import { createBankFromBinRequest, CreateBankFromBinRequestParams } from 'modules/banks/api/createBankFromBin.request';

export function useCreateBankFromBinRequest() {
  const { isMutating, trigger } = useSWRMutation(
    'create-bank-from-bin-request',
    (
      _,
      {
        arg,
      }: {
        arg: CreateBankFromBinRequestParams;
      }
    ) => createBankFromBinRequest(arg),
    { throwOnError: true }
  );

  return { createBankFromBin: trigger, isCreatingBankFromBin: isMutating };
}
