import React, { useEffect } from 'react';

import { App, Table } from 'antd';

import { paymentsFilters } from './filters';

import { FetchPaymentsParams } from 'modules/payments/store/types/fetchPaymentsParams.interface';

import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { usePaymentsColumns } from './hooks/usePaymentsColumns.hook';

import { fetchPayments } from 'modules/payments/store';

import { Filters } from 'components/Filters';
import { Pagination } from 'components/Pagination';
import { PaymentsStatisticBlock, RequestPaymentsReportButton } from 'modules/payments/components';
import { localDateTimeToUtc } from 'utils/localDateTimeToUtc.util';

export const PaymentsListPage: React.FC = () => {
  const { memoFilters, setMemoFilters } = useMemoFilters<FetchPaymentsParams>(true);

  const { message } = App.useApp();

  const {
    payments,
    isLoading,
    pagination: { page, perPage },
    pagination,
  } = useAppSelector((state) => state.payments);

  const dispatch = useAppDispatch();

  const columns = usePaymentsColumns();

  useEffect(() => {
    fetch(memoFilters);
  }, []);

  function handleFiltersChange(filters: FetchPaymentsParams) {
    setMemoFilters(filters);
    fetch(filters);
  }

  function handlePaginationChange(page: number, perPage: number): void {
    fetch(memoFilters, page, perPage);
  }

  async function fetch(filters: FetchPaymentsParams, requestPage = page, requestPerPage = perPage) {
    try {
      await dispatch(
        fetchPayments({
          ...filters,
          from: filters.from ? localDateTimeToUtc(filters.from) : undefined,
          to: filters.to ? localDateTimeToUtc(filters.to) : undefined,
          page: requestPage,
          perPage: requestPerPage,
        })
      ).unwrap();
    } catch {
      message.error('Не удалось получить список платежей');
    }
  }

  return (
    <>
      <Filters
        headerTitle="Платежи"
        filters={paymentsFilters}
        value={memoFilters}
        onSubmit={handleFiltersChange}
        customButtons={(getFilters) => (
          <>
            <RequestPaymentsReportButton params={getFilters} />
            <PaymentsStatisticBlock params={getFilters} onRequest={() => setMemoFilters(getFilters())} />
          </>
        )}
      />
      <Table
        columns={columns}
        dataSource={payments}
        loading={isLoading}
        pagination={false}
        rowKey={(record) => record.id}
      />
      <Pagination pagination={pagination} isLoading={isLoading} onPaginationChange={handlePaginationChange} />
    </>
  );
};
