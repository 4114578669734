import React from 'react';

import { Typography, Space, Switch } from 'antd';
import { TableOutlined } from '@ant-design/icons';

interface Props {
  isLoading: boolean;
  isTableView: boolean;
  onViewToggleChanged: () => void;
}

export const ActiveOrdersPageHeader: React.FC<Props> = ({ isLoading, isTableView, onViewToggleChanged }) => {
  return (
    <Space className="justify-between w-full">
      <Space className="right-0 pr-3">
        <TableOutlined className="text-blue-500" />
        <Typography.Text>Табличный вид</Typography.Text>
        <Switch disabled={isLoading} checked={isTableView} onChange={onViewToggleChanged} />
      </Space>
    </Space>
  );
};
