import React, { useMemo } from 'react';
import { LazyTableListViewRowColumn } from 'components/LazyTable/components/ListView/components/Row/components/Column';
import { MinusSquareFilled, PlusSquareFilled } from '@ant-design/icons';
import { useTheme } from 'contexts';
import { LazyTableColumnsType } from 'components/LazyTable/types/lazyTableColumns.type';

interface Props<RecordType = any> {
  columns: LazyTableColumnsType<RecordType>;
  columnWidths: { [key: string]: number };
  row: RecordType;
  style: React.CSSProperties;
  rowExpandable?: (record: RecordType) => boolean;
  expandedRowRender?: (record: RecordType) => React.ReactNode;
  onExpand: () => void;
  onCollapse: () => void;
  isExpanded: boolean;
  rowClassName?: (record: RecordType) => string;
}

export const LazyTableRow: React.FC<Props> = ({
  columns,
  columnWidths,
  row,
  style,
  rowExpandable,
  expandedRowRender,
  onExpand,
  onCollapse,
  isExpanded,
  rowClassName,
}) => {
  const { choose } = useTheme();

  const expandable = useMemo(() => {
    return rowExpandable?.(row) || false;
  }, [row, rowExpandable]);

  const className = useMemo(() => {
    if (!rowClassName) return '';

    return rowClassName(row);
  }, [row, rowClassName]);

  const expandButtonBody = useMemo(() => {
    if (!expandable) return null;

    if (isExpanded) {
      return (
        <MinusSquareFilled
          className="text-blue-400 bg-opacity-0"
          onClick={() => {
            onCollapse();
          }}
          style={{ fontSize: '16px' }}
        />
      );
    }

    return (
      <PlusSquareFilled
        className="text-blue-400 bg-opacity-0"
        onClick={() => {
          onExpand();
        }}
        style={{ fontSize: '16px' }}
      />
    );
  }, [expandable, isExpanded, onExpand, onCollapse]);

  return (
    <div
      className={['flex flex-col border-b', className, choose('border-gray-700', 'border-gray-100')].join(' ')}
      style={style}
    >
      <div className="flex flew-row h-full py-4">
        {rowExpandable && expandedRowRender && (
          <div style={{ minWidth: 30, maxWidth: 30 }} className="flex justify-center items-center">
            {expandButtonBody}
          </div>
        )}
        {columns.map((column, index) => (
          <LazyTableListViewRowColumn
            key={index}
            column={column}
            index={index}
            row={row}
            width={columnWidths[index]}
            breakWord={column.breakWord}
          />
        ))}
      </div>
      {expandable && isExpanded && expandedRowRender?.(row)}
    </div>
  );
};
