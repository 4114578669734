import React, { useMemo, useState } from 'react';
import { Button, Drawer, Form, Space, Spin, App } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Dayjs } from 'dayjs';

import { ICreateOwnerParams } from 'modules/owners/store/owners/types';
import { createOwner } from 'modules/owners/store/owners/actions';

import { useAgentsFromServer, useBanksFromServer } from 'hooks/useDataFromServer';
import { GoipCascader } from '../GoipCascader/GoipCascader';
import { useTheme } from 'contexts';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { Input, InputNumber } from 'components/Input';
import { SwitchField } from 'components/SwitchField';
import { LazySelect } from 'components/LazySelect';

interface Props {
  isShown: boolean;
  onCancel: () => void;
}

const OWNER_DISPUTE_DEFAULT_LIMIT = 5;

export const OwnersPageCreateDrawer: React.FC<Props> = ({ isShown, onCancel }) => {
  const [isCreatingInProgress, setIsCreatingInProgress] = useState<boolean>(false);

  const {
    isDarkMode,
    currentTheme: {
      token: { colorBgLabelInput, colorBorderInput, colorTextLight },
    },
  } = useTheme();
  const { message } = App.useApp();

  const [providers] = useBanksFromServer();

  const isAllowedSbp = useMemo(() => {
    return providers.filter((provider) => provider.currencyCode === 'RUB').map((provider) => provider.id);
  }, []);

  const [form] = useForm<Omit<ICreateOwnerParams, 'bith'> & { bith: Dayjs }>();

  const dispatch = useAppDispatch();

  function clearFormValues(): void {
    form.resetFields();
    form.setFieldsValue({
      outcommingTransactionsIsAllowed: false,
      incommingTransactionsIsAllowed: false,
      ping: false,
      isDeposit: false,
      isAgent: false,
    });
  }

  async function handleSumbitPress() {
    setIsCreatingInProgress(true);

    const values = form.getFieldsValue();

    const params: ICreateOwnerParams = {
      ...values,
      bith: values.bith?.format('YYYY-MM-DD'),
    };

    if (!params.agentId) delete params.agentId;
    if (!params.mainPhone) delete params.mainPhone;

    const isSuccess = await dispatch(createOwner(params));

    setIsCreatingInProgress(false);

    if (isSuccess) {
      message.success('Владелец успешно создан');

      clearFormValues();
      onCancel();
      return;
    }

    message.error('Не удалось создать владельца.');
  }

  function handleClose() {
    clearFormValues();
    onCancel();
  }

  return (
    <>
      <Drawer title="Создать нового владельца" open={isShown} onClose={handleClose}>
        <Spin tip="Идет создание владельца..." spinning={isCreatingInProgress} size="large">
          <Form form={form} component={false}>
            <Space direction="vertical" size="middle">
              <Input placeholder="ФИО владельца" addonBefore="ФИО владельца" className="w-full" name="fio" />
              <InputNumber
                placeholder="Лимит исходящих"
                addonBefore="Лимит исходящих"
                isAmountInput
                className="w-full"
                name="limitOut"
              />
              <Input placeholder="Введите @username" addonBefore="Ник" className="w-full" name="tgNick" />
              <Input placeholder="Введите IMEI устройства" addonBefore="IMEI" className="w-full" name="imei" />
              <Input placeholder="Введите Логин от Банка" addonBefore="Логин" className="w-full" name="login" />
              <Input placeholder="Введите Пароль от банка" addonBefore="Пароль" className="w-full" name="password" />
              <div className="flex items-center">
                <div
                  className={`px-2 py-1 border border-solid rounded-tl-md rounded-bl-md ${
                    isDarkMode
                      ? 'border-gray-500 bg-opacity-0 text-white text-opacity-85'
                      : 'border-hmGray bg-grayTrue-50'
                  }`}
                >
                  Имя шлюза
                </div>
                <Form.Item name="goip" noStyle>
                  <GoipCascader isShown={isShown} hasLabel={false} />
                </Form.Item>
              </div>
              <Input placeholder="ID чата" addonBefore="ID чата" className="w-full" name="chatId" />
              {isAllowedSbp && (
                <SwitchField defaultValue={false} name="isSbpEnabled">
                  Работа с СБП
                </SwitchField>
              )}
              <Form.Item name="mainPhone" noStyle>
                <Input placeholder="Основной телефон" addonBefore="Основной телефон" />
              </Form.Item>
              <Form.Item name="secondaryPhone" noStyle>
                <Input placeholder="Доп. телефон" addonBefore="Доп. телефон" />
              </Form.Item>
              <div className="flex items-center">
                <div
                  className="px-2 py-1 border border-solid whitespace-nowrap rounded-l-md"
                  style={{
                    backgroundColor: colorBgLabelInput,
                    borderColor: colorBorderInput,
                    color: colorTextLight,
                  }}
                >
                  Имя агента
                </div>
                <Form.Item name="agentId" noStyle>
                  <LazySelect
                    allowClear
                    placeholder="Имя агента"
                    className="w-full"
                    useDataHook={useAgentsFromServer}
                    cacheKey="agents-select"
                  />
                </Form.Item>
              </div>
              <Form.Item name="disputesLimit" initialValue={OWNER_DISPUTE_DEFAULT_LIMIT} noStyle>
                <InputNumber
                  placeholder="Лимит по диспутам"
                  addonBefore="Лимит по диспутам"
                  className="w-full"
                  name="disputesLimit"
                />
              </Form.Item>
              <Form.Item name="priority" noStyle>
                <InputNumber
                  placeholder="Приоритет входящих заявок"
                  addonBefore="Приоритет входящих заявок"
                  className="w-full"
                />
              </Form.Item>
              <Form.Item name="outcomingOrdersPriority" initialValue={1} noStyle>
                <InputNumber
                  placeholder="Приоритет исходящих заявок"
                  addonBefore="Приоритет исходящих заявок"
                  className="w-full"
                />
              </Form.Item>
              <Form.Item name="trustLimit" initialValue={1000000}>
                <InputNumber addonBefore="Лимит доверия" className="w-full" isAmountInput />
              </Form.Item>
              <SwitchField name="isDeposit">Является депозитником</SwitchField>
              <SwitchField name="ping" defaultValue={false}>
                Пинг
              </SwitchField>
              <SwitchField name="incommingTransactionsIsAllowed">Входящие транзакции разрешены</SwitchField>
              <SwitchField name="outcommingTransactionsIsAllowed">Исходящие транзакции разрешены</SwitchField>
              <SwitchField name="isAutoAssigmentEnabled" defaultValue={true}>
                Участвует в автоматическом распределении заявок
              </SwitchField>
              <SwitchField name="trusted" defaultValue={false}>
                Доверенный
              </SwitchField>
              <Button htmlType="submit" onClick={handleSumbitPress} className="w-full" type="primary">
                Создать
              </Button>
            </Space>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};
