import React, { useEffect, useRef } from 'react';
import { LazyTableHeader } from 'components/LazyTable/components/ListView/components/Header';
import { LazyTableListRenderer } from 'components/LazyTable/components/ListView/components/ListRenderer';
import { LazyTableRow } from 'components/LazyTable/components/ListView/components/Row';
import { CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { LazyTableColumnsType } from 'components/LazyTable/types/lazyTableColumns.type';

interface Props<RecordType = any> {
  data: RecordType[];
  columns: LazyTableColumnsType<RecordType>;
  total: number;
  onLoadMore: () => Promise<void>;
  isLoading: boolean;
  rowExpandable?: (record: RecordType) => boolean;
  expandedRowRender?: (record: RecordType) => React.ReactNode;
  rowClassName?: (record: RecordType) => string;
  rowKey?: (record: RecordType, index?: number) => string | number;
}

export const LazyTableListView: React.FC<Props> = ({
  data,
  columns,
  total,
  onLoadMore,
  isLoading,
  rowExpandable,
  expandedRowRender,
  rowClassName,
  rowKey,
}) => {
  const [columnSizes, setColumnSizes] = React.useState<{ [key: string]: number }>({});
  const [expandedIndexes, setExpandedIndexes] = React.useState<(number | string)[]>([]);

  const cache = useRef(new CellMeasurerCache({ fixedWidth: true }));
  const listRef = useRef<List | null>(null);
  const expandedMap = useRef<{ [index: number]: boolean }>({});
  const recalculatedAtTotalMap = useRef<{ [index: number]: number }>({});

  useEffect(() => {
    document.addEventListener('visibilitychange', handleChangedVisibility);

    return () => {
      document.removeEventListener('visibilitychange', handleChangedVisibility);
    };
  }, []);

  function handleChangedVisibility() {
    if (document.visibilityState !== 'visible') return;

    recalculateAll();
  }

  function handleExpand(index: number) {
    setExpandedIndexes((state) => [...state, getRowKey(data[index], index)]);

    recalculateRowHeights(index);
  }

  function handleCollapse(index: number) {
    setExpandedIndexes((state) => state.filter((el) => el !== getRowKey(data[index], index)));

    recalculateRowHeights(index);
  }

  function recalculateAll(): void {
    cache.current.clearAll();
    listRef.current?.recomputeRowHeights();
  }

  function recalculateRowHeights(index: number) {
    cache.current.clear(index, 0);
    listRef.current?.recomputeRowHeights(index);
  }

  function getRowKey(record: any, index: number) {
    if (rowKey) {
      return 'row-key-' + rowKey(record, index);
    }

    return index;
  }

  return (
    <div className="w-full mb-10 big-table">
      <LazyTableHeader
        columns={columns}
        isExpandable={!!rowExpandable && !!expandedRowRender}
        onColumnResize={(index, width) => {
          setColumnSizes((state) => ({ ...state, [index]: width }));
          recalculateAll();
        }}
      />
      <LazyTableListRenderer
        data={data}
        listRef={listRef}
        cellMeasurerCache={cache.current}
        rowRenderer={({ index, parent, style, key }) => {
          const isExpanded = expandedIndexes.includes(getRowKey(data[index], index));

          const previousExpanded = expandedMap.current[index];
          const recalculatedAtTotal = recalculatedAtTotalMap.current[index];

          if (previousExpanded !== isExpanded) {
            // TODO: Вызывает "Cannot update during an existing state transition", нужно исправить
            recalculateRowHeights(index);
          }

          if (recalculatedAtTotal !== total) {
            // TODO: При добавлении нового элемента через web sockets происходит
            // перерасчет высоты всех элементов для корректного отображения, возможны проблемы с перформансом
            recalculateRowHeights(index);
            recalculatedAtTotalMap.current[index] = total;
          }

          expandedMap.current = { ...expandedMap.current, [index]: isExpanded };

          return (
            <CellMeasurer cache={cache.current} columnIndex={0} rowIndex={index} parent={parent} key={key}>
              <LazyTableRow
                columnWidths={columnSizes}
                columns={columns}
                row={data[index]}
                style={style}
                rowExpandable={rowExpandable}
                expandedRowRender={expandedRowRender}
                onExpand={() => handleExpand(index)}
                onCollapse={() => handleCollapse(index)}
                isExpanded={isExpanded}
                rowClassName={rowClassName}
              />
            </CellMeasurer>
          );
        }}
        total={total}
        onLoadMore={onLoadMore}
        isLoading={isLoading}
      />
    </div>
  );
};
