import instance from 'utils/axios';

export interface CreateCardRequestParams {
  accountId: number;
  cardNumber: string;
}

export async function createCardRequest(params: CreateCardRequestParams) {
  await instance.post('/cards/create', params);
}
