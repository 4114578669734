import { CustomThemeConfig } from 'app/theme/customThemeConfig.interface';

export const darkTheme: CustomThemeConfig = {
  token: {
    colorPrimary: '#008ecc',
    colorBgBody: '#12171a',
    colorTextBase: '#ffffff',
    colorBgBase: '#202528',
    colorBgContainer: '#202528',
    colorBgTableHeader: '#292e31',
    colorBorderTable: '#4b575e',
    colorBgBlue: '#101d25',
    colorBorderInput: '#5d666b',
    colorBgLabelInput: '#434a50',
    colorTextLight: '#ffffffd9',
    colorBgBalance: '#5d666b',
  },
};
