import { useMemo } from 'react';

import { App } from 'antd';

import { MerchantChat } from 'modules/merchants/types';
import { ICurrency } from 'types/currency.interface';

interface useLogicChatsOptionsProps {
  chats: MerchantChat[];
  onChatsChange: (e: React.SetStateAction<MerchantChat[]>) => void;
  currencies: ICurrency[];
}

export const useLogicChatsOptions = ({ chats, onChatsChange, currencies }: useLogicChatsOptionsProps) => {
  const initialCurrencySelectOptions = currencies.map((el) => ({ value: el.id, label: el.name }));

  const { message } = App.useApp();

  function onCustomerChatChange(index: number, key: string, value: number) {
    const newChats = chats;
    newChats[index] = {
      ...newChats[index],
      [key]: value,
    };

    onChatsChange(newChats);
  }

  function addChat(): void {
    const emptyChat: MerchantChat = {
      currencyId: undefined,
      incomingChatId: undefined,
      outcomingChatId: undefined,
    };

    const hasNotSelectedCurrency = chats.some((chat) => !chat.currencyId);
    const allCurrenciesIsUsed = initialCurrencySelectOptions.length === chats.length;

    if (hasNotSelectedCurrency) {
      message.error('Выберите валюту для предыдущего чата');
      return;
    }

    if (allCurrenciesIsUsed) {
      message.error('Чаты для всех валют уже созданы');
      return;
    }

    onChatsChange((chats) => [...chats, emptyChat]);
  }

  const currencySelectOptions = useMemo(() => {
    const disabledCurrenciesId = (chats || []).map((chat) => chat.currencyId);

    return initialCurrencySelectOptions.map((option) => ({
      ...option,
      disabled: disabledCurrenciesId.includes(option.value),
    }));
  }, [chats, initialCurrencySelectOptions]);

  function removeChat(index: number): void {
    if (chats.length <= 1) {
      message.error('Клиент обязан иметь хотя бы один чат с валютой');
      return;
    }

    onChatsChange((chats) => chats.filter((_, i) => i !== index));
  }

  return {
    currencySelectOptions,
    onCustomerChatChange,
    addChat,
    removeChat,
  };
};
