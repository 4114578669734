import React from 'react';
import 'regenerator-runtime/runtime';
import { App as AntdApp } from 'antd';
import api from 'utils/axios';

import { AppRouter } from 'app/navigation';

import 'app/styles.scss';
import 'app/theme/customTheme.scss';
import { Spin } from 'antd';

import { ThemeConfigProvider } from 'app/providers/themeConfig.provider';
import { SWRConfig } from 'swr';
import store from 'app/store';
import { ThemeContextProvider } from 'contexts';
import { Provider } from 'react-redux';
import './initializers/sentry.initializer';

const App = () => {
  return (
    <React.Suspense fallback={<Spin />}>
      <Provider store={store}>
        <ThemeContextProvider>
          <ThemeConfigProvider>
            <AntdApp>
              <SWRConfig value={{ fetcher: (url) => api.get(url) }}>
                <AppRouter />
              </SWRConfig>
            </AntdApp>
          </ThemeConfigProvider>
        </ThemeContextProvider>
      </Provider>
    </React.Suspense>
  );
};

export default App;
