import { Dispatch } from 'redux';
import axios from 'axios';

import instance from 'utils/axios';
import {
  ILoginParams,
  ILoginAuthReducerAction,
  ILoginPayload,
  AuthReducerActionTypes,
  IRefreshMeAuthReducerAction,
} from 'modules/auth/store/auth/types';

export function login({ email, password }: ILoginParams) {
  return async (dispatch: Dispatch) => {
    await instance.get(import.meta.env.VITE_API_URL + '/sanctum/csrf-cookie', {
      withCredentials: true,
    });

    const {
      data: { data },
    } = await axios.post(
      import.meta.env.VITE_API_URL + '/api/login',
      {
        email,
        password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        },
        xsrfHeaderName: 'X-XSRF-TOKEN',
        withCredentials: true,
      }
    );

    dispatch<ILoginAuthReducerAction>({
      type: AuthReducerActionTypes.LOGIN,
      payload: data as ILoginPayload,
    });
  };
}

export function logout() {
  return (dispatch: Dispatch) => {
    dispatch({ type: AuthReducerActionTypes.LOGOUT });
  };
}

export function refreshMe() {
  return async (dispatch: Dispatch) => {
    try {
      const {
        data: { data },
      } = await instance.get('/users/current');

      dispatch<IRefreshMeAuthReducerAction>({
        type: AuthReducerActionTypes.REFRESH_ME,
        payload: data,
      });
    } catch {
      // TODO: Ошибка не требует дополнительной обработки
      // добавить уведомление об ошибке при необходимости
    }
  };
}
