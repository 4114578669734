import { App } from 'antd';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { getLinkedToOperatorStatus } from 'modules/orders/pages/active/components/RowActions/components/BindOperatorToOrder/utils';
import { bindOperator } from 'modules/orders/store/activeOrders/actions';

export function useBindOperator() {
  const { modal, message } = App.useApp();
  const { user } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  async function bindOperatorCheck(
    orderId: number,
    callback: ((...args: any[]) => void) | null = null,
    args: any[] | null = null,
    shouldBind = false
  ): Promise<void> {
    const operatorId = await getLinkedToOperatorStatus(orderId);

    const linktedToMe = operatorId === user?.id;
    const noOneLinked = !operatorId;
    if (linktedToMe && shouldBind) {
      if (callback) {
        null === args ? callback() : callback(...args);
      }

      return;
    }

    if (noOneLinked && shouldBind) {
      dispatch(bindOperator(orderId)).then(() => {
        if (callback) {
          null === args ? callback() : callback(...args);
        }
      });

      return;
    }

    const linkedToAnother = user?.id !== operatorId && Number.isInteger(operatorId);
    if (linkedToAnother) {
      modal.confirm({
        title: 'Данная заявка привязана к другому оператору',
        content: 'Вы уверены, что хотите перепривязать заявку на себя?',
        onOk: () => {
          dispatch(bindOperator(orderId))
            .then(() => {
              message.success('Заявка к вам привязана');

              if (callback) {
                null === args ? callback() : callback(...args);
              }
            })
            .catch(() => message.error('Не удалось привязать заявку'));
        },
        okText: 'Привязать',
        cancelText: 'Отмена',
      });

      return;
    }

    if (null === callback) {
      try {
        await dispatch(bindOperator(orderId));

        message.success('Заявка к вам привязана');
      } catch {
        message.error('Не удалось привязать заявку');
      }
    } else {
      null === args ? callback() : callback(...args);
    }
    return;
  }

  return [bindOperatorCheck, modal] as const;
}
