import instance from 'utils/axios';

export interface UpdateBankRequestParams {
  id: number;
  name: string;
  nameEn: string;
  enabled: boolean;
  internal: boolean;
  countryCode: string;
  currencyCode: string;
  imageUrl: string;
  backgroundColors?: [string, string];
  createdAt?: string;
  updatedAt?: string;
  logoBigSizeUrl?: string;
  logoSmallSizeUrl?: string;
  logoInvertBigSizeUrl?: string;
  logoInvertSmallSizeUrl?: string;
}

export async function updateBankRequest(params: UpdateBankRequestParams) {
  await instance.post('/banks/update', params);
}
