import { IMetaPagination } from 'types/IMetaPagination';

import { IProvider } from 'types/IProvider';
import { OrderTypes } from 'modules/orders/enums/orderTypes.enum';
import { ReducerAction } from 'types/reducerAction.interface';

export interface IOwnersSmsState {
  sms: IOwnerSms[];
  pagination: IMetaPagination;
  isLoading: boolean;
}

export interface IOwnerSms {
  id: number;
  message: string;
  ownerId: number;
  sum: number;
  provider: IProvider;
  paymentType?: number;
  currentBalance?: number;
  partCardNumber?: string;
  partAccountNumber?: string;
  time?: string;
  createdAt: string;
  orderId?: number;
  commission?: number;
}

export interface IFetchSmsOwnersSmsParams {
  ownerId?: number;
  amountFrom?: number;
  amountTo?: number;
  bankId?: number;
  createdAtFrom?: string;
  createdAtTo?: string;
  type?: OrderTypes;
  hasLinkedTransaction?: boolean;
}

export interface IFetchSmsOwnersSmsReducerActionPayload {
  sms: IOwnerSms[];
  pagination: IMetaPagination;
}

export interface IChangePaginationOwnersSmsReducerActionPayload {
  page: number;
  perPage: number;
}

export type IFetchSmsOwnersSmsReducerAction = ReducerAction<
  IFetchSmsOwnersSmsReducerActionPayload,
  OwnersSmsActionsTypes.FETCH_SMS
>;

export type ISetIsLoadingOwnersSmsReducerAction = ReducerAction<boolean, OwnersSmsActionsTypes.SET_IS_LOADING>;

export type IChangePaginationOwnersSmsReducerAction = ReducerAction<
  IChangePaginationOwnersSmsReducerActionPayload,
  OwnersSmsActionsTypes.CHANGE_PAGINATION
>;

export type OwnersSmsReducerActions =
  | IFetchSmsOwnersSmsReducerAction
  | ISetIsLoadingOwnersSmsReducerAction
  | IChangePaginationOwnersSmsReducerAction;

export enum OwnersSmsActionsTypes {
  FETCH_SMS = 'FETCH_SMS',
  SET_IS_LOADING = 'SET_IS_SMS_LOADING',
  CHANGE_PAGINATION = 'CHANGE_SMS_PAGINATION',
}
