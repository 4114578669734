/**
 * Перечисление валют, доступных в системе
 *
 * @author Рябущиц Иван <telegram: @ivangeli0n>
 */
export enum CurrencyEnum {
  RUB = 1,
  UAH = 2,
  KZT = 3,
  UZS = 4,
  THB = 5,
}

export enum CurrencyNameEnum {
  RUB = 'RUB',
  UAH = 'UAH',
  KZT = 'KZT',
  UZS = 'UZS',
  THB = 'THB',
  INR = 'INR',
}
