import React, { useMemo } from 'react';
import { Cascader, Divider, Tag } from 'antd';

import { IFetchOwnersParams } from 'modules/owners/store/owners/types';

import { useGoIpsFromServer } from 'hooks/useDataFromServer';

import { goipSearchfilter } from './GoipCascader';

interface Props {
  params: IFetchOwnersParams;
  onChange: (values: IFetchOwnersParams) => void;
}

export const OwnersPageGoipCascader: React.FC<Props> = ({ params, onChange: onChangeCallback }) => {
  const [goipNames] = useGoIpsFromServer();

  const goips = useMemo(() => params.goips, [params.goips]);
  const gatewayByChannelId = useMemo(() => {
    const map = new Map();

    goipNames.forEach((goip) => {
      goip.channels.forEach((channelid) => map.set(channelid, goip.name));
    });

    return map;
  }, [goipNames]);

  const options = useMemo(() => {
    return goipNames
      .sort((a, b) => parseInt(a.name) - parseInt(b.name))
      .map((goip) => ({
        label: goip.name,
        value: goip.name,
        children: goip.channels.map((channel) => ({
          value: channel,
          label: channel,
        })),
      }));
  }, [goipNames, goips]);

  const selectedOptions = useMemo(() => {
    if (!goips || !goipNames || gatewayByChannelId.size === 0) return [];

    return goips.map((channelId) => {
      if (isNaN(Number(channelId))) {
        return [channelId.slice(0, 3), Number(channelId.slice(3))].filter(Boolean);
      }

      const name = gatewayByChannelId.get(Number(channelId));

      return [name, Number(channelId)];
    });
  }, [goipNames, goips, gatewayByChannelId]);

  function onChange(newValue: (string | number)[][] | string) {
    if (!Array.isArray(newValue)) return;

    const cloneValue = structuredClone(newValue);
    const channelIds = cloneValue.map((option) => option.join('')).flat();

    onChangeCallback({ ...params, goips: channelIds });
  }

  return (
    <>
      <Cascader
        multiple
        maxTagCount={5}
        placeholder="Шлюзы"
        size="middle"
        showCheckedStrategy={Cascader.SHOW_PARENT}
        style={{
          minWidth: '90px',
          maxWidth: '100%',
        }}
        className="w-max max-h-8"
        options={options}
        value={selectedOptions}
        tagRender={(props) => <Tag {...props}>{(props.value as string).replace('__RC_CASCADER_SPLIT__', '')}</Tag>}
        dropdownRender={(menus) => (
          <div>
            {menus}
            <Divider
              style={{
                margin: 0,
              }}
            />
          </div>
        )}
        showSearch={{ filter: goipSearchfilter }}
        onChange={onChange}
      />
    </>
  );
};
