import React, { useMemo } from 'react';
import { Statistic, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { ActiveOrdersPageColumnsViewProviderImage } from './components/ColumnsView/components/ProviderImage';
import { ActiveOrdersPageColumnsViewTransferredSum } from './components/ColumnsView/components/TransferredSum';
import { ChangeStatusOfActiveOrderStatusesEnum } from 'modules/orders/enums/changeStatusOfActiveOrderStatuses.enum';
import { ActiveOrder } from 'modules/orders/store/activeOrders/interfaces/activeOrder.interface';
import { Order } from 'modules/orders/types/order.interface';
import { OrdersPageTableIconCellType } from 'modules/orders/pages/list/components/Table/components/IconCellType';
import { ActiveOrdersPageTableRowActions } from 'modules/orders/pages/active/components/RowActions';
import { LazyTableColumnsType } from 'components/LazyTable/types/lazyTableColumns.type';
import { EXPIRED_ORDER_TIME, ORDER_DATETIME_STRING_FORMAT } from 'constants/dates';

dayjs.extend(utc);

interface IMakeTableColumnsConfig {
  onChangeStatusPress: (status: ChangeStatusOfActiveOrderStatusesEnum, id: number) => void;
  onUnknownOrderPress: (order: ActiveOrder) => void;
  onAssignOwnerForOrderPress: (order: ActiveOrder) => void;
  onCommissionSetForOrderPress: (order: Order, isPartly: boolean) => void;
  onDisableAutoAssignmentPress: (order: ActiveOrder) => void;
  onOutcomingOrderConfirmPress: (order: ActiveOrder) => void;
}

export function useTableColumns({
  onChangeStatusPress,
  onUnknownOrderPress,
  onAssignOwnerForOrderPress,
  onCommissionSetForOrderPress,
  onDisableAutoAssignmentPress,
  onOutcomingOrderConfirmPress,
}: IMakeTableColumnsConfig): LazyTableColumnsType<ActiveOrder> {
  return useMemo(() => {
    return [
      {
        title: 'Наш ID',
        dataIndex: 'id',
        width: 90,
        key: 'id',
      },
      {
        title: 'Время создания заявки (местное)',
        key: 'createdAt',
        width: 70,
        dataIndex: 'createdAt',
        render: (_, { createdAt, owners }) => {
          const isExpired = dayjs().diff(dayjs(createdAt), 'millisecond') > EXPIRED_ORDER_TIME && !owners.length;
          return (
            <Typography.Text className={`${isExpired && 'text-red-300'}`} style={{ position: 'relative' }}>
              {dayjs.utc(createdAt).local().format(ORDER_DATETIME_STRING_FORMAT)}
            </Typography.Text>
          );
        },
      },
      {
        title: 'ID транзакции',
        dataIndex: 'customerTransactionId',
        key: 'customerTransactionId',
        render: (value: string) => {
          return (
            <Typography.Text copyable style={{ position: 'relative' }}>
              {value}
            </Typography.Text>
          );
        },
      },
      {
        title: 'Клиент',
        dataIndex: ['customer', 'name'],
        key: 'customerName',
      },
      {
        title: 'Тип',
        dataIndex: 'type',
        width: 40,
        key: 'type',
        render: (type: number) => <OrdersPageTableIconCellType typeValue={type} />,
      },
      {
        title: 'Владельцы',
        key: 'agent',
        render: (_, { owners }) => {
          return (
            <div className="flex flex-1 flex-wrap flex-row justify-center">
              {owners.map(({ ownerId }) => (
                <Tag key={ownerId} color="blue">
                  {ownerId}
                </Tag>
              ))}
            </div>
          );
        },
      },
      {
        title: 'Переведено',
        width: '10%',
        dataIndex: 'amount',
        key: 'amount',
        render: (_, { type, sumTransferred, realSum }) => {
          return (
            <ActiveOrdersPageColumnsViewTransferredSum type={type} orderSum={realSum} sumTransferred={sumTransferred} />
          );
        },
      },
      {
        title: 'Сумма',
        width: '8%',
        align: 'center',
        dataIndex: 'amount',
        key: 'amount',
        render: (_, { amount }) => {
          return <Statistic valueStyle={{ fontSize: 16 }} groupSeparator={' '} value={amount} />;
        },
      },
      {
        title: 'Банк',
        dataIndex: ['provider'],
        width: 55,
        key: 'providerName',
        render: (_, { provider, isSbp }) => (
          <ActiveOrdersPageColumnsViewProviderImage provider={provider} isSbp={isSbp} />
        ),
      },
      {
        title: '№ карты/телефона/счета',
        dataIndex: 'customerCard',
        width: '11%',
        key: 'customerCard',
        render: (_, { customerCard, customerPhone, account }) => {
          return <>{customerCard ?? customerPhone ?? account ?? 'Нет данных'}</>;
        },
      },
      {
        title: 'ФИО плательщика',
        dataIndex: 'customerName',
        width: '7%',
        breakWord: false,
        key: 'customerName',
      },
      {
        title: 'Действия',
        width: 200,
        key: 'actions',
        render: (_, record) => {
          return (
            <ActiveOrdersPageTableRowActions
              order={record}
              onChangeStatusPress={onChangeStatusPress}
              onUnknownOrderPress={onUnknownOrderPress}
              onAssignOwnerForOrderPress={onAssignOwnerForOrderPress}
              onCommissionSetForOrderPress={onCommissionSetForOrderPress}
              onDisableAutoAssignmentPress={onDisableAutoAssignmentPress}
              onConfirmOutcommingPress={onOutcomingOrderConfirmPress}
            />
          );
        },
      },
    ];
  }, []);
}
