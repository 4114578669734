import { ReducerAction } from 'types/reducerAction.interface';

export interface IConfigurationState {
  configuration: { [key: string]: IConfigurationValue };
  isLoading: boolean;
}

export interface IConfigurationValue {
  title: string;
  type: ConfigurationValueTypes;
  value?: ConfigurationValueValueType;
  children?: { [key: string]: IConfigurationValue };
  available_values_list?: {
    key: string;
    model: string;
  };
}

export type ConfigurationValueValueType = string | number | Array<string> | boolean;

export enum ConfigurationValueTypes {
  INT = 'int',
  FLOAT = 'float',
  STRING = 'string',
  ARRAY = 'array',
  BOOL = 'bool',
}

export type IFetchConfigurationReducerAction = ReducerAction<
  { [key: string]: IConfigurationValue },
  ConfigurationActionTypes.FETCH_CONFIGURATION
>;

export type ISetIsLoadingConfigurationReducerAction = ReducerAction<boolean, ConfigurationActionTypes.SET_IS_LODAING>;

export type IUpdateConfigurationValueReducerAction = ReducerAction<
  null,
  ConfigurationActionTypes.UPDATE_CONFIGURATION_VALUE
>;

export type ConfigurationReducerActions =
  | IFetchConfigurationReducerAction
  | ISetIsLoadingConfigurationReducerAction
  | IUpdateConfigurationValueReducerAction;

export enum ConfigurationActionTypes {
  FETCH_CONFIGURATION = 'FETCH_CONFIGURATION',
  UPDATE_CONFIGURATION_VALUE = 'UPDATE_CONFIGURATION_VALUE',
  SET_IS_LODAING = 'SET_IS_LOADING',
}
