import React, { useEffect, useState } from 'react';
import { Divider } from 'antd/es';
import { App, Button, Drawer, Form, Input, Space, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { SwitchField } from 'components/SwitchField';
import { InputNumber } from 'components/Input';

import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { BackendResponse } from 'types/BackendResponse';

import api from 'utils/axios';
import { UpdateMerchantParams } from 'modules/merchants/store/merchants/interfaces/updateMerchantParams.interface';

import { updateMerchant } from 'modules/merchants/store/merchants/actions';
import { MerchantChat, MerchantDetails } from 'modules/merchants/types';
import { MerchantTariffTypesEnum } from 'modules/merchants/enums';
import { MerchantChatsForm } from '../MerchantChatsForm';

interface Props {
  merchantId: number | undefined;
  onCancel: () => void;
}

export const EditMerchantDrawer: React.FC<Props> = ({ merchantId, onCancel }) => {
  const [form] = useForm<UpdateMerchantParams>();

  const { message } = App.useApp();

  const [customerChats, setCustomerChats] = useState<MerchantChat[]>([]);
  const [isEditingInProgress, setIsEditingInProgress] = useState<boolean>(false);
  const [isMerchantInfoLoading, setIsMerchantInfoLoading] = useState<boolean>(false);

  const [merchant, setMerchant] = useState<MerchantDetails>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!merchant) {
      setMerchant(undefined);

      return;
    }
    const C2CTariff = merchant.tariffs.find((tariff) => tariff.type === MerchantTariffTypesEnum.C2C);

    form.setFieldsValue({
      ...merchant,
      c2cMinOrderAmount: C2CTariff?.minOrderAmount,
      c2cIsActive: C2CTariff?.isActive,
    });
  }, [form, merchant]);

  useEffect(() => {
    setCustomerChats(merchant?.chats ?? []);
  }, [merchant?.chats]);

  useEffect(() => {
    merchantId && fetchMerchantInfo();
  }, [merchantId]);

  async function fetchMerchantInfo() {
    setIsMerchantInfoLoading(true);

    const {
      data: { data },
    } = await api.get<BackendResponse<MerchantDetails>>('/merchants/info', {
      params: { id: merchantId },
    });

    setMerchant(data);

    setIsMerchantInfoLoading(false);
  }

  async function onHandleSubmit() {
    setIsEditingInProgress(true);

    const params = form.getFieldsValue();

    if (!params.unpaidOrdersLimitByOwner) {
      params.unpaidOrdersLimitByOwner = 5;
    }

    params.chats = customerChats;

    const isSuccess = await dispatch(updateMerchant(params));

    setIsEditingInProgress(false);

    if (isSuccess) {
      message.success('Клиент успешно изменен');

      onCancel();
      return;
    }

    message.error('Не удалось изменить клиента.');
  }

  function closeModal() {
    onCancel();
    setMerchant(undefined);
  }

  return (
    <Drawer size="large" title="Редактирование клиента" open={!!merchantId} onClose={closeModal}>
      <Spin
        tip={isEditingInProgress && 'Клиент в процессе редактирования...'}
        spinning={isEditingInProgress || isMerchantInfoLoading}
        size="large"
      >
        <Form initialValues={merchant} form={form} name="editCustomerForm" component={false}>
          <Form.Item hidden name="id">
            <Input type="hidden" />
          </Form.Item>
          <div className="flex items-baseline">
            <label className="w-48">Наименование</label>
            <Form.Item className="w-full" name="name">
              <Input />
            </Form.Item>
          </div>
          <div className="flex items-baseline">
            <label className="w-48">Алиас</label>
            <Form.Item className="w-full" name="aliasOrder">
              <Input />
            </Form.Item>
          </div>
          <div className="flex items-baseline">
            <label className="w-48">Колбэк URL</label>
            <Form.Item className="w-full" name="callbackUrl">
              <Input />
            </Form.Item>
          </div>
          <div className="flex items-baseline">
            <label className="w-48">Лимит неоплаченных заявок</label>
            <InputNumber name="unpaidOrdersLimitByOwner" className="w-full mb-6" />
          </div>
          <div className="flex items-baseline">
            <label className="w-48">Время жизни входящей заявки (мин.)</label>
            <InputNumber name="incomingOrderLifetime" className="w-full mb-6" />
          </div>
          <div className="flex items-baseline">
            <label className="w-48">Овердрафт</label>
            <InputNumber name="overdraft" isAmountInput className="w-full mb-6" />
          </div>
          <div className="flex items-baseline">
            <label className="w-48">Минимальная сумма C2C</label>
            <InputNumber name="c2cMinOrderAmount" isAmountInput className="w-full mb-6" />
          </div>
          <Space direction="vertical" size="middle">
            <SwitchField defaultValue={merchant?.outcomingOrdersPriority ?? false} name="outcomingOrdersPriority">
              Включить приоритет исходящих заявок
            </SwitchField>
            <SwitchField defaultValue={merchant?.combineBalance} name="combineBalance">
              Объединение балансов
            </SwitchField>
            <SwitchField defaultValue={merchant?.isAutoAssigmentEnabled} name="isAutoAssigmentEnabled">
              Автоматическое распределение заявок клиента включено
            </SwitchField>
            <SwitchField name="c2cIsActive">Прием C2C</SwitchField>
            <SwitchField defaultValue={!!merchant?.isHighRisk} name="isHighRisk">
              High risk
            </SwitchField>
          </Space>
          <Divider />
          <MerchantChatsForm customerChats={customerChats} onCustomerChatsChange={setCustomerChats} />
          <div>
            <Divider style={{ margin: '12px 0' }} />
            <Button onClick={onHandleSubmit} className="w-full" type="primary">
              Сохранить
            </Button>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
};
