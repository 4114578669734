import { User } from 'modules/users/types/user.interface';
import { ReducerAction } from 'types/reducerAction.interface';

export interface ILoginParams {
  email: string;
  password: string;
}

export interface IAuthState {
  isAuth: boolean;
  token?: string;
  user?: User;
}

export interface ILoginPayload {
  access_token: string;
  token_type: string;
  user: User;
}

export type ILoginAuthReducerAction = ReducerAction<ILoginPayload, AuthReducerActionTypes.LOGIN>;

export type ILogoutAuthReducerAction = ReducerAction<undefined, AuthReducerActionTypes.LOGOUT>;

export type IRefreshMeAuthReducerAction = ReducerAction<User, AuthReducerActionTypes.REFRESH_ME>;

export enum AuthReducerActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  REFRESH_ME = 'REFRESH_ME',
}

export type AuthReducerActions = ILoginAuthReducerAction | ILogoutAuthReducerAction | IRefreshMeAuthReducerAction;
