import { useEffect, useMemo, useState } from 'react';

import instance from 'utils/axios';

import { Operator } from 'modules/users/types/operator.interface';
import { IOwnerGoIp } from 'modules/owners/types/IOwnerGoIp';
import { UserRole } from 'modules/users/types/userRole.interface';
import { Agent } from 'modules/agents/types/agent.interface';
import { BankAccountType } from 'modules/owners/types/bankAccountType.interface';

import { Merchant } from 'modules/merchants/types/merchant.interface';
import { GoipTypeEnum } from 'modules/owners/pages/list/components/GoipCascader/Enums/GoipTypeEnum';
import { IProvider } from 'types/IProvider';
import { ICurrency } from 'types/currency.interface';

interface Params<T> {
  endpoint: string;
  defaultValue: T;
  autoFetch?: boolean;
  caching?: CacheConfigParam;
}

interface CacheConfig {
  cacheKey: string;
  expiryInSecs: number;
}

type CacheConfigParam = Partial<CacheConfig> | boolean;

export enum UseDataFromServerHookStatesEnum {
  INITIALIZED,
  LOADING,
  SUCCESS,
  FAILURE,
}

export type UseDataFromServerHook = ReturnType<typeof generateHook>;

export function useDataFromServer<T>({ endpoint, defaultValue, autoFetch = true, caching = false }: Params<T>) {
  const [data, setData] = useState<T>(defaultValue);
  const [state, setState] = useState<UseDataFromServerHookStatesEnum>(UseDataFromServerHookStatesEnum.INITIALIZED);

  useEffect(() => {
    if (!autoFetch) {
      return;
    }

    fetchData();
  }, []);

  const cacheConfig = useMemo(() => {
    const defaultConfig: CacheConfig = {
      expiryInSecs: 60 * 60,
      cacheKey: 'use-data-hook_' + endpoint.replace(/\//g, '-'),
    };

    if (caching === true || !caching) {
      return defaultConfig;
    }

    return { ...defaultConfig, ...caching };
  }, [caching]);

  async function fetchData(): Promise<void> {
    setState(UseDataFromServerHookStatesEnum.LOADING);

    try {
      let cachedData: T | null = null;

      if (caching) {
        cachedData = getFromCache();
      }

      if (!cachedData) {
        const {
          data: { data },
        } = await instance.get(endpoint);

        setData(data);
        saveToCache(data);
      } else {
        setData(cachedData);
      }

      setState(UseDataFromServerHookStatesEnum.SUCCESS);
    } catch (e) {
      setState(UseDataFromServerHookStatesEnum.FAILURE);

      throw e;
    }
  }

  function getFromCache(): T | null {
    const cachedData = localStorage.getItem(cacheConfig.cacheKey);
    const expiry = localStorage.getItem(cacheConfig.cacheKey + '_expiry');

    if (!expiry || !cachedData) {
      return null;
    }

    if (Date.now() > parseInt(expiry, 10)) {
      localStorage.removeItem(cacheConfig.cacheKey);
      localStorage.removeItem(expiryCacheKey());

      return null;
    }

    return JSON.parse(cachedData);
  }

  function saveToCache(data: T) {
    localStorage.setItem(cacheConfig.cacheKey, JSON.stringify(data));
    localStorage.setItem(expiryCacheKey(), (Date.now() + cacheConfig.expiryInSecs * 1000).toString());
  }

  function expiryCacheKey(): string {
    return cacheConfig.cacheKey + '_expiry';
  }

  return [data, state, fetchData] as const;
}

function generateHook<T>(params: Params<T> & Omit<Params<T>, 'autoFetch'>) {
  return (autoFetch: boolean = true, caching: CacheConfigParam = false) =>
    useDataFromServer<T>({
      ...params,
      autoFetch,
      caching,
    });
}

export const useBanksFromServer = generateHook<IProvider[]>({
  endpoint: '/banks/list-short',
  defaultValue: [],
});

export const useBankAccountsTypesFromServer = generateHook<BankAccountType[]>({
  endpoint: '/bank-accounts/types',
  defaultValue: [],
});

export const useMerchantsFromServer = generateHook<Merchant[]>({
  endpoint: '/merchants/list-short?withDeleted=1',
  defaultValue: [],
});

export const useNotDeletedMerchantsFromServer = generateHook<Merchant[]>({
  endpoint: '/merchants/list-short?withDeleted=0',
  defaultValue: [],
});

export const useOperatorsFromServer = generateHook<Operator[]>({
  endpoint: '/static/operators',
  defaultValue: [],
});

export const useCurrenciesFromServer = generateHook<ICurrency[]>({
  endpoint: '/banks/currencies',
  defaultValue: [],
});

export interface IFetchedOwnerStatus {
  id: number;
  name: string;
}

export const useOwnerStatusesFromServer = generateHook<IFetchedOwnerStatus[]>({
  endpoint: '/owners/statuses',
  defaultValue: [],
});

export const useOwnersFromServer = generateHook<IFetchedOwnerStatus[]>({
  endpoint: '/owners/list-short',
  defaultValue: [],
});

export const useGoIpsFromServer = generateHook<IOwnerGoIp[]>({
  endpoint: '/goip/list-short',
  defaultValue: [],
});

export const useAvailableGoipsFromServer = generateHook<IOwnerGoIp[]>({
  endpoint: '/goip/list-short?type=' + GoipTypeEnum.AVAILABLE,
  defaultValue: [],
});

export const useRolesFromServer = generateHook<UserRole[]>({
  endpoint: '/static/roles',
  defaultValue: [],
});

export const useAgentsFromServer = generateHook<Agent[]>({
  endpoint: '/agents/list-short',
  defaultValue: [],
});

export const useCardStatusesFromServer = generateHook<{ id: number; label: string }[]>({
  endpoint: '/cards/status/list',
  defaultValue: [],
});

export const useOrderStatusesFromServer = generateHook<{ id: number; label: string }[]>({
  endpoint: '/orders/status/list',
  defaultValue: [],
});

export const useDisputeStatusesFromServer = generateHook<{ id: number; label: string }[]>({
  endpoint: '/disputes/status/list',
  defaultValue: [],
});
