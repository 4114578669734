import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Select, Space, Tag, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { Tooltip } from 'components/Tooltip';
import instance from 'utils/axios';
import { formatAmount } from 'utils/formatAmount.util';
import { useTheme } from 'contexts';
import { useBanksFromServer } from 'hooks/useDataFromServer';
import { OwnerForOutOrder } from 'modules/orders/types/ownerForOutOrder.interface';
import { SelectedOwner } from 'modules/orders/components/AssignOwnersToOrderForm/interfaces/selectedOwner.interface';
import { Order } from 'modules/orders/types/order.interface';

interface Props {
  value: SelectedOwner[];
  onChange: (value: SelectedOwner[]) => void;
  transaction?: Order;
  isMultipleOwners: boolean;
  onIsMultipeOwnersChange: (value: boolean) => void;
}

type OwnerForOutOrderWithAmount = OwnerForOutOrder & { amount?: number };

export const AssignOwnersToTransactionsFormOwnersSelect: React.FC<Props> = ({
  value,
  onChange,
  transaction,
  isMultipleOwners,
  onIsMultipeOwnersChange,
}) => {
  const [availableOwners, setAvailableOwners] = useState<OwnerForOutOrder[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [banks] = useBanksFromServer(true, true);

  const { isDarkMode } = useTheme();

  const groupedOwners = useMemo<{ [p: number]: (OwnerForOutOrder | OwnerForOutOrderWithAmount)[] }>(() => {
    const result: { [key: number]: OwnerForOutOrder[] } = {};

    const availableIds = availableOwners.map((owner) => owner.bankAccountId);
    const additionalOwners = value
      .filter((o) => !availableIds.includes(o.bankAccountId))
      .map<OwnerForOutOrderWithAmount>(({ ownerId, bankId, bankAccountId, bankAccountNumber, amount }) => ({
        ownerId,
        sberLimit: 0,
        amount: amount ?? 0,
        otherBanksLimit: 0,
        showLimits: false,
        accountNumber: bankAccountNumber,
        bankAccountId,
        bankId,
      }));

    [...availableOwners, ...additionalOwners].forEach((owner) => {
      if (!owner.bankId) {
        return;
      }

      result[owner.bankId] = [...(result[owner.bankId] ?? []), owner];
    });

    return result;
  }, [availableOwners, value]);

  const allOwners = useMemo(() => Object.values(groupedOwners).flat(), [groupedOwners]);

  useEffect(() => {
    if (!transaction) return;

    fetchOwnersForTransaction();
  }, [transaction]);

  useEffect(() => {
    if (isMultipleOwners) return;

    handleSelectChange(value?.map(({ bankAccountId }) => bankAccountId) ?? []);
  }, [isMultipleOwners]);

  async function fetchOwnersForTransaction() {
    setIsLoading(true);

    const {
      data: { data },
    } = await instance.get('/owners/choose-owners', {
      params: { transactionId: transaction!.id },
    });

    setAvailableOwners(data as OwnerForOutOrder[]);
    setIsLoading(false);
  }

  function handleSelectChange(bankAccountIds: number[] | number) {
    if (!transaction) return;

    const ids = Array.isArray(bankAccountIds) ? bankAccountIds : [bankAccountIds];

    onChange(
      allOwners
        .filter((owner) => ids.includes(owner.bankAccountId))
        .map<SelectedOwner>((owner: OwnerForOutOrderWithAmount) => ({
          ...owner,
          amount: isMultipleOwners ? owner.amount ?? 0 : transaction.amount,
          bankAccountNumber: owner.accountNumber,
        }))
    );
  }

  return (
    <div className="w-full flex flex-row items-center gap-2">
      <Tooltip
        title="Доступные владельцы для транзакции"
        trigger={['focus', 'hover']}
        placement="left"
        className="w-full"
      >
        {!isLoading && availableOwners?.length === 0 && (
          <Alert type="error" message="Для данной транзакции нет доступных владельцев" className="mb-2" />
        )}
        <Select
          showSearch
          style={{ minWidth: 250 }}
          className="w-full"
          placeholder="Введите ID"
          value={value?.map(({ bankAccountId }) => bankAccountId!)}
          onChange={handleSelectChange}
          onFocus={() => fetchOwnersForTransaction()}
          mode={isMultipleOwners ? 'multiple' : undefined}
          filterOption={(input, option) =>
            !!allOwners
              .find((owner) => owner.bankAccountId === option?.value)
              ?.ownerId.toString()
              .includes(input)
          }
          loading={isLoading}
        >
          {Object.keys(groupedOwners).map((key) => (
            <Select.OptGroup label={<b>{banks.find(({ id }) => id === Number(key))?.name}</b>} key={key}>
              {groupedOwners[Number(key)].map((owner) => (
                <Select.Option value={owner.bankAccountId} key={[owner.ownerId, owner.bankAccountId].join('-')}>
                  <Space>
                    <>ID: {owner.ownerId}</>
                    {owner.accountNumber && <Tag color="orange">Счет: {owner.accountNumber}</Tag>}
                    <Tag color="blue">
                      Баланс:&nbsp;
                      {formatAmount(owner.realBalance) ?? 'Нет данных'}
                    </Tag>
                    {owner.showLimits && (
                      <>
                        <Tag color="green">
                          Лим. сбер:&nbsp;
                          {formatAmount(owner.sberLimit)}
                        </Tag>
                        <Tag color="gray">
                          Лим. др:&nbsp;
                          {formatAmount(owner.otherBanksLimit)}
                        </Tag>
                      </>
                    )}
                  </Space>
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
        </Select>
      </Tooltip>
      <Checkbox
        checked={isMultipleOwners}
        onChange={(e: CheckboxChangeEvent) => onIsMultipeOwnersChange(e.target.checked)}
      >
        <span className={isDarkMode ? 'text-white text-opacity-85' : ''}>Разделить</span>
      </Checkbox>
    </div>
  );
};
