import instance from 'utils/axios';
import { Bank } from 'modules/banks/types/bank.interface';

export async function fetchBankRequest(id: number) {
  const {
    data: { data },
  } = await instance.get('/banks/item', { params: { id } });

  return data as Bank;
}
