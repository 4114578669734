import { Dispatch } from 'redux';
import api from 'utils/axios';
import {
  OwnersTransactionsActionsTypes,
  OwnersTransactionsFetchParams,
} from 'modules/owners/store/ownersTransactions/types';
import { OwnerCreateTransactionParams } from 'modules/owners/types/OwnerCreateTransactionParams';

export const fetchOwnersTransactions = (params: OwnersTransactionsFetchParams = {}) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading());

      const {
        data: { data, meta },
      } = await api.get('/owners/balance/history', { params });

      dispatch({
        type: OwnersTransactionsActionsTypes.FETCH_OWNERS_TRANSACTIONS,
        payload: { transactions: data, pagination: meta },
      });
    } catch {
      dispatch(setLoading(false));
    }
  };
};

export function createOwnerTransactions(params: OwnerCreateTransactionParams) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setLoading());

      const {
        data: { data },
      } = await api.post('/owners/balance/create-transaction', { ...params });

      dispatch<any>({
        type: OwnersTransactionsActionsTypes.CREATE_OWNER_TRANSACTION,
        payload: data,
      });
    } catch {
      dispatch<any>(setLoading(false));
    }
  };
}

export const setLoading = (value: boolean = true) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: OwnersTransactionsActionsTypes.SET_LOADING,
      payload: value,
    });
  };
};

export function changePagination(page: number, perPage: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: OwnersTransactionsActionsTypes.CHANGE_PAGINATION,
      payload: { page, perPage },
    });
  };
}
