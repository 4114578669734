import React, { FC } from 'react';
import { Button, Col, Row } from 'antd';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';

interface Props {
  onSelectBanksClick: () => void;
  onMergeBanksClick: () => void;
}

export const BanksPageActions: FC<Props> = ({ onSelectBanksClick, onMergeBanksClick }) => {
  return (
    <Row gutter={8} style={{ marginTop: '-17px' }}>
      <Col>
        <ProtectedComponent requiredPermissions={[permissions.bank.combine]}>
          <Button type="primary" onClick={onSelectBanksClick}>
            Выбрать банки
          </Button>
        </ProtectedComponent>
      </Col>
      <Col>
        <ProtectedComponent requiredPermissions={[permissions.bank.combine]}>
          <Button type="primary" onClick={onMergeBanksClick}>
            Объединить банки
          </Button>
        </ProtectedComponent>
      </Col>
    </Row>
  );
};
