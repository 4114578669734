import useSWR from 'swr';
import { fetchReportsRequest } from 'modules/reports/api';

interface FetchReportRequestParams {
  perPage: number;
  page: number;
}

export function useFetchReportsRequest(params: FetchReportRequestParams) {
  const { data, mutate, isLoading } = useSWR(
    [params, 'fetch-reports-request'],
    ([params]) => fetchReportsRequest(params),
    {
      shouldRetryOnError: false,
    }
  );

  return {
    reportsData: data,
    isFetchingReports: isLoading,
    fetchReports: mutate,
  };
}
