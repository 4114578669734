import { OwnerTypeEnum } from 'modules/owners/types/OwnerTypeEnum';
import { IOwner, IOwnerCommission, IOwnerStatus } from 'modules/owners/types/IOwner';
import { IMetaPagination } from 'types/IMetaPagination';
import { ReducerAction } from 'types/reducerAction.interface';

export interface IOwnersState {
  owners: IOwner[];
  isLoading: boolean;
  pagination: IMetaPagination;
}

export interface IOwnersStatePagination {
  total: number;
  page: number;
  pages: number;
  perPage: number;
}

export interface IOwnerFieldToUpdate {
  status: OwnerStatusIdEnum;
  limitIn: number;
  limitOut: number;
  incommingTransactionsIsAllowed: boolean;
  outcommingTransactionsIsAllowed: boolean;
}

export interface ICreateOwnerParams {
  ownerId?: number;
  fio?: string;
  chatId?: string;
  limitIn?: number;
  limitOut?: number;
  bith?: string;
  city?: string;
  tgNick?: string;
  mainPhone?: string;
  deposit: boolean;
  isAgent: boolean;
  agentId?: number;
  incommingTransactionsIsAllowed: boolean;
  outcommingTransactionsIsAllowed: boolean;
  phoneSBP?: string;
  disputesLimit: number;
  trustLimit?: number;
  priority: number;
  outcomingOrdersPriority: number;
  ping: boolean;
  login?: string;
  password?: string;
  isSbpEnabled: boolean;
  goip?: string;
  isDeposit?: boolean;
  trusted: boolean;
}

export interface IFetchOwnersParams {
  fio?: string;
  limitInFrom?: number;
  limitInTo?: number;
  ownerId?: IOwner['ownerId'];
  ownerIds?: IOwner['ownerId'][];
  statusId?: number;
  page?: number;
  perPage?: number;
  bankId?: number;
  customerId?: number;
  isSbp?: boolean;
  goips?: string[]; // список ID каналов(шлюзов)
}

export interface IFetchOwnersPayload {
  owners: IOwner[];
  pagination: IOwnersStatePagination;
  other: {
    totalActualBalance: number;
    totalPlannedBalance: number;
    balanceDifference: number;
  };
}

export interface IEditOwnerBalanceParams {
  ownerId: number;
  operationType: EditOwnerBalanceOperationTypeEnum;
  amount: number;
}

export enum EditOwnerBalanceOperationTypeEnum {
  ADDITION = 1,
  SUBTRACTION = 2,
}

export interface IChangePagePayload {
  page: number;
  perPage: number;
}

export enum OwnerStatusIdEnum {
  BLOCKED = 0,
  ACTIVE = 1,
  INACTIVE = 2,
  DELETED = 3,
  BLOCKED_GOIP_GATEWAY_IS_UNAVAILABLE = 9,
}

export const OwnerTypesOptions = [
  { value: OwnerTypeEnum.OWNER, label: 'Владелец' },
  { value: OwnerTypeEnum.AGENT, label: 'Агент' },
];

export type IFetchOwnersReducerAction = ReducerAction<IFetchOwnersPayload, OwnersActionTypes.FETCH_OWNERS>;

export type ISetLoadingReducerAction = ReducerAction<boolean, OwnersActionTypes.SET_LOADING>;

export type ICreateOwnerReducerAction = ReducerAction<IOwner, OwnersActionTypes.CREATE_OWNER>;

export type EditOwnerReducerAction = ReducerAction<IOwner, OwnersActionTypes.EDIT_OWNER>;

export interface UpdaterOwnerFieldPayload {
  ownerId: number;
  incommingTransactionsIsAllowed?: boolean;
  outcommingTransactionsIsAllowed?: boolean;
  limitIn?: number;
  limitOut?: number;
  status?: IOwnerStatus;
}

export type EditOwnerFieldReducerAction = ReducerAction<UpdaterOwnerFieldPayload, OwnersActionTypes.UPDATE_OWNER_FIELD>;

export type DeleteOwnerReducerAction = ReducerAction<number, OwnersActionTypes.DELETE_OWNER>;

export type IChangePageOwnersReducerAction = ReducerAction<IChangePagePayload, OwnersActionTypes.CHANGE_PAGE>;

export type IUpdateOwnersReducerAction = ReducerAction<IOwner[], OwnersActionTypes.UPDATE_OWNERS>;
export type IClearOwnersReducerAction = ReducerAction<IOwner[], OwnersActionTypes.CLEAR_OWNERS>;
export type UpdateOwnerCommissionsReducerAction = ReducerAction<
  { commissions: IOwnerCommission[]; ownerId: number },
  OwnersActionTypes.UPDATE_OWNER_COMMISSIONS
>;

export interface IEditOwnerBalanceReducerPayload {
  ownerId: number;
  balanceReal: number;
}

export type IEditOwnerBalanceReducerAction = ReducerAction<
  IEditOwnerBalanceReducerPayload,
  OwnersActionTypes.EDIT_OWNER_BALANCE
>;

export enum OwnersActionTypes {
  FETCH_OWNERS = 'FETCH_OWNERS',
  SET_LOADING = 'SET_LOADING',
  CREATE_OWNER = 'CREATE_OWNER',
  EDIT_OWNER = 'EDIT_OWNER',
  UPDATE_OWNER_FIELD = 'UPDATE_OWNER_FIELD',
  EDIT_OWNER_BALANCE = 'EDIT_OWNER_BALANCE',
  DELETE_OWNER = 'DELETE_OWNER',
  CHANGE_PAGE = 'CHANGE_PAGE',
  UPDATE_OWNERS = 'UPDATE_OWNERS',
  UPDATE_OWNER_COMMISSIONS = 'UPDATE_OWNER_COMMISSIONS',
  CLEAR_OWNERS = 'CLEAR_OWNERS',
}

export type OwnerReducerActions =
  | IFetchOwnersReducerAction
  | ISetLoadingReducerAction
  | ICreateOwnerReducerAction
  | IChangePageOwnersReducerAction
  | IUpdateOwnersReducerAction
  | EditOwnerReducerAction
  | EditOwnerFieldReducerAction
  | DeleteOwnerReducerAction
  | UpdateOwnerCommissionsReducerAction
  | IEditOwnerBalanceReducerAction
  | IClearOwnersReducerAction;
